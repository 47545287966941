// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useState} from 'react';
import {LatestSoftwareUpdate} from '@modules/Core/components/base/latest-software-update/LatestSoftwareUpdate';
import {
  PROFILE_ALERT_NEW_RELEASE,
  PROFILE_ALERT_TYPE_GAMIFICATION,
  PROFILE_ALERT_TYPE_SERVICE_ROUND_FINISHED,
} from '@modules/Core/config/constants';
import {_ProfileAlert} from '@modules/Core/types/profile.model';
import {useAppNavigate} from '@modules/Navigation/hooks/appNavigate';
import {GamificationAlertDialog} from '@modules/Profile/components/alerts/GamificationAlertDialog';
import {ProfileAlertDialog} from '@modules/Profile/components/alerts/ProfileAlertDialog';
import ExerciseRoundFinishedDialog from '@modules/Profile/components/exercises/dialogs/ProfileExerciseRoundFinishedDialog';
import {setProfileAlertDoneAction} from '@modules/Profile/util/journalUtil';
import {currentProfile} from '@modules/Profile/util/profileUtil';

/**
 *
 * @param alerts
 * @returns {null}
 * @constructor
 */

interface _Props {
  alert: _ProfileAlert;
  onDone?: () => void;
}

export const ProfileAlert: React.FC<_Props> = ({alert, onDone}) => {
  const [showDialog, setShowDialog] = useState(false);
  const {navigate} = useAppNavigate();

  useEffect(() => {
    if (alert?.id) {
      setTimeout(() => setShowDialog(true), 1000);
    }
  }, [alert]);

  if (!alert) {
    return null;
  }

  const handleClose = async (confirmed: boolean, navigateTo: string | null): Promise<void> => {
    await setProfileAlertDoneAction(alert.id);
    setShowDialog(false);
    onDone?.();
    if (confirmed && navigateTo) {
      navigate(navigateTo);
    }
  };

  let content;
  if (alert.key === PROFILE_ALERT_TYPE_SERVICE_ROUND_FINISHED) {
    content = (
      <ExerciseRoundFinishedDialog
        open={showDialog}
        onClose={handleClose}
        service={alert?.metadata?.service}
        round={alert?.metadata?.round as number}
      />
    );
  } else if (alert.key === PROFILE_ALERT_NEW_RELEASE) {
    content = (
      <LatestSoftwareUpdate
        latestReleaseKey={currentProfile()?.latestSoftwareUpdateCE}
        open={showDialog}
        setOpen={(open: boolean) => {
          setShowDialog(open);
          if (!open) handleClose(true, null);
        }}
      />
    );
  } else if (alert.key === PROFILE_ALERT_TYPE_GAMIFICATION) {
    content = <GamificationAlertDialog alert={alert} open={showDialog} onClose={handleClose} />;
  } else {
    content = <ProfileAlertDialog alert={alert} open={showDialog} onClose={handleClose} />;
  }

  return <>{content}</>;
};
