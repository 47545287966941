// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {ProfileLevelProgressionItem} from '@modules/ContentEngine/components/items/gamification/ProfileLevelProgressionItem';
import {Card} from '@modules/Core/components/base/cards/Card';
import {Typography} from '@modules/Core/components/base/Typography';
import {_ProfileGamification} from '@modules/Core/types/gamification.model';

interface _Props {
  title: string;
  image: string;
  progress?: _ProfileGamification | null | undefined;
  description?: string | null | undefined;
  type: 'achievement' | 'level';
  progressPosition?: 'horizontal' | 'vertical' | 'hidden';
  progression: number;
}

export const GamificationAlertCard: React.FC<_Props> = ({
  progression,
  progressPosition = 'horizontal',
  type,
  title,
  image,
  progress,
  description,
}) => {
  const showProgress = progress !== null && progress !== undefined;
  const showDescription = description !== null && description !== undefined;

  const showProgressHorizontal = progressPosition === 'horizontal' && showProgress;
  const showProgressVertical = progressPosition === 'vertical' && showProgress;
  return (
    <Card titleVariant="body" padding={2} className="py-2 px-3">
      {title}
      <div className="flex flex-row w-full items-center gap-1">
        {type === 'level' && !!image && <img src={image} className="w-5" />}

        {showProgressHorizontal && (
          <ProfileLevelProgressionItem progression={progression} type={type} minified gamification={progress} />
        )}

        {showDescription && <Typography variant="body">{description}</Typography>}
      </div>
      {showProgressVertical && (
        <ProfileLevelProgressionItem progression={progression} type={type} minified gamification={progress} />
      )}
    </Card>
  );
};
