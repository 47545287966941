// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {ProgressBar} from '@modules/Core/components/base/ProgressBar';
import {SectionRow} from '@modules/Core/components/layout/SectionRow';
import {logger} from '@modules/Core/util/Logger';
import {concatNames} from '@modules/Core/util/strings';
import {_InstitutionOverviewTeam} from '@modules/Institution/types/institutionstatistics.model';
import {concatTeamNames} from '@modules/Institution/util/institutionUtil';
import {PROFILE_SERVICES_TITLES} from '@modules/Profile/config/profileServicesLangProps';
import {getServiceIcon} from '@modules/Profile/util/profileUtil';
import {_TotalProgressData} from '@modules/Team/types/teamstatistics.model';
import {trans} from '@modules/Translations/util/i18n';

interface _Props {
  service: string;
  teams: _InstitutionOverviewTeam[];
  progress: _TotalProgressData;
}

const LIMIT = 3;
export const OrganisationSoftfactsRow: React.FC<_Props> = ({progress, service, teams}) => {
  const Icon = getServiceIcon(service);

  logger.debug('ExercisesRoundRow', {service});

  const teamNames = concatTeamNames(teams, LIMIT);

  return (
    <SectionRow
      title={trans(PROFILE_SERVICES_TITLES[service])}
      subtitle={teamNames}
      icon={Icon && <Icon />}
      buttonIcon={null}
      prefixContent={
        !!progress?.avg && (
          <div className="w-[56px]">
            <ProgressBar value={progress?.avg} color="success" size="xs" />
          </div>
        )
      }
    />
  );
};
