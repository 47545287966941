// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {Avatar} from '@modules/Core/components/base/avatar/Avatar';
import {AvatarGroup} from '@modules/Core/components/base/avatar/AvatarGroup';
import {getProfilePic} from '@modules/Profile/util/profileUtil';
import {_TeamProfile} from '@modules/Team/types/team.model';

interface _Props {
  profiles: string[];
  teamProfiles: Record<string, _TeamProfile>;
  limit?: number;
  avatarSize?: 'sm' | 'md' | 'lg';
}

export const TeamProfileAvatarList: React.FC<_Props> = ({avatarSize, profiles, limit = 4, teamProfiles}) => {
  if (!profiles || profiles?.length === 0) {
    return null;
  }

  function profileComponent(profileId: string) {
    return <Avatar size={avatarSize} image={getProfilePic(teamProfiles?.[profileId])} />;
  }

  if (profiles.length === 1) return profileComponent(profiles[0]);

  // badge is +5 for example
  return (
    <AvatarGroup badge={profiles?.length > limit ? `+${profiles.length - limit}` : null}>
      {profiles?.slice(0, limit).map((profile, index) => profileComponent(profile))}
    </AvatarGroup>
  );
};
