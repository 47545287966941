// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {Goals} from '@modules/Core/components/base/Goals';
import {Typography} from '@modules/Core/components/base/Typography';
import {kickoffLangProps} from '@modules/Profile/config/profileServicesLangProps';
import {_ProfileExerciseCE} from '@modules/Profile/types/exercises.model';
import {_ServiceStatus} from '@modules/Profile/types/modules.model';
import {trans} from '@modules/Translations/util/i18n';

const LEVELS: Array<number | 'final'> = [1, 2, 3, 'final'];

interface _ProfileServiceExercisesItemProps {
  service: string;
  profileExercises: _ProfileExerciseCE;
  serviceStatus: _ServiceStatus;
}

export const ServiceAchievements: React.FC<_ProfileServiceExercisesItemProps> = ({
  service,
  profileExercises,
  serviceStatus,
}) => {
  if (!service || serviceStatus?.current_round === undefined) {
    return null;
  }

  return (
    <div className="flex flex-row justify-between items-center">
      <Typography variant="body2">
        {trans(kickoffLangProps[serviceStatus?.current_round] || 'profile_modules.kickoff_0')}
      </Typography>

      <div className="flex flex-row gap-5">
        {LEVELS.map((level: number | 'final') => {
          let disabled;
          let progress;
          let hideProgressBar;
          const MAX_LEVEL = 4;

          const currentRound = serviceStatus.current_round! + 1;

          if (level === 'final') {
            hideProgressBar = true;
            disabled = currentRound < MAX_LEVEL;
          } else if (currentRound < (level as unknown as number)) {
            disabled = true;
            hideProgressBar = true;
          } else if (currentRound > (level as unknown as number)) {
            disabled = false;
            hideProgressBar = true;
          } else {
            // Current level/round
            disabled = false;
            progress = profileExercises?.rounds?.[currentRound - 1]?.progress;
            hideProgressBar = progress === 100;
          }
          const MAX_ROUND_INDEX = 2;
          const roundIndex = level !== 'final' ? level - 1 : MAX_ROUND_INDEX;

          if (!profileExercises?.rounds?.[roundIndex]?.available) {
            disabled = true;
            hideProgressBar = true;
          }

          return (
            <Goals
              key={level}
              width="w-[32px]"
              height="h-[48px]"
              includeLabel
              level={level}
              disabled={disabled}
              progress={progress}
              hideProgressBar={hideProgressBar}
            />
          );
        })}
      </div>
    </div>
  );
};
