// @ts-nocheck
/* eslint-disable */

import React, {ReactElement} from 'react';
import {addCN} from '@/_core/util/cssHelper';

export interface _AvatarGroup {
  size?: 'sm' | 'md' | 'lg';
  badge?: string | null;
  children: ReactElement | ReactElement[];
  onBadgeClick?: () => void;
}

export const AvatarGroup: React.FC<_AvatarGroup> = ({onBadgeClick, children, size = 'md', badge = '+3'}) => (
  // children & <Avatar need to have the parent with "avatarGroup" cn
  <div className={addCN('avatarGroup flex items-center', size === 'lg' ? '-space-x-2' : '-space-x-1', size)}>
    {children}
    {!!badge && <AvatarBadge onClick={onBadgeClick} badge={badge} size={size} />}
  </div>
);

interface _AvatarBadge {
  size: 'sm' | 'md' | 'lg';
  badge: string;
  onClick?: () => void;
}

const AvatarBadge: React.FC<_AvatarBadge> = ({size, badge, onClick}) => {
  let finSize = 'h-4 w-4';
  if (size === 'sm') finSize = 'h-3 w-3';
  if (size === 'md') finSize = 'h-4 w-4';
  if (size === 'lg') finSize = 'h-5 w-5';

  return (
    <div onClick={onClick} className={addCN('relative z-10', onClick ? 'cursor-pointer' : '')}>
      <div
        className={addCN(
          'p-[8px] rounded-full flex justify-center items-center gap-[8px] shrink-0 bg-blue700',
          finSize,
          size === 'md' ? 'ml-[-2px]' : 'ml-0'
        )}
      >
        <span className="text-caption !text-white500 ">{badge}</span>
      </div>
    </div>
  );
};
