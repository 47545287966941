// @ts-nocheck
/* eslint-disable */

import React, {ReactElement, useEffect, useRef, useState} from 'react';
import {twMerge} from 'tailwind-merge';
import {addCN} from '@/_core/util/cssHelper';

interface _ToolTip {
  txt?: string | null;
  html?: string | TrustedHTML;
  plc?: 'top' | 'bottom' | 'left' | 'right' | 'top-right' | 'top-left' | 'bottom-right' | 'bottom-left';
  tip?: boolean;
  icon?: React.ElementType;
  open?: boolean;
  children: ReactElement;
  className?: string;
  style?: React.CSSProperties;
  backgroundColor?: string;
  color?: string;
}

export const ToolTip: React.FC<_ToolTip> = ({
  color,
  backgroundColor,
  style,
  txt,
  html,
  children,
  plc,
  tip,
  open,
  className,
  icon: Icon,
}) => {
  const [isSingleLine, setIsSingleLine] = useState(true);
  const ref = useRef<HTMLSpanElement>(null);
  const [hover, setHover] = useState(open);

  // check if the span is single/multi line. Places icon accordingly (single: centered or multi: top)
  // also .. this is not a great solution. There is no way to know for certain if a span is single/multi line
  // if this is too hacky, just remove it and keep the span centered. Design system will need to be updated
  useEffect(() => {
    if (ref?.current) {
      const rect = ref?.current?.getBoundingClientRect();
      if (rect) {
        if (rect.height > 28 && isSingleLine) setIsSingleLine(false);
        if (rect.height <= 28 && !isSingleLine) setIsSingleLine(true);
      }
    }
  });

  return txt ?? html ? (
    <div
      style={style}
      onPointerOver={() => setHover(true)}
      onPointerOut={() => setHover(false)}
      className={addCN(hover ? 'hover' : '', 'tooltipInline', className)}
    >
      <span
        className={addCN(
          'tooltiptext rounded-xs py-1 px-2 w-max max-w-[488px]',
          'flex justify-center gap-1',
          plc,
          tip ? 'tip' : '',
          isSingleLine ? 'items-center' : 'items-start',
          backgroundColor ?? 'bg-prussian100'
        )}
      >
        {Icon ? <Icon /> : null}

        <span
          ref={ref}
          className={twMerge('text-caption', color ?? '!text-prussian500')}
          dangerouslySetInnerHTML={html ? {__html: html} : undefined}
        >
          {txt}
        </span>
      </span>
      {children}
    </div>
  ) : (
    children
  );
};

ToolTip.defaultProps = {
  txt: null,
  html: undefined,
  plc: 'top',
  tip: true,
  icon: undefined,
  open: false,
  className: '',
};
