// @ts-nocheck
/* eslint-disable */

import {useEffect, useRef, useState} from 'react';
import {_GlobalDialog} from '@modules/Core/components/base/GlobalDialog';
import {Typography} from '@modules/Core/components/base/Typography';
import {useEffectAsync} from '@modules/Core/hooks/effectAsync';
import {getCheckoutSession} from '@modules/Core/services/api/payment';
import {_PaymentCheckoutSession} from '@modules/Core/types/payment.model';
import {logger} from '@modules/Core/util/Logger';
import {can} from '@modules/Core/util/rolesAndPermissionsUtil';
import {_Institution} from '@modules/Institution/types/institution.model';
import {useAppNavigate} from '@modules/Navigation/hooks/appNavigate';
import {trans} from '@modules/Translations/util/i18n';

export function usePaymentResult(currentInstitution?: _Institution | null, checkoutId?: string | null): _GlobalDialog {
  const {navigate} = useAppNavigate();
  const [checkoutSession, setCheckoutSession] = useState<_PaymentCheckoutSession | null>(null);
  const [dialogConfig, setDialogConfig] = useState<_GlobalDialog>({});
  const shown = useRef<boolean>(false);

  useEffect(() => {
    if (!checkoutSession || shown.current) {
      setDialogConfig({});
      return;
    }

    const hasPermission = can('organisation.products:purchase', {
      institution_id: currentInstitution?.id,
    });

    if (!hasPermission) {
      return;
    }

    shown.current = true;

    setDialogConfig({
      open: true,
      title: trans(checkoutSession?.dialogResultTexts?.title),
      children: (
        <Typography
          variant="body1"
          className="text-center"
          dangerouslySetInnerHTML={{
            __html: trans(
              checkoutSession?.dialogResultTexts?.message,
              checkoutSession?.dialogResultTexts?.params || {}
            ),
          }}
        />
      ),
      showCloseButton: true,
      justifyButtons: 'center',
      justifyContent: 'center',
      confirmLabel: trans(checkoutSession?.dialogResultTexts?.cta),
      onConfirm: () => {
        if (checkoutSession?.dialogResultTexts?.link) {
          navigate(checkoutSession?.dialogResultTexts?.link);
        }
      },
    });
  }, [checkoutSession]);

  useEffectAsync(async () => {
    if (!checkoutId) {
      setCheckoutSession(null);
      return;
    }

    const response = await getCheckoutSession(checkoutId);

    if (response.status === 200 && response.data) {
      setCheckoutSession(response.data);
    }
  }, [checkoutId]);

  return dialogConfig;
}
