// @ts-nocheck
/* eslint-disable */

import React, {useState} from 'react';
import {Input} from '@modules/Core/components/base/inputs/Input';
import {_BaseStepComponentOptions} from '@modules/FlowEngine/types/step.model';
import {BaseStep} from '../Base/BaseStep';

export const TextStep: React.FC<_BaseStepComponentOptions> = ({options}) => {
  const [value, setValue] = useState<string>('');

  return (
    <BaseStep options={options}>
      <Input onChange={v => setValue(v)} value={value} placeholder={options.placeholder} label={options.label} />
    </BaseStep>
  );
};
