// @ts-nocheck
/* eslint-disable */

import React, {useState} from 'react';
import {Input} from '@modules/Core/components/base/inputs/Input';
import {Section} from '@modules/Core/components/layout/Section';
import {logger} from '@modules/Core/util/Logger';
import {_BaseStepComponentOptions} from '@modules/FlowEngine/types/step.model';
import {Emails} from '@modules/Team/components/invitations/Emails';
import {BaseStep} from '../Base/BaseStep';

export const TextEmailsStep: React.FC<_BaseStepComponentOptions> = ({options}) => {
  const [text, setText] = useState<string>((options.text as string) ?? '');
  const [emails, setEmails] = useState<string[]>([]);

  const getValuesCallback = (): Record<string, any> => ({
    text,
    emails,
  });

  logger.debug('[TextEmailsStep] options', {
    options,
    text,
    emails,
  });
  return (
    <BaseStep getValueCallbackFn={getValuesCallback} inputs={[text, emails]} options={options}>
      <Section description={options.intro} descriptionVariant="body1" gap={5}>
        {!options.hideText && (
          <Section title={options.textTitle}>
            <Input
              onChange={v => setText(v)}
              value={text}
              placeholder={options.textPlaceholder}
              label={options.textLabel}
            />
          </Section>
        )}

        {!options.hideEmails && (
          <Section title={options.emailsTitle} description={options.emailsDescription}>
            <Emails
              emails={emails}
              setEmails={setEmails}
              placeholder={options.emailsPlaceholder}
              label={options.emailsLabel}
              consentLabel={options.emailsConsentLabel}
            />
          </Section>
        )}
      </Section>
    </BaseStep>
  );
};
