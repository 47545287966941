// @ts-nocheck
/* eslint-disable */

import {FC, useEffect, useState} from 'react';
import {twMerge} from 'tailwind-merge';
import {_Alert, Alert} from '@modules/Core/components/base/Alert';
import {useLoader} from '@modules/Core/hooks/state/loader';
import {_GlobalAlert, useUiStore, zustandApi} from '@modules/Core/util/logic/zustand';

interface _Props {
  mainAlert?: boolean;
}

export const GlobalAlert: FC<_Props> = ({mainAlert = false}) => {
  const globalAlerts = useUiStore(state => state.globalAlerts);
  const globalAlertsCount = useUiStore(state => state.globalAlertsCount);
  const {showLoadingIndicator} = useLoader();

  useEffect(() => {
    if (!mainAlert) {
      zustandApi.updateUiStore({globalAlertsCount: globalAlertsCount + 1});
      return () => {
        zustandApi.updateUiStore({globalAlertsCount: Math.max(globalAlertsCount - 1, 0)});
      };
    }
  }, []);

  const handleClose = (id: string): void => zustandApi.removeFromGlobals('globalAlerts', id);

  const handleShow = (currentAlert: _GlobalAlert): void => {
    if (currentAlert.scope) {
      return;
    }
    zustandApi.updateGlobal('globalAlerts', currentAlert.id, {
      ...currentAlert,
      scope: mainAlert ? 'main' : 'popup',
    });
  };

  if ((globalAlertsCount > 0 && mainAlert) || showLoadingIndicator) {
    return null;
  }

  return (
    <div
      className={twMerge(
        'fixed bottom-[39px] z-10 flex flex-col gap-1',
        mainAlert ? 'relative-col-width-12' : 'w-full',
        mainAlert ? '' : 'px-[39px] -ml-5'
      )}
    >
      {globalAlerts
        .sort((a: _GlobalAlert, b: _GlobalAlert) => (a.id < b.id ? 1 : -1))
        .filter(
          (currentAlert: _GlobalAlert) => !currentAlert.scope || currentAlert.scope === (mainAlert ? 'main' : 'popup')
        )
        .map((currentAlert: _GlobalAlert) => (
          <Alert
            key={currentAlert.id}
            endIcon
            {...currentAlert}
            onClose={() => handleClose(currentAlert.id)}
            onShow={() => handleShow(currentAlert)}
            width={mainAlert ? 'w-1/2' : 'w-full'}
          />
        ))}
    </div>
  );
};
