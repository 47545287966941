// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {_ProfileTrackingLastChange} from '@modules/ContentEngine/types/metrics.model';
import {IconArrowDownward, IconArrowUpward} from '@modules/Core/components/base/Icons/Icons';
import {formatFloatString} from '@modules/Core/util/typesUtil';
import {LabelBadge} from './LabelBadge';

interface _Props extends _ProfileTrackingLastChange {
  keepInDom?: boolean;
}

export const StatisticLabelBadge: React.FC<_Props> = ({keepInDom, change, changeType}) => {
  let color;
  let icon;
  let sign;
  let showChange = true;
  if (changeType === '+') {
    color = 'success';
    icon = IconArrowUpward;
    sign = '+';
  } else if (changeType === '-') {
    color = 'danger';
    icon = IconArrowDownward;
    sign = '';
  } else if (changeType === 'N') {
    color = 'grey';
    icon = null;
    sign = '';
    showChange = false;
  } else {
    showChange = false;
  }

  if (!showChange && !keepInDom) {
    return null;
  }

  // keep in dom but hide
  return (
    <LabelBadge
      style={{visibility: showChange ? 'visible' : 'hidden'}}
      value={`${sign}${formatFloatString(change)}%`}
      color={color}
      icon={icon}
    />
  );
};
