// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@app/_core/util/cssHelper';
import {Button} from '@modules/Core/components/base/buttons/Button';
import {trans} from '@modules/Translations/util/i18n';

const variantMap = {
  left: 'justify-start',
  center: 'justify-center',
  right: 'justify-end',
};

export const LastQuestionNavigation: React.FC<{
  backClicked: () => void;
  onFinishClicked: () => void;
  yourResultsButtonText?: string;
  variant?: 'left' | 'center' | 'right';
  yourResultButtonWidth?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
}> = ({yourResultButtonWidth = 2, backClicked, onFinishClicked, yourResultsButtonText, variant = 'right'}) => {
  return (
    <div className={addCN('flex flex-row w-full gap-3', variantMap[variant])}>
      <div className="relative-col-width-2">
        <Button dusk="btn-back" onClick={backClicked} variant="secondary">
          {trans('base.back')}
        </Button>
      </div>

      <div className={`relative-col-width-${yourResultButtonWidth}`}>
        <Button onClick={onFinishClicked} dusk="btn-next">
          {yourResultsButtonText ?? trans('project.your_results')}
        </Button>
      </div>
    </div>
  );
};

export const NextPrevNavigation: React.FC<{
  backClicked: () => void;
  nextClicked: () => void;
  isFirstQuestion: () => boolean;
  variant?: 'left' | 'center' | 'right';
}> = ({backClicked, nextClicked, isFirstQuestion, variant = 'right'}) => {
  return (
    <div className={addCN('flex flex-row w-full gap-3', variantMap[variant])}>
      {!isFirstQuestion() && (
        <div className="relative-col-width-2">
          <Button dusk="btn-back" onClick={backClicked} variant="secondary">
            {trans('base.back')}
          </Button>
        </div>
      )}
      <div className="relative-col-width-2">
        <Button onClick={nextClicked} dusk="btn-next">
          {trans('base.next')}
        </Button>
      </div>
    </div>
  );
};

export const SkipCard: React.FC<{
  onSkipClicked: () => void;
  skipQuestion: () => Promise<void>;
}> = ({onSkipClicked, skipQuestion}) => {
  return (
    <div className="fixed-bottom" style={{zIndex: 900}}>
      <div className="flex flex-row-reverse justify-center gap-3">
        <div className="relative-col-width-2">
          <Button onClick={onSkipClicked} dusk="skip-to-end-btn">
            {trans('base.skip_btn')}
          </Button>
        </div>

        <div className="relative-col-width-2">
          <Button
            onClick={() => {
              void skipQuestion();
            }}
            dusk="skip-one-btn"
          >
            {trans('base.skip_one_btn')}
          </Button>
        </div>
      </div>
    </div>
  );
};
