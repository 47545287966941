// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {twMerge} from 'tailwind-merge';
import {ProgressBar} from '@modules/Core/components/base/ProgressBar';
import {trans} from '@modules/Translations/util/i18n';

interface _Props {
  level: number | 'final';
  progress?: number;
  hideProgressBar?: boolean;
  disabled?: boolean;
  width?: string;
  height?: string;
  includeLabel?: boolean;
  className?: string;
}

export const Goals: React.FC<_Props> = ({
  className,
  level = 1,
  progress = 50,
  hideProgressBar,
  disabled,
  width = 'w-[36px]',
  height = 'h-[53px]',
  includeLabel = false,
}: _Props) => {
  const label =
    level === 'final'
      ? null
      : trans('profile_exercises.round', {
          roundIndex: level,
        });

  return (
    <div
      className={className}
      style={{
        display: 'flex',
        flexDirection: 'row',
        gap: 4,
        opacity: disabled ? 0.4 : 1,
      }}
    >
      <img src={`/assets/img/goals/_goal${level}.svg`} className={twMerge(width, height)} alt="goal" />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          gap: 8,
        }}
        className={level !== 'final' ? 'w-[65px]' : ''}
      >
        {/* Dirty hack because for some reason style gets fucked up when the label is hidden */}
        {!includeLabel && <span className="text-sm font-semibold opacity-0">{label}</span>}

        <div style={{opacity: hideProgressBar ? 0 : 1}}>
          <ProgressBar size="xs" value={progress} color="success" />
        </div>

        <span className={`text-sm font-semibold ${includeLabel && label ? 'opacity-100' : 'opacity-0'}`}>{label}</span>
      </div>
    </div>
  );
};
