// @ts-nocheck
/* eslint-disable */

import React, {useState} from 'react';
import {trans} from '@modules/Translations/util/i18n';
import {IconButton} from '../buttons/IconButton';
import {IconArrowDoubleLeft, IconArrowDoubleRight, IconArrowLeft, IconArrowRight} from '../Icons/Icons';
import {_SelectValue, Select} from '../inputs/Select';

export const Pagination: React.FC = ({table}) => {
  const options: _SelectValue[] = [
    {value: 10, label: 10},
    {value: 20, label: 20},
    {value: 30, label: 30},
    {value: 40, label: 40},
    {value: 50, label: 50},
  ];

  const [activeRowsPerPage, setActiveRowsPerPage] = useState<_SelectValue>(options[0]);

  const onChangeRowsPerPage = (newValue: _SelectValue): void => {
    setActiveRowsPerPage(newValue);
    table.setPageSize(newValue.value);
  };

  return (
    <div className="flex items-center gap-3 justify-end">
      <div className="flex h-4 items-center">
        <span>{trans('base.table_rowsperpage_text')}</span>
      </div>

      <div>
        <Select options={options} size="sm" value={activeRowsPerPage} onChange={onChangeRowsPerPage} hideBorder />
      </div>

      <div className="flex h-4 items-center">
        <span>
          {trans('base.table_page_of', {
            page: table.getState().pagination.pageIndex + 1,
            totalPages: table.getPageCount(),
          })}
        </span>
      </div>

      <IconButton
        onClick={(): void => table.setPageIndex(0)}
        disabled={!table.getCanPreviousPage()}
        size="sm"
        icon={IconArrowDoubleLeft}
        color="light"
      />

      <IconButton
        onClick={(): void => table.previousPage()}
        disabled={!table.getCanPreviousPage()}
        size="sm"
        icon={IconArrowLeft}
        color="light"
      />
      <IconButton
        onClick={(): void => table.nextPage()}
        disabled={!table.getCanNextPage()}
        size="sm"
        icon={IconArrowRight}
        color="light"
      />
      <IconButton
        onClick={(): void => table.setPageIndex(table.getPageCount() - 1)}
        disabled={!table.getCanNextPage()}
        size="sm"
        icon={IconArrowDoubleRight}
        color="light"
      />
    </div>
  );
};
