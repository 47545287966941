// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useState} from 'react';
import {_SelectValue, Select} from '@modules/Core/components/base/inputs/Select';
import {Section} from '@modules/Core/components/layout/Section';
import {logger} from '@modules/Core/util/Logger';
import {_Item} from '@modules/FlowEngine/components/Steps/SelectStep';
import {_BaseStepComponentOptions} from '@modules/FlowEngine/types/step.model';
import {selectLabel} from '@modules/FlowEngine/util/helpers/itemSelectionUtil';
import {Emails} from '@modules/Team/components/invitations/Emails';
import {BaseStep} from '../Base/BaseStep';

export const SelectionEmailsStep: React.FC<_BaseStepComponentOptions> = ({options}) => {
  const [emails, setEmails] = useState<string[]>([]);
  const [selectedItem, setSelectedItem] = useState<_Item | null>(null);
  const {selectedItem: previouslySelectedItem, items, labelSelector} = options;

  useEffect(() => {
    if (previouslySelectedItem) {
      setSelectedItem(previouslySelectedItem as _Item);
    }
  }, [previouslySelectedItem]);

  const getValuesCallback = (): Record<string, any> => ({
    selectedItem,
    emails,
  });

  logger.debug('[SelectionEmailsStep] options', {
    items,
    options,
    selectedItem,
    emails,
  });

  const selectItems = items?.map(item => {
    return {
      label: selectLabel(item, labelSelector),
      value: item.id,
    } as _SelectValue;
  });

  if (options.allowEmpty) {
    selectItems.unshift({label: options.emptyLabel ?? '-', value: null});
  }
  return (
    <BaseStep getValueCallbackFn={getValuesCallback} inputs={[selectedItem, emails]} options={options}>
      {!options.hideEmails && (
        <Section title={options.emailsTitle ?? null} description={options.emailsDescription}>
          <Emails
            emails={emails}
            setEmails={setEmails}
            placeholder={options.emailsPlaceholder}
            label={options.emailsLabel}
            consentLabel={options.emailsConsentLabel}
          />
        </Section>
      )}

      {!options.hideSelect && (
        <Section title={options.selectTitle ?? null}>
          <Select
            disabled={options.selectionDisabled}
            value={{
              label: selectedItem ? selectLabel(selectedItem, labelSelector) : '',
              value: selectedItem?.id,
            }}
            options={selectItems}
            onChange={v => setSelectedItem(items?.find(item => item.id === v.value) ?? null)}
            placeholder={options.selectPlaceholder}
            label={options.selectLabel}
          />
        </Section>
      )}
    </BaseStep>
  );
};
