// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useState} from 'react';
import {ContentEngineContainer} from '@modules/ContentEngine/components/base/ContentEngineContainer';
import {SERVICE_PROFILE_BASE_AND_ANALYSIS, SERVICE_TEAM_PROFILE_BASE_AND_ANALYSIS} from '@modules/Core/config/services';
import {logger} from '@modules/Core/util/Logger';
import {_TeamAnalysisResultType} from '@modules/Team/types/teamanalysis.model';

interface _TeamAnalysisResultItemProps {
  service: _TeamAnalysisResultType;
  teamId: string;
  institutionId: string;
  isPreview?: boolean;
}

const CE_SERVICES_TEAM_ANALYSIS_TEMPLATES: Record<_TeamAnalysisResultType, string> = {
  team_roles: 'team/team_analysis/roles',
  team_values: 'team/team_analysis/values',
  team_way_of_working: 'team/team_analysis/wayOfWorking',
  team_profile_base_and_analysis: 'team/team_analysis/personality',
};

const DEFAULT_SERVICE = SERVICE_PROFILE_BASE_AND_ANALYSIS;

export const TeamAnalysisResultItem: React.FC<_TeamAnalysisResultItemProps> = ({
  teamId,
  institutionId,
  service,
  isPreview = false,
}) => {
  const [contentEngineKey, setContentEngineKey] = useState<string | undefined>(
    CE_SERVICES_TEAM_ANALYSIS_TEMPLATES[service]
  );

  useEffect(() => {
    setContentEngineKey(CE_SERVICES_TEAM_ANALYSIS_TEMPLATES[service ?? DEFAULT_SERVICE]);
  }, [service]);

  logger.debug('TEAMANALYSIS TEST RESULT', {
    service,
    contentEngineKey,
    CE_SERVICES_TEAM_ANALYSIS_TEMPLATES,
  });
  if (!contentEngineKey) {
    return null;
  }
  return (
    <ContentEngineContainer
      type={contentEngineKey}
      params={{
        teamId,
        institutionId,
        isPreview,
      }}
    />
  );
};
