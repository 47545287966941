// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useState} from 'react';
import {Avatar} from '@modules/Core/components/base/avatar/Avatar';
import {Slider} from '@modules/Core/components/base/Slider';
import {logger} from '@modules/Core/util/Logger';
import {
  personalityTraitsExplanationLangProps,
  personalityTraitsLangProps,
} from '@modules/Profile/config/servicesLangProps';
import {_ProfilePersonalityTraitsResult} from '@modules/Profile/types/service.model';
import {getProfilePic} from '@modules/Profile/util/profileUtil';
import {trans} from '@modules/Translations/util/i18n';

interface _ProfileServiceGraphItemProps {
  // TODO @Sherif types
  data: Record<string, any[]>;
  feedbackId: string | null;
  profileData: _ProfilePersonalityTraitsResult;
  profilePicture: string;
  profileId: string;
}

const order = ['openMindedness', 'conscientiousness', 'extraversion', 'agreeableness', 'emotionalStability'];

export const ThirdPersonFeedbackPersonalityTraitsItem: React.FC<_ProfileServiceGraphItemProps> = ({
  data,
  feedbackId,
  profileId,
  profilePicture,
  profileData,
}) => {
  const [feedbacks, setFeedbacks] = useState<_ProfilePersonalityTraitsResult[]>([]);

  useEffect(() => {
    if (!feedbackId || !data) {
      setFeedbacks([]);
      return;
    }

    const newFeedbacks = data[feedbackId] ?? [];

    setFeedbacks(
      (data[feedbackId] ?? []).filter(item => {
        return item?.status === 'DONE' && !!item?.values?._feedback_results;
      })
    );
  }, [data, feedbackId]);

  logger.debug('ThirdPersonFeedbackPersonalityTraitsItem', {
    data: data?.[feedbackId ?? ''],
    feedbackId,
    feedbacks,
    profileData,
  });
  return (
    <>
      {order.map((key, index) => (
        <Slider
          key={key}
          title={trans(personalityTraitsLangProps[key])}
          leftText={trans('profile_result.low')}
          rightText={trans('profile_result.high')}
          components={feedbacks
            ?.map((feedback, index) => {
              const value = feedback?.values?._feedback_results?.kauai?.domains?.[key]?.value;
              return {
                id: feedback?.profile?.id,
                progress: (value / 5) * 100,
                component: <Avatar size="lg" image={getProfilePic(feedback?.profile)} indicator="success" />,
              };
            })
            .concat(
              profileData?.[key]
                ? {
                    id: profileId,
                    progress: (profileData[key].value / 5) * 100,
                    component: <Avatar size="lg" image={profilePicture ?? getProfilePic(null)} indicator="success" />,
                  }
                : null
            )
            .filter(Boolean)}
          bottomSubtitle={trans(personalityTraitsExplanationLangProps[key])}
          showExpand
        />
      ))}
    </>
  );
};
