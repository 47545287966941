// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {ProgressBar} from '@modules/Core/components/base/ProgressBar';
import {ToolTip} from '@modules/Core/components/base/ToolTip';
import {Typography} from '@modules/Core/components/base/Typography';
import {_ProfileGamification} from '@modules/Core/types/gamification.model';
import {getAchievementImage, getLevelImage} from '@modules/Core/util/imagesUtil';
import {logger} from '@modules/Core/util/Logger';
import ExperienceCount from '@modules/Statistics/components/general/ExperienceCount';
import {trans} from '@modules/Translations/util/i18n';

interface _Props {
  gamification: _ProfileGamification;
  minified?: boolean;
  type: 'level' | 'achievement';
  progression?: number;
}

export const ProfileLevelProgressionItem: React.FC<_Props> = ({
  progression,
  type = 'level',
  minified,
  gamification,
}) => {
  const {
    currentLevel,
    nextLevel,
    totalLevelExperienceGoal,
    totalLevelExperiencePoints,
    currentLevelExperienceGoal,
    currentLevelExperiencePoints,
    currentLevelProgress,
    isMaxLevel,
    totalLevelProgress,
  } = gamification ?? {};

  logger.debug({gamification});
  if (!gamification) {
    return null;
  }

  const isNotYetLevelOneAchievement = type === 'achievement' && currentLevel?.index === -1;

  const progressBarComponent = (
    <ProgressBar
      diff={progression}
      value={!isMaxLevel ? currentLevelProgress : 100}
      size={minified ? 'xs' : 'md'}
      color="success"
      tooltip={
        !minified && type === 'level' ? (
          <ExperienceCount textColor="text-white500" experience={totalLevelExperiencePoints} />
        ) : null
      }
    />
  );

  const currentLevelComponent = !isNotYetLevelOneAchievement ? (
    <LevelIcon
      tooltipPlacement="bottom-right"
      type={type}
      minified={minified}
      image={currentLevel?.image}
      index={currentLevel?.index}
      label={currentLevel?.title}
    />
  ) : null;
  const nextLevelComponent = isMaxLevel ? null : (
    <LevelIcon
      tooltipPlacement="bottom-left"
      type={type}
      index={nextLevel?.index}
      minified={minified}
      image={nextLevel?.image}
      label={nextLevel?.title}
    />
  );

  if (minified) {
    return (
      <div className="flex flex-row items-center w-full gap-1">
        {currentLevelComponent}
        {progressBarComponent}
        {nextLevelComponent}
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-2 w-full">
      <div className="flex flex-row w-full justify-between items-center">
        <ExperienceCount experience={currentLevel?.start ?? 0} />

        {!isMaxLevel && <ExperienceCount experience={totalLevelExperienceGoal} />}
      </div>

      {progressBarComponent}

      <div className="flex flex-row w-full justify-between items-center">
        {currentLevelComponent}
        {!isMaxLevel && nextLevelComponent}
      </div>
    </div>
  );
};

const LevelIcon: React.FC<{
  tooltipPlacement: 'bottom-right' | 'bottom-left';
  type: 'level' | 'achievement';
  index: number;
  minified?: boolean;
  image: string;
  label: string;
}> = ({tooltipPlacement, index, minified, image, label, type}) => {
  let img;
  if (type === 'level') {
    img = getLevelImage(image, 'png');
  }
  if (type === 'achievement') {
    img = getAchievementImage(image, 'png');
  }

  return (
    <ToolTip
      plc={tooltipPlacement}
      txt={
        type === 'level'
          ? trans('gamification.level_tooltip', {
              level: index + 1,
              name: trans(label),
            })
          : trans(label)
      }
    >
      <div className="flex flex-col gap-1 items-center">
        <img src={img} className="w-5" />
        {!minified && <Typography variant="body">{trans(label)}</Typography>}
      </div>
    </ToolTip>
  );
};
