// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';

interface _IconButton {
  id?: string;
  color?: 'light' | 'outline' | 'dark' | 'outline-light' | 'brand' | 'no-background';
  disabled?: boolean;
  size?: 'sm' | 'md' | 'lg';
  active?: boolean;
  onClick?: (event: any) => void;
  icon: React.ElementType;
  dusk?: string;
}

interface StyleConfig {
  buttonColors: string;
  iconColor: string;
}

const styleMap: Record<string, Record<'active' | 'default', StyleConfig>> = {
  light: {
    active: {buttonColors: 'bg-brand700', iconColor: 'fill-white500'},
    default: {buttonColors: 'bg-white500 hover:bg-grey100', iconColor: 'fill-grey700'},
  },
  'no-background': {
    active: {buttonColors: '', iconColor: 'fill-white500'},
    default: {buttonColors: 'hover:bg-grey100', iconColor: 'fill-grey700'},
  },
  brand: {
    active: {buttonColors: 'bg-white700', iconColor: 'fill-white500'},
    default: {buttonColors: 'bg-white500 hover:bg-grey100', iconColor: 'fill-brand700'},
  },
  outline: {
    active: {buttonColors: 'border-[2px] bg-transparent border-blue700', iconColor: 'fill-blue700'},
    default: {
      buttonColors: 'border-[2px] bg-transparent border-grey300 hover:border-grey100',
      iconColor: 'fill-grey700',
    },
  },
  dark: {
    active: {buttonColors: 'bg-blue700', iconColor: 'fill-white500'},
    default: {buttonColors: 'bg-white500 hover:bg-blue500', iconColor: 'fill-grey700'},
  },
  'outline-light': {
    active: {buttonColors: 'border-[2px] bg-transparent border-white700', iconColor: 'fill-white700'},
    default: {
      buttonColors: 'border-[2px] bg-transparent border-white700 hover:border-grey100',
      iconColor: 'fill-white700',
    },
  },
};

export const IconButton: React.FC<_IconButton> = ({
  id,
  color = 'light',
  disabled,
  size = 'md',
  active,
  onClick,
  icon: Icon,
  dusk,
}) => {
  let {buttonColors, iconColor} = styleMap[color][active ? 'active' : 'default'];

  // Disabled styles
  if (disabled) {
    buttonColors = 'bg-grey500';
    iconColor = 'fill-white500';
    if (color === 'outline') {
      buttonColors = 'bg-transparent border-grey500 border-2';
    }
  }

  return (
    <div>
      <button
        id={id}
        disabled={disabled}
        onClick={onClick}
        type="button"
        dusk={dusk}
        className={addCN('p-1 transition flex justify-center items-center gap-1 rounded-xs p-1', buttonColors)}
      >
        {Icon && <Icon size={size} color={iconColor} />}
      </button>
    </div>
  );
};
