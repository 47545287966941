// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {
  LastQuestionNavigation,
  NextPrevNavigation,
  SkipCard,
} from '@modules/SurveyEngine/components/navigation/Buttons';
import {isTeamDevModule} from '@modules/Team/util/serviceUtil';

interface _SurveyNavigationProps {
  survey: any;
  question: any;
  variant?: 'left' | 'center' | 'right';
  nextClicked: () => void;
  backClicked: () => void;
  isFirstQuestion: () => boolean;
  isLastQuestion: () => boolean;
  showSkip?: boolean;
  onFinishClicked: () => void;
  onSkipClicked: () => void;
  yourResultsButtonText?: string;
  skipQuestion: () => Promise<void>;
  surveyType: string;
}

export const SurveyNavigation: React.FC<_SurveyNavigationProps> = ({
  variant = 'right',
  nextClicked,
  backClicked,
  isFirstQuestion,
  isLastQuestion,
  showSkip = false,
  onFinishClicked,
  onSkipClicked,
  yourResultsButtonText,
  skipQuestion,
  surveyType,
}) => (
  <>
    {isLastQuestion() ? (
      <LastQuestionNavigation
        backClicked={backClicked}
        onFinishClicked={onFinishClicked}
        yourResultsButtonText={yourResultsButtonText}
        variant="center"
        yourResultButtonWidth={isTeamDevModule(surveyType) ? 4 : 2}
      />
    ) : (
      <NextPrevNavigation
        backClicked={backClicked}
        nextClicked={nextClicked}
        isFirstQuestion={isFirstQuestion}
        variant={variant}
      />
    )}
    {showSkip && <SkipCard onSkipClicked={onSkipClicked} skipQuestion={skipQuestion} />}
  </>
);
