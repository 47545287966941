// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useState} from 'react';
import {CheckBox} from '@modules/Core/components/base/inputs/CheckBox';
import {Section} from '@modules/Core/components/layout/Section';
import {_BaseStepComponentOptions} from '@modules/FlowEngine/types/step.model';
import {BaseStep} from '../Base/BaseStep';

interface _ButtonItem {
  active: boolean;
  label: string;
  action: () => void;
}

interface _MyStepOptions extends _BaseStepComponentOptions {
  items: Record<string, string>;
  itemsConditions?: Record<string, boolean>;
  results?: string[];
}

interface _Props {
  data: Record<string, any>;
  options: _MyStepOptions;
}

type _ResultItem = Record<string, boolean>;

export const MultiselectStep: React.FC<_Props> = ({data, options}) => {
  const [buttons, setButtons] = useState<_ButtonItem[]>([]);
  const [results, setResults] = useState<_ResultItem>({});

  const getValuesCallback = (): Record<string, any> => ({
    results,
  });

  useEffect(() => {
    setResults(Object.fromEntries((options.results || []).map(key => [key, true])));
  }, [options.results]);

  useEffect(() => {
    const conditions = options.itemsConditions;
    setButtons(
      Object.entries(options.items)
        .filter(([key]) => !conditions || conditions[key])
        .map(([key, item]) => ({
          active: results[key] ?? false,
          label: item,
          action: () => setResults({...results, [key]: !results[key]}),
        }))
    );
  }, [options, results]);

  return (
    <BaseStep getValueCallbackFn={getValuesCallback} inputs={[results]} options={options}>
      <Section title={options.title} description={options.description} descriptionVariant="body1">
        <div className={`flex flex-col gap-3 ${options.scrollable ? 'overflow-y-auto max-h-[300px]' : ''}`}>
          {buttons.map(btn => (
            <button
              type="button"
              onClick={() => btn.action()}
              key={btn.label}
              className="flex flex-row gap-1 items-center"
            >
              <CheckBox active={btn.active} onChange={() => btn.action()} size="sm" color="light" />
              <span>{btn.label}</span>
            </button>
          ))}
        </div>
      </Section>
    </BaseStep>
  );
};
