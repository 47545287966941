// @ts-nocheck
/* eslint-disable */

import {SERVICE_PROFILE_MOTIVE_STRUCTURE, SERVICE_PROFILE_ROLES} from '@modules/Core/config/services';
import {logger} from '@modules/Core/util/Logger';
import {copyObject} from '@modules/Core/util/util';
import {PROFILE_SERVICES_TITLES} from '@modules/Profile/config/profileServicesLangProps';
import {downloadServiceCertificate} from '@modules/Profile/services/profileApi';
import {
  _ExerciseData,
  _FullExerciseDataEntry,
  _ProfileExercise,
  _ProfileExerciseCE,
  _ProfileExercisesRound,
  _ProfileExerciseTask,
  _RoundData,
  EXERCISE_STATUS_LOCKED,
} from '@modules/Profile/types/exercises.model';
import {_Profile} from '@modules/Profile/types/profile.model';
import {currentProfile} from '@modules/Profile/util/profileUtil';
import {trans} from '@modules/Translations/util/i18n';

export function extractProfileTask(exercise?: _ProfileExercise, taskIndex?: number): _ProfileExerciseTask | undefined {
  if (!exercise?.data?.tasks || taskIndex === undefined || !exercise) {
    return undefined;
  }

  return (exercise.data.tasks || []).find(task => task.position === taskIndex);
}

export function getProfileExercises(profile: _Profile | null = null): Record<string, _ProfileExerciseCE> {
  profile = profile ?? currentProfile();
  const result = copyObject(profile?.data?.exercises?.all) as Record<string, _ProfileExerciseCE>;

  // for each result, if result.rounds a dict -> transform it to an array
  logger.debug('getProfileExercises', result);

  for (const service in result) {
    if (result[service].rounds) {
      result[service].rounds = Object.values(result[service].rounds);
    }
  }

  return result;
}

export function getProfileServiceRoundExercises(
  service: string,
  round: number,
  profile: _Profile | null = null
): _ProfileExercisesRound {
  profile = profile ?? currentProfile();
  const result = profile?.data?.exercises?.all as Record<string, _ProfileExerciseCE>;

  if (round === null || !service) {
    throw new Error('Round is not supported without service');
  }
  return result?.[service]?.rounds?.[round];
}

export const downloadCertificate = async (service: string, round: number): Promise<void> => {
  if (service && round !== undefined) {
    const response = await downloadServiceCertificate(service, round);

    // return;
    // Assuming the response data is a Blob
    if (response.data.size > 0) {
      const url = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));
      const link = document.createElement('a');
      const name = `${trans(PROFILE_SERVICES_TITLES[service])}-Level ${round}`;
      link.href = url;
      link.download = `${name}.pdf`;
      link.target = '_blank';
      link.setAttribute('download', `${name}.pdf`);
      document.body.appendChild(link);
      link.click();

      // Cleanup
      document.body.removeChild(link);
      // Otherwise, object is revoked before the download is complete
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
      }, 100);
    } else {
      logger.error('No data received for the file download');
    }
  }
};

export const createExerciseData = (
  service: string,
  exercisesData: _FullExerciseDataEntry | undefined,
  profileExercises: _ProfileExerciseCE
): {rounds: _RoundData[]; exercises: _ExerciseData[][] | Record<string, _ExerciseData[]>} => {
  if (serviceHasDynamicExercises(service)) {
    const rounds = copyObject(exercisesData?.rounds ?? []);
    const exercises = copyObject(exercisesData?.exercises ?? {});
    const newExercises: _ExerciseData[][] = [];
    // Fill from profile data, loop on profileExercises?.rounds, and fetch exercises
    rounds?.forEach(round => {
      const profileRound = profileExercises?.rounds?.[round.round];
      // Init current round exercises
      newExercises.push([]);

      logger.debug('ProfileExercisesItem before loop', {
        service,
        profileRound,
      });
      if (profileRound) {
        for (const profileExercise of profileRound.exercises) {
          const exerciseId = profileExercise.exercise_id;

          const {name, index} = extractNameAndNumberFromExerciseId(exerciseId);

          if (name) {
            const exercise = exercises?.[name];
            if (exercise && index !== undefined && exercise[index]) {
              // add to round
              if (!round.exercises) {
                round.exercises = [];
              }

              logger.debug('ProfileExercisesItem in loop', {
                service,
              });
              round.exercises.push(exercise[index]);
              newExercises[round.round].push(exercise[index]);
              // Round is available if any exercise is available
            }
          }
          round.available = round.available ?? profileExercise.available;
        }
        // Enrich each exercise with the data from the profile
        round.progress = profileRound.progress;
        round.status = profileRound.status;
      }
    });

    return {
      rounds: rounds ?? [],
      exercises: newExercises,
    };
  }

  // for each round, enrich with progress from profileExercises
  const rounds = copyObject(exercisesData?.rounds ?? []);
  rounds?.forEach(round => {
    const profileRound = profileExercises?.rounds?.[round.round];
    if (profileRound) {
      round.available = profileRound.available;
      round.progress = profileRound.progress;
      round.status = profileRound.status;
    }
  });
  return {
    rounds,
    exercises: exercisesData?.exercises ?? [],
  };
};

// Function to extract the name and the number from the given exerciseId string
export function extractNameAndNumberFromExerciseId(exerciseId: string): {name: string; index: number} | null {
  // Regular expression to match the pattern 'exercise_$name_number'
  // It has two capturing groups: one for the $name part and another for the number
  const regex = /exercise_([a-zA-Z0-9_]+)_(\d+)/;

  // Using exec to apply the regex to the input string
  const match = regex.exec(exerciseId);

  // Checking if there was a match
  if (match) {
    // If a match is found, return an object with the name and the number
    return {
      name: match[1],
      index: parseInt(match[2], 10), // Parsing the number part as an integer
    };
  }
  return null;
}

export const serviceHasDynamicExercises = (service: string): boolean =>
  [SERVICE_PROFILE_ROLES, SERVICE_PROFILE_MOTIVE_STRUCTURE].includes(service);

/**
 * useEffect(() => {
 *     // Loop on all exercises, if all are locked, set allLocked to true
 *     let locked = true;
 *     const currentRoundExercises = profileExercises?.rounds?.[profileExercises?.currentRound]?.exercises;
 *
 *     if (currentRoundExercises) {
 *       for (const exercise of currentRoundExercises) {
 *         if (exercise.status !== EXERCISE_STATUS_LOCKED) {
 *           locked = false;
 *           break;
 *         }
 *       }
 *     }
 *
 *     // First time, textareas enabeld by default
 *     if (locked && profileExercises?.currentRound === 0) {
 *       setDisabled(false);
 *     }
 *
 *     setAllLocked(locked);
 *     setCurrentRound(profileExercises?.currentRound || 0);
 *   }, [profileExercises]);
 *
 *   tHIS FN SHOULD replicate the above useEffect
 * @param profileExercises
 */
export function isServiceChallengesAndGoalsLocked(profileExercises: _ProfileExerciseCE): boolean {
  let locked = true;
  const currentRoundExercises = profileExercises?.rounds?.[profileExercises?.currentRound]?.exercises;

  if (currentRoundExercises) {
    for (const exercise of currentRoundExercises) {
      if (exercise.status !== EXERCISE_STATUS_LOCKED) {
        locked = false;
        break;
      }
    }
  }

  return locked;
}
