// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useState} from 'react';
import {Dialog} from '@modules/Core/components/base/Dialog';
import {Typography} from '@modules/Core/components/base/Typography';
import {
  _GamificationAlertAchievementProgress,
  _GamificationAlertGainedAchievements,
  _GamificationAlertGainedExp,
  _GamificationAlertLevelUp,
} from '@modules/Core/types/gamification.model';
import {_ProfileAlert} from '@modules/Core/types/profile.model';
import {getStatisticImage} from '@modules/Core/util/imagesUtil';
import {logger} from '@modules/Core/util/Logger';
import {GamificationAlertCard} from '@modules/Statistics/components/general/GamificationAlertCard';
import {GamificationAlertLevelUpViewCard} from '@modules/Statistics/components/general/GamificationAlertLevelUpViewCard';
import {trans} from '@modules/Translations/util/i18n';

interface _Props {
  alert: _ProfileAlert;
  open: boolean;
  onClose: (confirmed: boolean, navigateTo: string | null) => void;
}

/**
 * This interface is a page, basically, this popup will have multiple pages depending on data
 */

type _PageType = 'levelUp' | 'achievementLevelUp' | 'gains';

interface _GamificationAlertPage {
  type: _PageType;
  data: any;
}

const pagesOrder: _PageType[] = ['gains', 'achievementLevelUp', 'levelUp'];

export const GamificationAlertDialog: React.FC<_Props> = ({open, onClose, alert}) => {
  const {metadata} = alert;
  const [levelUps, setLevelUps] = useState<_GamificationAlertLevelUp | null>(null);
  const [gainedExp, setGainedExp] = useState<_GamificationAlertGainedExp | null>(null);
  const [gainedAchievements, setGainedAchievements] = useState<_GamificationAlertGainedAchievements | null>(null);
  const [achievementProgress, setAchievementProgress] = useState<_GamificationAlertAchievementProgress | null>(null);

  const [pages, setPages] = useState<_GamificationAlertPage[]>([]);
  const [currentPageIndex, setCurrentPageIndex] = useState<number>(0);

  useEffect(() => {
    // Create pages

    const pagesDict: Record<_PageType, _GamificationAlertPage[]> = {};

    // Create a page for gained experience
    if (gainedExp || achievementProgress) {
      pagesDict.gains = [
        {
          type: 'gains',
          data: {
            gainedExp,
            achievementProgress,
          },
        },
      ];
    } else {
      pagesDict.gains = [];
    }

    // Create a page for each gained achievement
    if (gainedAchievements?.gainedAchievements) {
      pagesDict.achievementLevelUp = Object.entries(gainedAchievements.gainedAchievements).flatMap(([key, value]) =>
        value.map(achievement => ({
          type: 'achievementLevelUp',
          data: {achievement},
        }))
      );
    } else {
      pagesDict.achievementLevelUp = [];
    }

    // Create a page for each level up
    if (levelUps?.levelUp) {
      pagesDict.levelUp = levelUps.levelUp.map(levelUp => ({
        type: 'levelUp',
        data: {levelUp},
      }));
    } else {
      pagesDict.levelUp = [];
    }

    // Set the pages in the order defined in `pagesOrder`
    setPages(pagesOrder.map(page => pagesDict[page]).flat());
  }, [levelUps, gainedExp, gainedAchievements, achievementProgress]);

  useEffect(() => {
    if (!alert?.metadata) return;

    const {
      levelUp: levelUpNew,
      gainedExp: gainedExpNew,
      gainedAchievements: gainedAchievementsNew,
      achievementProgress: achievementProgressNew,
    } = alert.metadata;

    // Set Level Up alert
    if (levelUpNew) {
      setLevelUps({levelUp: levelUpNew});
    }

    // Set Experience Gained alert
    if (gainedExpNew) {
      setGainedExp({
        gainedExperience: gainedExpNew.gainedExperience || 0,
        totalExperience: gainedExpNew.totalExperience || 0,
        levelProgress: gainedExpNew.levelProgress,
        progression: gainedExpNew.progression,
      });
    }

    // Set Achievements Gained alert
    if (gainedAchievementsNew) {
      setGainedAchievements({
        gainedAchievements: gainedAchievementsNew,
      });
    }

    // Set Achievement Progress alert
    if (achievementProgressNew) {
      setAchievementProgress({
        achievementProgress: achievementProgressNew,
      });
    }
  }, [alert]);

  const handleClose = async (confirmed: boolean): Promise<void> => {
    onClose(confirmed, metadata?.navigateTo as string | null);
  };

  const onNextPage = () => {
    setCurrentPageIndex(currentPageIndex + 1);
  };

  logger.debug('GamificationAlertDialog', {
    levelUps,
    gainedExp,
    gainedAchievements,
    achievementProgress,
    currentPage: pages[currentPageIndex],
  });

  const hasNextPage = currentPageIndex < pages.length - 1;
  const currentPage = pages[currentPageIndex];
  return (
    <Dialog
      titleVariant="h4"
      title={currentPageIndex === 0 ? trans('gamification.your_development') : null}
      confirmLabel={hasNextPage ? trans('base.continue') : trans('gamification.dialog_confirm')}
      open={open}
      onClose={() => {
        void handleClose(false);
      }}
      onConfirm={() => {
        if (hasNextPage) {
          onNextPage();
          return;
        }
        void handleClose(true);
      }}
      titleGap={5}
    >
      <div className="flex flex-col gap-2 w-full items-center">
        {!!currentPage && (
          <>
            {currentPage.type === 'gains' && (
              <>
                {Boolean(currentPage.data?.gainedExp?.gainedExperience) && (
                  <GamificationAlertCard
                    progressPosition="hidden"
                    progression={currentPage.data?.gainedExp?.progression}
                    type="level"
                    title={
                      <Typography variant="body">{trans('gamification.achievements.experienceGain.title')}</Typography>
                    }
                    image={getStatisticImage('experiencelogo')}
                    description={
                      <Typography bold className="text-green700">
                        +{currentPage.data?.gainedExp?.gainedExperience}
                      </Typography>
                    }
                  />
                )}

                {Boolean(currentPage.data?.gainedExp?.gainedExperience) && (
                  <GamificationAlertCard
                    progression={currentPage.data?.gainedExp?.progression}
                    type="level"
                    title={<Typography variant="body">{trans('gamification.level_progress')}</Typography>}
                    progress={currentPage.data?.gainedExp?.levelProgress}
                  />
                )}

                {Object.entries(achievementProgress?.achievementProgress ?? {}).map(([key, value]) => {
                  let title;

                  // Achievement not achieved yet, add a "on the way" prefix to the title.
                  if (value.progress?.currentLevel?.index === -1) {
                    title = trans('gamification.achievements.on_the_way', {
                      achievement: trans(value?.config?.title),
                    });
                  } else {
                    title = trans(value?.config?.title);
                  }

                  return (
                    <GamificationAlertCard
                      type="achievement"
                      progression={value?.progression}
                      title={
                        <div className="flex flex-row items-center justify-between w-full">
                          <Typography>{title} </Typography>
                          <Typography bold className="text-green700">
                            +{value?.points}
                          </Typography>
                        </div>
                      }
                      progress={value?.progress}
                    />
                  );
                })}
              </>
            )}

            {currentPage.type === 'achievementLevelUp' && (
              <GamificationAlertLevelUpViewCard
                title={trans('gamification.achievement_achieved', {
                  achievement: trans(currentPage?.data?.achievement?.config?.title),
                })}
                description={trans(currentPage?.data?.achievement?.config?.description)}
                image={currentPage?.data?.achievement?.config?.image}
              />
            )}

            {currentPage.type === 'levelUp' && (
              <GamificationAlertLevelUpViewCard
                title={trans('gamification.level_achieved', {
                  level: trans(currentPage?.data?.levelUp?.newLevelData?.title),
                })}
                description={trans(currentPage?.data?.levelUp?.newLevelData?.description)}
                image={currentPage?.data?.levelUp?.newLevelData?.image}
              />
            )}
          </>
        )}
      </div>
    </Dialog>
  );
};
