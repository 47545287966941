// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {Typography} from '@modules/Core/components/base/Typography';
import {useDialog} from '@modules/Core/hooks/ui/dialog';
import {getAchievementImage, getLevelImage} from '@modules/Core/util/imagesUtil';
import {GamificationAlertLevelUpViewCard} from '@modules/Statistics/components/general/GamificationAlertLevelUpViewCard';

interface _Props {
  title: string;
  description: string;
  image: string;
  type: 'achievement' | 'level';
}

export const ProfileLevelAchievementCard: React.FC<_Props> = ({type, title, description, image}) => {
  const dialog = useDialog();
  const onClick = () => {
    dialog.show({
      children: <GamificationAlertLevelUpViewCard title={title} description={description} image={image} />,
      hideButtons: true,
      showCloseButton: true,
    });
  };

  return (
    <div
      onClick={onClick}
      className="rounded-sm border-1 border-grey300 bg-white500 p-3 hover:bg-grey100 cursor-pointer h-full min-h-full"
    >
      <div className="flex flex-col gap-3 items-center h-full min-h-full">
        <img
          src={type === 'achievement' ? getAchievementImage(image, 'png') : getLevelImage(image, 'png')}
          className="h-12"
          alt={title}
        />
        <div className="h-16 flex flex-col gap-3 items-center">
          <Typography variant="h6" className="text-center">
            {title}
          </Typography>
          <Typography variant="body" className="text-center">
            {description}
          </Typography>
        </div>
      </div>
    </div>
  );
};
