// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useState} from 'react';
import 'react-quill/dist/quill.snow.css';
import {AvatarUser} from '@modules/Core/components/base/avatar/AvatarUser';
import {Dialog} from '@modules/Core/components/base/Dialog';
import {Typography} from '@modules/Core/components/base/Typography';
import useSnackbar from '@modules/Core/hooks/ui/snackbar';
import {formatDateTime} from '@modules/Core/util/dates';
import {useProfile} from '@modules/Profile/hooks/profile';
import {getProfilePic} from '@modules/Profile/util/profileUtil';
import {sendEmailToAllTeamMembers} from '@modules/Team/util/teamsUtil';
import {trans} from '@modules/Translations/util/i18n';

interface _EmailFormProps {
  teamId: string;
  isOpen: boolean;
  onClose: () => void;
  emailType?: string;
}

const EmailForm: React.FC<_EmailFormProps> = ({teamId, isOpen, onClose, emailType = 'TM'}) => {
  const [currentProfile] = useProfile();
  const [message, setMessage] = useState('');
  const [currentTime, setCurrentTime] = useState(new Date());
  const snackbar = useSnackbar();

  const [ReactQuill, setReactQuill] = useState<any>(null);

  // Dynamically import ReactQuill on client side
  useEffect(() => {
    import('react-quill')
      .then(module => {
        setReactQuill(() => module.default);
      })
      .catch(error => {
        console.error('Failed to load ReactQuill:', error);
      });
  }, []);

  useEffect(() => {
    if (isOpen) {
      setMessage('');
    }

    // Update the current time every second
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    // Clear the interval when the component is unmounted or the dialog is closed
    return () => clearInterval(intervalId);
  }, [isOpen]);

  async function sendEmail(emailData) {
    const success = await sendEmailToAllTeamMembers(emailData.teamId, emailData.message, emailData.emailType);
    if (success) {
      snackbar.success(trans('email.send_email_to_team_success'));
    }
  }

  const handleMessageChange = value => {
    setMessage(value);
  };

  const handleSend = () => {
    const emailData = {
      message,
      teamId,
      emailType,
    };
    void sendEmail(emailData);
    // Close the dialog
    onClose();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      size="md"
      title={trans('email.send_email_to_team_title')}
      cancelLabel={trans('email.send_email_to_team_cancel_btn')}
      confirmLabel={trans('email.send_email_to_team_send_btn')}
      onConfirm={handleSend}
      showCloseButton
    >
      <Typography variant="body1">
        <div className="d-flex justify-content-between mb-4">
          <div className="d-flex align-items-start">
            <div style={{display: 'flex', alignItems: 'center'}}>
              <AvatarUser label={currentProfile.fullName} image={getProfilePic(currentProfile)} />
            </div>
          </div>
          <div className="d-flex align-items-end">{formatDateTime(currentTime)}</div>
        </div>
      </Typography>

      {ReactQuill && <ReactQuill defaultValue={message} onChange={handleMessageChange} />}
    </Dialog>
  );
};

export default EmailForm;
