// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useState} from 'react';
import {ProgressBar} from '@modules/Core/components/base/ProgressBar';
import {SectionRow} from '@modules/Core/components/layout/SectionRow';
import {logger} from '@modules/Core/util/Logger';
import {PROFILE_SERVICES_TITLES} from '@modules/Profile/config/profileServicesLangProps';
import {_ShortProfile} from '@modules/Profile/types/profile.model';
import {getServiceIcon} from '@modules/Profile/util/profileUtil';
import {TeamProfileAvatarList} from '@modules/Team/components/common/TeamProfileAvatarList';
import {_TotalProgressData} from '@modules/Team/types/teamstatistics.model';
import {trans} from '@modules/Translations/util/i18n';

interface _Props {
  service: string;
  profiles: _ShortProfile[];
  progress: _TotalProgressData;
}

export const TeamExercisesRoundRow: React.FC<_Props> = ({progress, service, profiles}) => {
  const [dictProfiles, setDictProfiles] = useState<Record<string, _ShortProfile>>({});
  useEffect(() => {
    const profilesDictById = profiles?.reduce((acc, profile) => {
      acc[profile.id] = profile;
      return acc;
    }, {});

    logger.debug('TeamExercisesRoundRow', {profilesDictById, profiles});
    setDictProfiles(profilesDictById);
  }, [profiles]);

  const Icon = getServiceIcon(service);

  logger.debug('ExercisesRoundRow', {service});

  return (
    <SectionRow
      title={trans(PROFILE_SERVICES_TITLES[service])}
      subtitle={<TeamProfileAvatarList profiles={Object.keys(dictProfiles)} teamProfiles={dictProfiles} />}
      icon={Icon && <Icon />}
      buttonIcon={null}
      prefixContent={
        !!progress?.avg && (
          <div className="w-[56px]">
            <ProgressBar value={progress?.avg} color="success" size="xs" />
          </div>
        )
      }
    />
  );
};
