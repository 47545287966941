// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {ProgressBar} from '@modules/Core/components/base/ProgressBar';
import {_InstitutionOverviewTeam} from '@modules/Institution/types/institutionstatistics.model';
import {OverviewCard} from '@modules/Statistics/components/general/OverviewCard';
import {TeamProfileAvatarList} from '@modules/Team/components/common/TeamProfileAvatarList';
import {trans} from '@modules/Translations/util/i18n';

interface _Props {
  team?: _InstitutionOverviewTeam;
  view?: 'card' | 'expanded';
  className?: string;
  supportedMetrics?: string[];
}

export const OrganisationTeamsOverviewCard: React.FC<_Props> = ({supportedMetrics, className, view = 'card', team}) => {
  if (!team) {
    return null;
  }
  return (
    <OverviewCard
      supportedMetrics={supportedMetrics ?? []}
      className={className}
      view={view}
      data={{
        activity: team.activity,
        change: team.change,
        name: team.name,
        subtitle: trans('organisation.employee_count', {
          count: team.profiles?.length,
        }),
        metrics: team.metrics,
      }}
      renderAvatar={() => (
        <TeamProfileAvatarList profiles={Object.keys(team.profiles ?? {})} teamProfiles={team.profiles ?? {}} />
      )}
    />
  );
};
