export default [
  {
    source: '/',
    destination: '/flow/login',
    permanent: false,
  },
  {
    source: '/register/:inviteCode',
    destination: '/flow/login?type=register&inviteCode=:inviteCode',
    permanent: false,
  },
  {
    source: '/password/reset/:pwResetToken',
    destination: '/flow/reset-password?token=:pwResetToken',
    permanent: false,
  },
  {
    source: '/:slug(register|login|forgot-password|reset-password|verify-email)',
    destination: '/flow/login?type=:slug',
    permanent: false,
  },
  {
    source: '/:slug(softfactionary|logout)',
    destination: '/flow/:slug',
    permanent: false,
  },
  // Profile
  {
    source: '/dashboard',
    destination: '/flow/profile-dashboard',
    permanent: false,
  },
  // Shared results
  {
    source: '/flow/profile/shared-result/:slug',
    destination: '/flow/profile-shared-results',
    permanent: true,
  },
  {
    source: '/profile/shared-results',
    destination: '/flow/profile-shared-results',
    permanent: true,
  },
  {
    source: '/profile/shared-result/:slug',
    destination: '/flow/profile-shared-results',
    permanent: true,
  },
  {
    source: '/flow/shared-results/:slug',
    destination: '/flow/profile-shared-results',
    permanent: false,
  },
  // Third person feedback
  {
    source: '/profile/third-person-feedback/:slug',
    destination: '/flow/third-person-feedback',
    permanent: false,
  },
  {
    source: '/profile/third-person-feedback-single/:slug',
    destination: '/flow/third-person-feedback',
    permanent: false,
  },
  {
    source: '/profile/third-person-feedback',
    destination: '/flow/third-person-feedback',
    permanent: false,
  },
  // Settings
  {
    source: '/profile/account',
    destination: '/flow/profile-settings',
    permanent: false,
  },
  // Privacy settings
  {
    source: '/profile/privacy-settings',
    destination: '/flow/profile-settings/?active=privacy',
    permanent: false,
  },
  // Profile
  {
    source: '/profile',
    destination: '/flow/profile-info',
    permanent: false,
  },
  {
    source: '/teams/:teamId/flow/team-analysis/:slug',
    destination: '/flow/team/:teamId/team-analysis',
    permanent: false,
  },
  {
    source: '/teams/:teamId/flow/team-analysis',
    destination: '/flow/team/:teamId/team-analysis',
    permanent: false,
  },
  {
    source: '/team/:slug((?!team-analysis).*)',
    destination: '/team/team-analysis/:slug',
    permanent: false,
  },
  {
    source: '/team/:slug((?!team-development).*)',
    destination: '/team/team-development/:slug',
    permanent: false,
  },
  {
    source: '/teams/:teamId/flow/team-development/:slug',
    destination: '/flow/team/:teamId/team-development',
    permanent: false,
  },
  {
    source: '/teams/:teamId/flow/team-development',
    destination: '/flow/team/:teamId/team-development',
    permanent: false,
  },
  {
    source: '/teams/:teamId/members',
    destination: '/flow/team/:teamId/team-members',
    permanent: false,
  },
  {
    source: '/organisation/:slug((?!dashboard).*)',
    destination: '/organisation/dashboard/:slug*',
    permanent: false,
  },
];
