// @ts-nocheck
/* eslint-disable */

import {flowRoute} from '@/_core/util/util';
import {_AlertSeverity} from '@modules/Core/components/base/Alert';
import {ACTION_STATUS_ERROR} from '@modules/Core/config/constants';
import {dispatchReactEvent, reloadContentEngine, reloadProfileStateWithPromise} from '@modules/Core/util/eventsUtil';
import {logger} from '@modules/Core/util/Logger';
import {isArray, isObjectField, isString} from '@modules/Core/util/typesUtil';
import {dispatchAction} from '@modules/State/util/util';
import {performFlowAction} from '../../services/api';
import {finishFlow, updateFlowAction} from '../../state/flowSlice';
import {_FlowAction} from '../../types/action.model';
import {_FlowInput, _FlowShowConditionResult} from '../../types/core.model';
import {_FlowStep, _StepEvent} from '../../types/step.model';
import {getFlowActions, getFlowCurrentStep, getFlowData} from '../helpers/dataGetters';
import {FlowManager} from '../managers/flowManager';
import {EvaluatorFunctions} from './evaluatorFunctions';

export class FlowEvaluator {
  private readonly flowName: string;

  private readonly instanceKey: string;

  private readonly flowManager: FlowManager;

  constructor(flowName: string, instanceKey: string, flowManager: FlowManager) {
    this.flowName = flowName;
    this.instanceKey = instanceKey;
    this.flowManager = flowManager;
  }

  /**
   * All Eval functions should call this function at the end
   */
  doEvaluateExpression(expression: string, overwriteFields: Record<string, any> = {}): any {
    let {steps, input, actions} = getFlowData(this.flowName, this.instanceKey);

    steps = overwriteFields.steps || steps;

    input = overwriteFields.input || input;

    actions = overwriteFields.actions || actions;

    const result = FlowEvaluator.evaluate(
      expression,
      {
        steps,
        input,
        actions,
      },
      this
    );

    logger.debug('[FlowEvaluator] ', 'doEvaluateExpression', expression, {
      steps,
      input,
      actions,
      result,
    });

    return result;
  }

  /**
   * @param step
   * @returns {{show: boolean}|{show: boolean, nextStep: ((function(): void)|*)}}
   */
  evaluateStepConditions(step: _FlowStep): _FlowShowConditionResult {
    logger.debug('[FlowEvaluator] ', 'evaluateStepConditions', step.flowName, step.type, step?.showConditions);

    const {showConditions} = step;
    if (!showConditions) {
      this.flowManager.onUpdateFlowData({show: true});
      return {show: true};
    }

    for (const showCondition of showConditions) {
      const showConditionResult = this.doEvaluateExpression(showCondition.expression);
      logger.debug('[FlowEvaluator] ', `evaluateStepConditions => ${showConditionResult}`, {
        expression: showCondition.expression,
        nextStep: showCondition.nextStep,
      });
      if (!showConditionResult) {
        this.flowManager.onUpdateFlowData({show: false});
        return {
          show: false,
          nextStep: showCondition.nextStep,
        };
      }
    }

    this.flowManager.onUpdateFlowData({show: true});
    return {show: true};
  }

  evaluateStepEvent(event: _StepEvent): void {
    if (event.handle) {
      logger.info('[FlowEvaluator] Handling event');
      this.doEvaluateExpression(event.handle);
    }
  }

  evalFlowOnFinish(): any {
    const {steps, input, actions, options} = getFlowData(this.flowName, this.instanceKey);

    const {onFinish} = options ?? {};

    if (!onFinish) {
      logger.info('[FlowEvaluator] No onFinish found, skipping.');
      return {};
    }

    return this.evaluateObjectFieldsInternal(onFinish as Record<string, any>, steps, input, actions, ['handle']);
  }

  evaluateObjectFieldsInternal(
    obj: object | string,
    steps: Record<string, _FlowStep> = {},
    input: _FlowInput = {},
    actions: Record<string, _FlowAction> = {},
    excludedFields: string[] = []
  ): any {
    return FlowEvaluator.evaluateObjectFields(obj, steps, input, actions, excludedFields, this);
  }

  // type of instance is any on purpose. This gives us some flexibility in the future to replace the instance with a different object
  // and hence have its functionality within the flow engine.
  static evaluateObjectFields(
    obj: object | string,
    steps: Record<string, _FlowStep> = {},
    input: _FlowInput = {},
    actions: Record<string, _FlowAction> = {},
    excludedFields: string[] = [],
    instance: any = null
  ): any {
    // Used in eval
    const result: Record<string, any> = {};
    if (!obj) {
      return {};
    }

    const context = {
      steps,
      input,
      actions,
    };

    // if string, execute
    if (typeof obj === 'string') {
      return FlowEvaluator.evaluate(obj, context, instance);
    }
    // Evaluate each option
    for (const [key, value] of Object.entries(obj)) {
      if (!value) {
        continue;
      }

      if (excludedFields.includes(key)) {
        result[key] = value;
        continue;
      }

      // check if value is a dict
      if (isObjectField(value)) {
        result[key] = FlowEvaluator.evaluateObjectFields(
          value as object,
          steps,
          input,
          actions,
          excludedFields,
          instance
        );
      } else if (isString(value)) {
        // Step field requires data from another step, extract it from the steps list using eval
        result[key] = FlowEvaluator.evaluate(value as string, context, instance);
      } else if (isArray(value)) {
        // Evaluate each element in the array
        result[key] = (value as string[]).map((element: string) =>
          FlowEvaluator.evaluateObjectFields(element, steps, input, actions, excludedFields, instance)
        );
      }
    }

    return result;
  }

  /**
   * This fn will evaluate the expression in context of:
   * 1. Static functions from EvaluatorFunctions
   * 2. Instance functions from the flowManager ( Can actually be any object with functions )
   * 3. Context variables passed to the function
   * @param str
   * @param context
   * @param instance
   * @returns {*|null|string}
   */
  static evaluate(str: string, context: Record<string, any>, instance: any = null): any {
    if (!str) {
      return '';
    }

    EvaluatorFunctions.registerAllFunctions();

    // Flatten the functions from EvaluatorFunctions into a single context object
    const staticContext = Object.entries(EvaluatorFunctions.functions).reduce((acc: Record<string, any>, [, funcs]) => {
      Object.entries(funcs).forEach(([funcName, func]) => {
        acc[funcName] = func;
      });
      return acc;
    }, {});

    let instanceContext = {};
    if (instance) {
      // Include instance-specific functions by getting the names and functions from this instance
      instanceContext = Object.getOwnPropertyNames(Object.getPrototypeOf(instance))
        .filter(prop => typeof instance[prop] === 'function')
        .reduce((acc: Record<string, any>, propName) => {
          acc[propName] = instance[propName].bind(instance); // Bind instance functions to keep 'this' context
          return acc;
        }, {});
    }

    // Merge static and instance contexts
    const combinedContext = {...staticContext, ...instanceContext, ...context};

    // Extract keys (function names) and values (function implementations) from the combined context
    const contextKeys = Object.keys(combinedContext).join(', ');
    const contextValues = Object.values(combinedContext);

    // Create the dynamic function with contextKeys as parameters and str as the body

    // Execute the dynamic function with contextValues as arguments
    try {
      // eslint-disable-next-line no-new-func, no-implied-eval
      const dynamicFunction = new Function(contextKeys, `return ${str};`);

      return dynamicFunction(...contextValues);
    } catch (error) {
      logger.error('Error executing dynamic function:', {error, str, isArray: isArray(str), isString: isString(str)});
      return null;
    }
  }

  /**
   * Flow Functionalities
   */

  step(stepName: string, executeEffects = true): void {
    this.flowManager.goToStepByName(stepName, executeEffects);
  }

  toFlow(
    flowName: string,
    teamId?: string,
    params: Record<string, any> = {},
    origin?: string,
    withLoader?: boolean
  ): void {
    if (!this.flowManager?.onNavigate) {
      logger.error('FlowManager or onNavigate not found');
      return;
    }
    const route = flowRoute(flowName, teamId, params, origin);
    if (!route) {
      logger.error('Route not found for flow', flowName);
      return;
    }
    this.flowManager.onNavigate(route, undefined, undefined, undefined, withLoader);
  }

  nextStep(): void {
    this.flowManager.nextStep();
  }

  finishFlow(): void {
    logger.info('[FlowEvaluator] Finishing flow', this.flowName, this.instanceKey);
    void dispatchAction(finishFlow({name: `${this.flowName}_${this.instanceKey}`}));
  }

  async action(actionName: string, callback?: any): Promise<Record<string, any>> {
    const {actions} = getFlowData(this.flowName, this.instanceKey);

    if (!actions?.[actionName]) {
      logger.error(`[FlowEvaluator] Action ${actionName} not found`);
      return {};
    }

    const actionMap = {
      [actionName]: actions[actionName],
    };

    try {
      const result = await this.flowManager.executeFlowActions(actionMap);
      callback?.(result);
      return result[actionName];
    } catch (e) {
      logger.error(e);
    }

    return {};
  }

  // TODO: move these to constants
  handleStep(stepName: string): void {
    if (stepName === 'finishFlow') {
      this.finishFlow();
    } else if (stepName.startsWith('redirect:')) {
      if (!this.flowManager?.onNavigate) {
        logger.error('FlowManager or onNavigate not found');
        return;
      }
      this.flowManager.onNavigate(stepName.replace('redirect:', ''));
    } else {
      this.step(stepName);
    }
  }

  async actionWithStep(actionName: string, stepName: string, failStepName?: string): Promise<void> {
    try {
      const {data} = await this.action(actionName);

      if (data?.status === 'OK') {
        if (data.message) {
          this.flowManager.snackbar?.success(data.message as string);
        }
        this.handleStep(stepName);
      } else {
        const errors = Object.values((data?.errors as Record<string, any>) || {});
        if (errors.length > 0) {
          this.flowManager.snackbar?.danger(errors.join('\n'));
        }
        if (failStepName) {
          this.handleStep(failStepName);
        } else {
          this.flowManager.snackbar?.danger(`Action ${actionName} failed`);
        }
      }
    } catch (e) {
      logger.error(e);
    }
  }

  currentStep(): _FlowStep | undefined {
    return getFlowCurrentStep(this.flowName, this.instanceKey);
  }

  showAlert(message: string, severity: _AlertSeverity = 'info'): void {
    if (!this.flowManager?.snackbar) {
      logger.error('FlowManager or showAlert not found');
      return;
    }
    logger.debug('[FlowEvaluator] showAlert', message, severity);

    let snackbarFn;
    switch (severity) {
      case 'error':
        snackbarFn = this.flowManager.snackbar.danger;
        break;
      case 'warning':
        snackbarFn = this.flowManager.snackbar.warning;
        break;
      case 'success':
        snackbarFn = this.flowManager.snackbar.success;
        break;
      default:
        snackbarFn = this.flowManager.snackbar.info;
    }
    snackbarFn?.(message);
  }

  async executeActions(
    flowName: string,
    instanceKey: string,
    actionsToExecute: Record<string, _FlowAction>
  ): Promise<Record<string, any>> {
    const {steps, input, actions} = getFlowData(flowName, instanceKey);
    let context = {
      steps,
      input,
      actions,
    };
    const actionsResults: Record<string, any> = {};

    if (!actionsToExecute) {
      return {};
    }

    const startLoader = Object.values(actionsToExecute).filter(action => action.startLoader).length > 0;
    if (startLoader) {
      dispatchReactEvent('navigate.start', {flowName, actionsToExecute});
    }

    for (const actionName of Object.keys(actionsToExecute)) {
      // Action might be executed middle of flow, no need to execute it again
      // TODO  @Sherif FIX THIS !!!
      // if (actionsToExecute?.status === 'done') {
      //   continue;
      // }

      let actions = getFlowActions(flowName, instanceKey);

      context = {
        steps,
        input,
        actions,
      };

      const action = actionsToExecute[actionName];
      const {method} = action;
      const params = this.evaluateParams(steps, action.params, input, actions);
      const {condition} = action;
      const {afterEffects} = action;

      // Checks for action condition
      if (condition?.expression) {
        const conditionResult = FlowEvaluator.evaluate(condition?.expression, context, this);
        if (!conditionResult) {
          logger.debug(
            `Condition for action ${actionName} is false, skipping. {${condition?.expression}}: ${conditionResult}`
          );
          continue;
        }
      }

      const requestBody = {
        method,
        params,
      };
      actionsResults[actionName] = await performFlowAction(requestBody);

      if (
        (actionsResults[actionName]?.data?.status === 'OK' || actionsResults[actionName]?.data?.status === 'success') &&
        actionsResults[actionName]?.data?.message
      ) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        this.flowManager.snackbar.success(actionsResults[actionName]?.data?.message);
      }

      if (
        actionsResults[actionName]?.data?.status === ACTION_STATUS_ERROR &&
        actionsResults[actionName]?.data?.message
      ) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        this.flowManager.snackbar?.danger(actionsResults[actionName]?.data?.message);
      }

      await dispatchAction(
        updateFlowAction({
          name: `${flowName}_${instanceKey}`,
          actionName,
          data: actionsResults[actionName]?.data,
        })
      );

      actions = getFlowActions(flowName, instanceKey);

      actions[actionName] = actionsResults[actionName]?.data;
      if (afterEffects) {
        for (const afterEffect of afterEffects) {
          this.doEvaluateExpression(afterEffect.expression, {
            actions,
          });
        }
      }
    }

    const reloadProfile = Object.values(actionsToExecute).filter(action => action.reloadProfile).length > 0;
    if (reloadProfile) {
      logger.debug('[executeActions] reloadProfile', actionsToExecute);
      await reloadProfileStateWithPromise();
    }

    const reloadContent = Object.values(actionsToExecute).filter(action => action.reloadContent).length > 0;
    if (reloadContent) {
      reloadContentEngine();
    }

    return actionsResults;
  }

  evaluateParams(
    steps?: Record<string, _FlowStep>,
    params?: Record<string, string>,
    input?: _FlowInput,
    actions?: Record<string, _FlowAction>
  ): Record<string, any> {
    // Input used in eval
    if (!params) {
      return [];
    }
    const parsedParams: Record<string, any> = {};
    const context = {
      steps,
      input,
      actions,
    };
    for (const param of Object.keys(params)) {
      parsedParams[param] = FlowEvaluator.evaluate(params[param], context, this);
    }
    return parsedParams;
  }
}
