// @ts-nocheck
/* eslint-disable */

import {logger} from '@modules/Core/util/Logger';

export function urlSearchParamsToDict(entries: URLSearchParams): Record<string, string> {
  const dict: Record<string, string> = {};
  entries.forEach((value, key) => {
    dict[key] = value;
  });
  return dict;
}

function queryToDict(queryString: string): Record<string, string> {
  // Remove the leading "?" if present
  const cleanedQueryString = queryString.startsWith('?') ? queryString.slice(1) : queryString;

  // Create a URLSearchParams object
  const params = new URLSearchParams(cleanedQueryString);

  // Convert URLSearchParams to a plain object
  const result: Record<string, string> = {};

  params.forEach((value, key) => {
    result[key] = value;
  });

  return result;
}

export function getWindowLocationData(): {pathname: string; search: Record<string, string>; hash: string} {
  return {
    pathname: window.location.pathname,
    search: queryToDict(window.location.search),
    hash: window.location.hash,
  };
}

export function getFullPath(): string {
  return `${window.location.pathname}${window.location.search}`;
}

export const createUrl = (path: string, params: URLSearchParams | null): string => {
  const queryString = params?.toString();
  return `${path}${queryString ? `?${queryString}` : ''}`;
};

export const isSameUrl = (fromUrl: string, toUrl: string): boolean => {
  logger.debug('[useAppNavigate] Checking if', fromUrl, 'is the same as', toUrl);
  if (fromUrl === toUrl) {
    return true;
  }

  return redirectMatches.length > 0;
};
