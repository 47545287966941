// @ts-nocheck
/* eslint-disable */

import {_Dialog} from '@modules/Core/components/base/Dialog';
import {_ActionsTableHeaderSelectValue} from '@modules/Core/components/base/table/actions/ActionsTableHeader';
import {useTableHeaderActions} from '@modules/Core/components/base/table/actions/tableHeaderActions';
import {Typography} from '@modules/Core/components/base/Typography';
import {MANAGE_INST_OWNERSHIP, MANAGE_INST_ROLES_PERMISSION} from '@modules/Core/config/rolesConstants';
import useSnackbar from '@modules/Core/hooks/ui/snackbar';
import {
  INSTITUTION_ADMIN_ROLE,
  INSTITUTION_MEMBER_ROLE,
  INSTITUTION_OWNER_ROLE,
  TEAM_ADMIN_ROLE,
  TEAM_MEMBER_ROLE,
  TEAM_OWNER_ROLE,
} from '@modules/Core/types/rolesAndPermissions.model';
import {reloadContentEngine} from '@modules/Core/util/eventsUtil';
import {can, hasRole, performChangeRoleAction} from '@modules/Core/util/rolesAndPermissionsUtil';
import {hasLegacyMembership} from '@modules/Core/util/subscriptionUtil';
import {copyObject} from '@modules/Core/util/util';
import {performFlowAction} from '@modules/FlowEngine/services/api';
import {currentInstitution} from '@modules/Institution/util/institutionUtil';
import {_Profile} from '@modules/Profile/types/profile.model';
import {isFreeSubscription} from '@modules/Profile/util/membershipsUtil';
import {currentProfile} from '@modules/Profile/util/profileUtil';
import {trans} from '@modules/Translations/util/i18n';

const dialogLanguageProps: Record<string, Record<string, string>> = {
  [TEAM_ADMIN_ROLE]: {
    title: 'roles.dialogs.organisation.team.promote_to_admin.title',
    message_singular_part1: 'roles.dialogs.organisation.team.promote_to_admin.message_singular_part1',
    message_singular_part2: 'roles.dialogs.organisation.team.promote_to_admin.message_singular_part2',
    message_plural_part1: 'roles.dialogs.organisation.team.promote_to_admin.message_plural_part1',
    message_plural_part2: 'roles.dialogs.organisation.team.promote_to_admin.message_plural_part2',
  },
  [TEAM_MEMBER_ROLE]: {
    title: 'roles.dialogs.organisation.team.change_to_team_member.title',
    message_singular_part1: 'roles.dialogs.organisation.team.change_to_team_member.message_singular_part1',
    message_singular_part2: 'roles.dialogs.organisation.team.change_to_team_member.message_singular_part2',
    message_plural_part1: 'roles.dialogs.organisation.team.change_to_team_member.message_plural_part1',
    message_plural_part2: 'roles.dialogs.organisation.team.change_to_team_member.message_plural_part2',
  },
  [TEAM_OWNER_ROLE]: {
    title: 'roles.dialogs.organisation.team.handover_ownership.title',
    message_self_part1: 'roles.dialogs.organisation.team.handover_ownership.message_self_part1',
    message_self_part2: 'roles.dialogs.organisation.team.handover_ownership.message_self_part2',
    message_other_part1: 'roles.dialogs.organisation.team.handover_ownership.message_other_part1',
    message_other_part2: 'roles.dialogs.organisation.team.handover_ownership.message_other_part2',
  },
  [INSTITUTION_ADMIN_ROLE]: {
    title: 'roles.dialogs.organisation.promote_to_organisation_admin.title',
    message_singular: 'roles.dialogs.organisation.promote_to_organisation_admin.message_singular',
    message_plural: 'roles.dialogs.organisation.promote_to_organisation_admin.message_plural',
  },
  [INSTITUTION_MEMBER_ROLE]: {
    title: 'roles.dialogs.organisation.change_to_organisation_member.title',
    message_singular: 'roles.dialogs.organisation.change_to_organisation_member.message_singular',
    message_plural: 'roles.dialogs.organisation.change_to_organisation_member.message_singular',
  },
  [INSTITUTION_OWNER_ROLE]: {
    title: 'roles.dialogs.organisation.handover_organisation_ownership.title',
    message: 'roles.dialogs.organisation.handover_organisation_ownership.message',
  },
};

export interface _ChosenProps {
  hasOrgOwners: boolean;
  hasOrgAdmins: boolean;
  hasOrgMembers: boolean;
  hasTeamOwners: boolean;
  hasTeamAdmins: boolean;
  hasTeamMembers: boolean;
  multipleSelected: boolean;
  multipleRolesSelected: boolean;
}

const defaultProps: _ChosenProps = {
  hasOrgOwners: false,
  hasOrgAdmins: false,
  hasOrgMembers: false,
  hasTeamOwners: false,
  hasTeamAdmins: false,
  hasTeamMembers: false,
  multipleSelected: false,
  multipleRolesSelected: false,
};

const NO_PERMISSION_ACTION = 'roles.errors.no_permission_action';
const HANDOVER_OWNERSHIP_DISABLED = 'roles.errors.handover_ownership_disabled';
const SELECT_SAME_ROLES_FOR_ACTION_ORG = 'roles.errors.select_same_roles_for_action_org';
const SELECTED_USERS_ALREADY_HAVE_ROLE = 'roles.errors.selected_users_already_have_role';
const ONE_OWNER_NEEDED = 'roles.errors.one_owner_needed';

export const useOrganisationRoleMgmtActions = (
  institutionId: string,
  profiles: Record<string, _Profile>,
  setDialogConfig: (config: _Dialog) => void,
  setShowDialog: (show: boolean) => void
): {
  actions: _ActionsTableHeaderSelectValue[];
  chosenProfiles: string[];
  canSelectProfile: (profile: _Profile, currentSubscriptionProps?: _ChosenProps) => boolean;
  onProfileSelected: (profileId: string) => void;
  onClearSelectedProfiles: () => void;
} => {
  const snackbar = useSnackbar();

  const getAllowedActions = (selectedProfilesParams: _ChosenProps): _ActionsTableHeaderSelectValue[] => {
    const options: _ActionsTableHeaderSelectValue[] = [];

    const canManageOrgRoles = can(MANAGE_INST_ROLES_PERMISSION, {
      institution_id: institutionId,
    });

    const canTransferOrgOwnership = can(MANAGE_INST_OWNERSHIP, {
      institution_id: institutionId,
    });

    const orgOwnerSelected = selectedProfilesParams.hasOrgOwners;
    const multipleProfileSelected = selectedProfilesParams.multipleSelected;
    const onlyOwnerSelected = orgOwnerSelected && !multipleProfileSelected;
    const hasNoPermission = !!(!canManageOrgRoles || (onlyOwnerSelected && !canTransferOrgOwnership));

    // Logic to determine which actions to push to menuActions
    // Based on the screenshot you provided and the current profile's permissions

    options.push(transferOwnershipAction(selectedProfilesParams, canTransferOrgOwnership));
    options.push(promoteToAdminAction(selectedProfilesParams, hasNoPermission, canManageOrgRoles));
    options.push(changeToOrgMemberAction(selectedProfilesParams, hasNoPermission, canManageOrgRoles));
    options.push(
      deleteFromOrgAction(selectedProfilesParams, hasNoPermission, canManageOrgRoles, canTransferOrgOwnership)
    );

    return options;
  };

  const getCurrentProps = (selectedRowsIds: string[], rows: Record<string, _Profile>): _ChosenProps => {
    const newProfileParams = copyObject(defaultProps)!;

    if (!selectedRowsIds?.length) {
      return newProfileParams;
    }

    for (const row of selectedRowsIds) {
      // row = id, profiles is  an array
      const profile = rows[row];

      newProfileParams.hasTeamOwners = hasRole(profile, TEAM_OWNER_ROLE) || newProfileParams.hasTeamOwners;
      newProfileParams.hasTeamAdmins = hasRole(profile, TEAM_ADMIN_ROLE) || newProfileParams.hasTeamAdmins;
      newProfileParams.hasTeamMembers = hasRole(profile, TEAM_MEMBER_ROLE) || newProfileParams.hasTeamMembers;
      newProfileParams.hasOrgOwners =
        hasRole(profile, INSTITUTION_OWNER_ROLE, {
          institution_id: institutionId,
        }) || newProfileParams.hasOrgOwners;
      newProfileParams.hasOrgAdmins =
        hasRole(profile, INSTITUTION_ADMIN_ROLE, {
          institution_id: institutionId,
        }) || newProfileParams.hasOrgAdmins;
      newProfileParams.hasOrgMembers =
        hasRole(profile, INSTITUTION_MEMBER_ROLE, {
          institution_id: institutionId,
        }) || newProfileParams.hasOrgMembers;
    }

    newProfileParams.multipleSelected = selectedRowsIds.length > 1;
    newProfileParams.multipleRolesSelected =
      (newProfileParams.hasOrgMembers ? 1 : 0) +
        (newProfileParams.hasOrgOwners ? 1 : 0) +
        (newProfileParams.hasOrgAdmins ? 1 : 0) >=
      2;

    return newProfileParams;
  };

  const {selectedRowsIds, onClearSelection, onRowSelected, actions} = useTableHeaderActions(
    profiles,
    getAllowedActions,
    getCurrentProps,
    null,
    defaultProps,
    trans('roles.choose_members_same_role')
  );

  async function doHandleRoleChange(profileId: string[], role: string) {
    // Implement role change action
    try {
      const response = await performChangeRoleAction(profileId, role, {
        institution_id: institutionId,
      });

      if (response?.data?.status === 'OK') {
        snackbar.success(response?.data?.message);
        setShowDialog(false);
        onClearSelection();

        reloadContentEngine();
      } else {
        snackbar.danger(response?.data?.message);
      }
    } catch (e) {
      // handled by AuthTokenInterceptor => errorMessage()
    }
    setShowDialog(false);
  }

  async function handleRoleChange(role: string, selectedProfilesParams: _ChosenProps) {
    const {title} = dialogLanguageProps[role];

    let message = selectedProfilesParams.multipleSelected
      ? dialogLanguageProps[role].message_plural
      : dialogLanguageProps[role].message_singular;

    if (role === INSTITUTION_OWNER_ROLE) {
      // Owner role is only singular
      message = dialogLanguageProps[role].message;
    }

    const names = Object.values(profiles || {})
      .filter(profile => selectedRowsIds.includes(profile.id))
      .map(profile => profile.fullName)
      .join(', ');

    setDialogConfig({
      title: trans(title),
      children: (
        <Typography
          dangerouslySetInnerHTML={{
            __html: trans(message, {
              names,
            }),
          }}
        />
      ),
      onConfirm: () => {
        void doHandleRoleChange(selectedRowsIds, role);
      },
    });

    setShowDialog(true);
  }

  async function handleDeleteMembers(selectedProfilesParams: _ChosenProps) {
    if (selectedRowsIds?.length === 0) {
      return;
    }
    let langProps = {
      sub: {
        self: {
          title: 'roles.dialogs.organisation.remove_from_org_with_sub.title',
          message: 'roles.dialogs.organisation.remove_from_org_with_sub.message_you',
        },
        plural: {
          title: 'roles.dialogs.organisation.remove_from_org_with_sub.title',
          message: 'roles.dialogs.organisation.remove_from_org_with_sub.message_plural',
        },
        singular: {
          title: 'roles.dialogs.organisation.remove_from_org_with_sub.title',
          message: 'roles.dialogs.organisation.remove_from_org_with_sub.message_singular',
        },
      },
      no_sub: {
        self: {
          title: 'roles.dialogs.organisation.remove_from_org_no_sub.title',
          message: 'roles.dialogs.organisation.remove_from_org_no_sub.message_you',
        },
        plural: {
          title: 'roles.dialogs.organisation.remove_from_org_no_sub.title',
          message: 'roles.dialogs.organisation.remove_from_org_no_sub.message_plural',
        },
        singular: {
          title: 'roles.dialogs.organisation.remove_from_org_no_sub.title',
          message: 'roles.dialogs.organisation.remove_from_org_no_sub.message_singular',
        },
      },
    };

    const chosenMembers = Object.entries(profiles).filter(([id, member]) => selectedRowsIds.includes(id));

    const hasSub =
      !hasLegacyMembership(currentInstitution()) &&
      chosenMembers.some(([id, member]) => !isFreeSubscription(member.subscriptionData?.subscription));

    langProps = hasSub ? langProps.sub : langProps.no_sub;

    langProps =
      selectedRowsIds.length > 1
        ? langProps.plural
        : selectedRowsIds[0] === currentProfile()?.id
          ? langProps.self
          : langProps.singular;

    const name = Object.values(profiles || {})
      .filter(profile => selectedRowsIds.includes(profile.id))
      .map(profile => profile.fullName)
      .join(', ');

    setDialogConfig({
      title: trans(langProps.title),
      children: (
        <Typography
          dangerouslySetInnerHTML={{
            __html: trans(langProps.message, {
              name,
            }),
          }}
        />
      ),
      onConfirm: () => {
        void doHandleDeleteMembers(selectedRowsIds);
      },
    });

    setShowDialog(true);
  }

  async function doHandleDeleteMembers(profileIds: string[]) {
    const method = 'organisation.remove';
    const params = {
      institutionId,
      profileIds,
    };

    try {
      const response = await performFlowAction(
        {
          method,
          params,
        },
        {
          refetchProfile: true,
          loadingIndicator: true, // TODO @Sherif Really?
        }
      );

      if (response.data?.status === 'success') {
        snackbar.success(trans(response?.data?.message));

        setShowDialog(false);

        reloadContentEngine();

        onClearSelection();
      } else {
        snackbar.danger(trans(response?.data?.message));
      }
    } catch (e) {
      // handled by AuthTokenInterceptor => errorMessage()
    }
    setShowDialog(false);
  }

  function transferOwnershipAction(
    selectedProfilesParams: _ChosenProps,
    canTransferOrgOwnership: boolean
  ): _ActionsTableHeaderSelectValue {
    const disabled =
      !canTransferOrgOwnership || selectedProfilesParams.hasOrgOwners || selectedProfilesParams.multipleSelected;
    return {
      label: trans('roles.actions.organisation.handover_ownership'),
      isDisabled: disabled,
      tooltip: !disabled ? null : trans(!canTransferOrgOwnership ? NO_PERMISSION_ACTION : HANDOVER_OWNERSHIP_DISABLED),
      action: () => {
        void handleRoleChange(INSTITUTION_OWNER_ROLE, selectedProfilesParams);
      },
    };
  }

  function promoteToAdminAction(
    selectedProfilesParams: _ChosenProps,
    hasNoPermission: boolean,
    canManageOrgRoles: boolean
  ): _ActionsTableHeaderSelectValue {
    const disabled = !canManageOrgRoles || selectedProfilesParams.hasOrgOwners || selectedProfilesParams.hasOrgAdmins;
    return {
      label: trans('roles.actions.organisation.promote_to_organisation_admin'),
      isDisabled: disabled,
      tooltip: !disabled
        ? null
        : trans(
            hasNoPermission
              ? NO_PERMISSION_ACTION
              : selectedProfilesParams.multipleRolesSelected
                ? SELECT_SAME_ROLES_FOR_ACTION_ORG
                : selectedProfilesParams.hasOrgAdmins
                  ? SELECTED_USERS_ALREADY_HAVE_ROLE
                  : selectedProfilesParams.hasOrgOwners
                    ? ONE_OWNER_NEEDED
                    : NO_PERMISSION_ACTION
          ),
      action: () => {
        void handleRoleChange(INSTITUTION_ADMIN_ROLE, selectedProfilesParams);
      },
    };
  }

  function changeToOrgMemberAction(
    selectedProfilesParams: _ChosenProps,
    hasNoPermission: boolean,
    canManageOrgRoles: boolean
  ): _ActionsTableHeaderSelectValue {
    const disabled = !canManageOrgRoles || selectedProfilesParams.hasOrgOwners || selectedProfilesParams.hasOrgMembers;
    return {
      label: trans('roles.actions.organisation.change_to_organisation_member'),
      isDisabled: disabled,
      tooltip: !disabled
        ? null
        : trans(
            hasNoPermission
              ? NO_PERMISSION_ACTION
              : selectedProfilesParams.multipleRolesSelected
                ? SELECT_SAME_ROLES_FOR_ACTION_ORG
                : selectedProfilesParams.hasTeamOwners
                  ? ONE_OWNER_NEEDED
                  : selectedProfilesParams.hasOrgMembers
                    ? SELECTED_USERS_ALREADY_HAVE_ROLE
                    : NO_PERMISSION_ACTION
          ),
      action: () => {
        void handleRoleChange(INSTITUTION_MEMBER_ROLE, selectedProfilesParams);
      },
    };
  }

  function deleteFromOrgAction(
    selectedProfilesParams: _ChosenProps,
    hasNoPermission: boolean,
    canManageOrgRoles: boolean,
    canTransferOrgOwnership: boolean
  ): _ActionsTableHeaderSelectValue {
    const disabled = !canManageOrgRoles || selectedProfilesParams.hasOrgOwners;
    return {
      label: trans('roles.actions.organisation.delete_from_organisation'),
      isDisabled: disabled,
      tooltip: !disabled
        ? null
        : hasNoPermission
          ? trans(NO_PERMISSION_ACTION)
          : selectedProfilesParams.hasOrgOwners && canTransferOrgOwnership
            ? trans(ONE_OWNER_NEEDED)
            : trans(NO_PERMISSION_ACTION),
      action: () => {
        void handleDeleteMembers(selectedProfilesParams);
      },
    };
  }

  return {
    actions,
    chosenProfiles: selectedRowsIds,
    onClearSelectedProfiles: onClearSelection,
    onProfileSelected: onRowSelected,
  };
};
