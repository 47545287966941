// @ts-nocheck
/* eslint-disable */

export const SOFTFACT_ACTIVITY_METRIC = 'softfactActivity';
export const EXERCISES_COMPLETED_METRIC = 'exercisesCompleted';
export const EXPERIENCE_GAIN_METRIC = 'experienceGain';
export const USER_ACTIVITY_METRIC = 'userActivity';

export const GAINED_EXP_GAMIFICATION_ALERT = 'gainedExp';
export const GAINED_ACHIEVEMENTS_GAMIFICATION_ALERT = 'gainedAchievements';
export const LEVELUP_GAMIFICATION_ALERT = 'levelUp';
export const ACHIEVEMENT_PROGRESS_GAMIFICATION_ALERT = 'achievementProgress';
