// @ts-nocheck
/* eslint-disable */

import React, {FC, useState} from 'react';
import {IconButton} from '@modules/Core/components/base/buttons/IconButton';
import {IconExpandLess, IconExpandMore} from '@modules/Core/components/base/Icons/Icons';
import {_TypographyVariants, Typography} from '@modules/Core/components/base/Typography';
import '../cards/RoleCard.scss';

export interface _AccordionProps {
  title?: string;
  titleSuffix?: React.ReactNode;
  titlePrefix?: React.ReactNode;
  subtitle?: string;
  titleVariant?: _TypographyVariants;
  children?: React.ReactNode;
}

export const Accordion: FC<_AccordionProps> = ({
  title,
  titleVariant = 'h6',
  subtitle,
  children,
  titleSuffix,
  titlePrefix,
}) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <div className="flex px-3 py-2 flex-col items-start gap-3 rounded-sm border-1 border-grey300 bg-white500 w-full">
      {/* header */}
      <div className="flex flex-row items-center justify-between w-full gap-3">
        {titlePrefix && <div className="flex">{titlePrefix}</div>}
        <div className="flex flex-col gap-3">
          {title && <Typography variant={titleVariant}>{title}</Typography>}
          {subtitle && <Typography variant="caption">{subtitle}</Typography>}
        </div>
        {titleSuffix && <div className="flex-grow flex justify-end">{titleSuffix}</div>}
        <IconButton onClick={(): void => setExpanded(!expanded)} icon={expanded ? IconExpandLess : IconExpandMore} />
      </div>

      {Boolean(expanded) && children}
    </div>
  );
};
