// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {Typography} from '@modules/Core/components/base/Typography';
import {SERVICE_STATUS_FINISHED, SERVICE_STATUS_STARTED} from '@modules/Core/config/services';
import {_ProfileAchievement, _ProfileLevel} from '@modules/Core/types/gamification.model';
import {logger} from '@modules/Core/util/Logger';
import {Photo} from '@modules/Profile/components/info/Photo';
import {ProfileDevelopmentStatusCard} from '@modules/Profile/components/public/ProfileDevelopmentStatusCard';
import {ProfileLevelAchievementCard} from '@modules/Profile/components/public/ProfileLevelAchievementCard';
import {PublicProfilePermissionedSection} from '@modules/Profile/components/public/PublicProfilePermissionedSection';
import {_ServiceStatusStatus} from '@modules/Profile/types/modules.model';
import {getProfilePic} from '@modules/Profile/util/profileUtil';
import {trans} from '@modules/Translations/util/i18n';

interface _Props {
  name: string;
  permissions: {
    profilePicture: boolean;
    description: boolean;
    developmentStatus: boolean;
    achievements: boolean;
    levels: boolean;
    positions: boolean;
  };
  profilePicture?: string;
  profileDescription?: string;
  profileDevelopmentStatus?: Record<
    string,
    {
      status: _ServiceStatusStatus;
      progress: number;
    }
  >;
  profileAchievements: Record<string, _ProfileAchievement>;
  profileLevels: _ProfileLevel[];
  position: string;
}

export const PublicProfileItem: React.FC<_Props> = ({
  profileAchievements,
  profileLevels,
  permissions,
  profilePicture,
  profileDescription,
  profileDevelopmentStatus,
  name,
  position,
}) => {
  return (
    <>
      <Photo photo={profilePicture ?? getProfilePic(null)} view="public" showHeader={false}>
        <div className="flex flex-col items-center justify-center w-full gap-1">
          <Typography variant="h5">{name ?? ''}</Typography>

          {Boolean(permissions.positions) && <Typography variant="body">{position ?? ''}</Typography>}
        </div>
      </Photo>

      <PublicProfilePermissionedSection
        show={Boolean(permissions.description)}
        name={name}
        title={trans('settings.privacy.profile_description')}
      >
        <Typography>{profileDescription}</Typography>
      </PublicProfilePermissionedSection>

      <PublicProfilePermissionedSection
        name={name}
        show={Boolean(permissions.developmentStatus)}
        title={trans('settings.privacy.development_status')}
      >
        <div className="flex flex-row gap-3 flex-wrap w-full">
          {Object.keys(profileDevelopmentStatus ?? {})
            // sort by progress
            .sort((a, b) => profileDevelopmentStatus?.[b]?.progress - profileDevelopmentStatus?.[a]?.progress)
            .sort((a, b) => {
              // will be sorted in reverse order to this list
              const statusPrio = [SERVICE_STATUS_STARTED, SERVICE_STATUS_FINISHED];

              return (
                statusPrio.indexOf(profileDevelopmentStatus?.[b]?.status) -
                statusPrio.indexOf(profileDevelopmentStatus?.[a]?.status)
              );
            })
            .map(service => {
              if (
                ![SERVICE_STATUS_STARTED, SERVICE_STATUS_FINISHED].includes(profileDevelopmentStatus?.[service].status)
              ) {
                return null;
              }
              return (
                <div className="relative-col-width-3 mb-3 h-full">
                  <ProfileDevelopmentStatusCard
                    key={service}
                    service={service}
                    progress={profileDevelopmentStatus?.[service].progress}
                    status={profileDevelopmentStatus?.[service].status}
                  />
                </div>
              );
            })}
        </div>
      </PublicProfilePermissionedSection>

      <PublicProfilePermissionedSection
        name={name}
        show={Boolean(permissions.levels)}
        title={trans('gamification.levels_achieved')}
      >
        <div className="flex flex-row gap-3 flex-wrap w-full">
          {profileLevels
            ?.sort((a, b) => profileLevels?.start - profileLevels?.start)
            .map(level => {
              return (
                <div className="relative-col-width-3 mb-3 h-full">
                  <ProfileLevelAchievementCard
                    type="level"
                    title={trans(level?.title)}
                    description={trans(level?.description)}
                    image={level?.image}
                  />
                </div>
              );
            })}
        </div>
      </PublicProfilePermissionedSection>
      <PublicProfilePermissionedSection
        name={name}
        show={Boolean(permissions.achievements)}
        title={trans('gamification.achievements_title')}
      >
        <div className="flex flex-row gap-3 flex-wrap w-full">
          {Object.keys(profileAchievements ?? {})
            // sort by progress
            .sort((a, b) => profileAchievements?.[b]?.milestone - profileAchievements?.[a]?.milestone)
            .map(achievementKey => {
              const achievement = profileAchievements?.[achievementKey];
              return (
                <div className="relative-col-width-3 mb-3 h-full">
                  <ProfileLevelAchievementCard
                    type="achievement"
                    title={trans(achievement?.config?.title)}
                    description={trans(achievement?.config?.description)}
                    image={achievement?.config?.image}
                  />
                </div>
              );
            })}
        </div>
      </PublicProfilePermissionedSection>
    </>
  );
};
