// @ts-nocheck
/* eslint-disable */

export const SERVICE_PROFILE_BASE_AND_ANALYSIS = 'profile_base_and_analysis';
export const SERVICE_PROFILE_ANALYSIS = 'profile_analysis';
export const SERVICE_PROFILE_MOTIVE_STRUCTURE = 'motive_structure';
export const SERVICE_PROFILE_POTENTIALS = 'profile_potentials';
export const SERVICE_PROFILE_WAY_OF_WORKING = 'profile_way_of_working';
export const SERVICE_PROFILE_ROLES = 'profile_roles';
export const SERVICE_PROFILE_VALUES = 'profile_values';
//
export const SERVICE_TEAM_REFLECTION = 'team_reflection';
export const SERVICE_TEAM_DEVELOPMENT = 'team_development';
//

export const SERVICE_TEAM_ROLES = 'team_roles';
export const SERVICE_TEAM_VALUES = 'team_values';
export const SERVICE_TEAM_WAY_OF_WORKING = 'team_way_of_working';
export const SERVICE_TEAM_PROFILE_BASE_AND_ANALYSIS = 'team_profile_base_and_analysis';

// New Soft Facts
export const SERVICE_INITIAL_SOFTFACTS_ANALYSIS = 'initial_status_quo';

export const SERVICE_PROFILE_MOTIVATION = 'profile_motivation';
export const SERVICE_PROFILE_COMMUNICATION = 'profile_communication';
export const SERVICE_PROFILE_TRUST = 'profile_trust';
export const SERVICE_PROFILE_RESPONSIBILITY = 'profile_responsibility';
export const SERVICE_PROFILE_PROACTIVITY = 'profile_proactivity';
export const SERVICE_PROFILE_SELF_EFFICACY = 'profile_self_efficacy';
export const SERVICE_PROFILE_RESILIENCE = 'profile_resilience';
export const SERVICE_PROFILE_MINDFULNESS = 'profile_mindfulness';
export const SERVICE_PROFILE_LEARNING = 'profile_learning';
export const SERVICE_PROFILE_EMOTIONAL_INTELLIGENCE = 'profile_emotional_intelligence';

export const SERVICE_PROFILE_SATISFACTION_AND_PERFORMANCE = 'profile_satisfaction_and_performance';

export const THIRD_PERSON_FEEDBACK = 'third_person_feedback';
/**
 * Statuses
 */

export const SERVICE_STATUS_NOT_READY = 'not_ready';
export const SERVICE_STATUS_READY = 'ready';
export const SERVICE_STATUS_STARTED = 'started';
export const SERVICE_STATUS_FINISHED = 'finished';

export const SOFTFACT_SERVICES_NO_SAT_PERF = [
  SERVICE_PROFILE_BASE_AND_ANALYSIS,
  SERVICE_PROFILE_MOTIVE_STRUCTURE,
  SERVICE_PROFILE_WAY_OF_WORKING,
  SERVICE_PROFILE_ROLES,
  SERVICE_PROFILE_VALUES,
  SERVICE_PROFILE_MOTIVATION,
  SERVICE_PROFILE_COMMUNICATION,
  SERVICE_PROFILE_TRUST,
  SERVICE_PROFILE_RESPONSIBILITY,
  SERVICE_PROFILE_PROACTIVITY,
  SERVICE_PROFILE_SELF_EFFICACY,
  SERVICE_PROFILE_RESILIENCE,
  SERVICE_PROFILE_MINDFULNESS,
  SERVICE_PROFILE_LEARNING,
  SERVICE_PROFILE_EMOTIONAL_INTELLIGENCE,
];

export const SOFTFACT_SERVICES = [
  SERVICE_PROFILE_BASE_AND_ANALYSIS,
  SERVICE_PROFILE_MOTIVE_STRUCTURE,
  SERVICE_PROFILE_WAY_OF_WORKING,
  SERVICE_PROFILE_ROLES,
  SERVICE_PROFILE_VALUES,
  SERVICE_PROFILE_MOTIVATION,
  SERVICE_PROFILE_COMMUNICATION,
  SERVICE_PROFILE_TRUST,
  SERVICE_PROFILE_RESPONSIBILITY,
  SERVICE_PROFILE_PROACTIVITY,
  SERVICE_PROFILE_SELF_EFFICACY,
  SERVICE_PROFILE_RESILIENCE,
  SERVICE_PROFILE_MINDFULNESS,
  SERVICE_PROFILE_LEARNING,
  SERVICE_PROFILE_EMOTIONAL_INTELLIGENCE,
  SERVICE_PROFILE_SATISFACTION_AND_PERFORMANCE,
];

export const STRIPPED_SERVICE_NAMES: Record<string, string> = {
  [SERVICE_PROFILE_BASE_AND_ANALYSIS]: 'base_and_analysis',
  [SERVICE_PROFILE_MOTIVE_STRUCTURE]: 'motive_structure',
  [SERVICE_PROFILE_POTENTIALS]: 'potentials',
  [SERVICE_PROFILE_WAY_OF_WORKING]: 'way_of_working',
  [SERVICE_PROFILE_ROLES]: 'roles',
  [SERVICE_PROFILE_VALUES]: 'values',
  [SERVICE_TEAM_REFLECTION]: 'team_reflection',
  [SERVICE_TEAM_DEVELOPMENT]: 'team_development',
  [SERVICE_TEAM_ROLES]: 'team_roles',
  [SERVICE_TEAM_VALUES]: 'team_values',
  [SERVICE_TEAM_WAY_OF_WORKING]: 'team_way_of_working',
  [SERVICE_TEAM_PROFILE_BASE_AND_ANALYSIS]: 'team_profile_base_and_analysis',
  [SERVICE_INITIAL_SOFTFACTS_ANALYSIS]: 'initial_status_quo',
  [SERVICE_PROFILE_MOTIVATION]: 'motivation',
  [SERVICE_PROFILE_COMMUNICATION]: 'communication',
  [SERVICE_PROFILE_TRUST]: 'trust',
  [SERVICE_PROFILE_RESPONSIBILITY]: 'responsibility',
  [SERVICE_PROFILE_PROACTIVITY]: 'proactivity',
  [SERVICE_PROFILE_SELF_EFFICACY]: 'selfefficacy',
  [SERVICE_PROFILE_RESILIENCE]: 'resilience',
  [SERVICE_PROFILE_MINDFULNESS]: 'mindfulness',
  [SERVICE_PROFILE_LEARNING]: 'learning',
  [SERVICE_PROFILE_EMOTIONAL_INTELLIGENCE]: 'emotional_intelligence',
  [SERVICE_PROFILE_SATISFACTION_AND_PERFORMANCE]: 'satisfaction_and_performance',
};
