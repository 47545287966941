// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useState} from 'react';
import {useDidUpdateEffect} from '@modules/Core/hooks/didUpdateEffect';
import {_ProfileAlert} from '@modules/Core/types/profile.model';
import {logger} from '@modules/Core/util/Logger';
import {copyObject} from '@modules/Core/util/util';
import {ProfileAlert} from './ProfileAlert';

interface _Props {
  alerts: _ProfileAlert[];
  onDone?: () => void;
}

export const ProfileAlerts: React.FC<_Props> = ({alerts, onDone = null}) => {
  const [orderedAlerts, setOrderedAlerts] = useState<_ProfileAlert[]>(alerts);
  const [currentAlert, setCurrentAlert] = useState<_ProfileAlert | null>(null);

  useDidUpdateEffect(() => {
    if (!orderedAlerts?.length) {
      setCurrentAlert(null);
      return;
    }

    setCurrentAlert(orderedAlerts[0]);
  }, [orderedAlerts]);
  useEffect(() => {
    const alertsCopy = copyObject(alerts);

    // Order by created_at and then priority
    const ordered = alertsCopy?.sort((a, b) => {
      // Convert 'created_at' to Date objects for comparison
      if (a.priority === b.priority) {
        const dateA = new Date(a.created_at);
        const dateB = new Date(b.created_at);

        // Compare by 'created_at' first
        if (dateA < dateB) return -1;
        if (dateA > dateB) return 1;

        return 0;
      }

      // If 'created_at' is equal, compare by 'priority'
      // Note: Lower numbers indicate higher priority
      return a.priority - b.priority;
    });

    setOrderedAlerts(ordered ?? []);
  }, [alerts]);

  const handleDone = (): void => {
    const nextAlerts = orderedAlerts.slice(1);

    if (!nextAlerts.length) {
      onDone?.();
      setCurrentAlert(null);
      return;
    }

    setCurrentAlert(nextAlerts[0]);
    setOrderedAlerts(nextAlerts);
  };

  if (!currentAlert) {
    return null;
  }

  logger.debug('ProfileAlerts', {
    currentAlert,
    orderedAlerts,
    alerts,
  });

  return <ProfileAlert alert={currentAlert} onDone={handleDone} />;
};
