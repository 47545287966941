// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';

interface _RadioButton {
  label: string | React.ReactNode;
  active: boolean;
  onChange: (active: boolean) => void;
  disabled?: boolean;
  border?: boolean;
  variant?: 'default' | 'with-disc';
}

export const RadioButton: React.FC<_RadioButton> = ({
  label,
  active,
  onChange,
  disabled,
  border = true,
  variant = 'default',
}) => {
  const handleChange = (): void => {
    if (!disabled) onChange(!active);
  };

  return (
    <div
      onClick={handleChange}
      className={addCN(
        'transition flex min-h-6 w-full h-full pl-1 items-center gap-2 border-1 rounded-xs bg-white500 hover:bg-grey100',
        disabled ? '' : 'cursor-pointer',
        active ? 'border-blue700' : 'border-grey300',
        !border && 'border-transparent',
        variant === 'default' ? 'justify-center' : ''
      )}
    >
      {variant === 'with-disc' && (
        <div className="relative pointer-events-none w-3 h-3 p-1 rounded-full border-1 border-brand700 bg-transparent">
          <div
            className={addCN(
              'absolute-50 transition h-[12px] w-[12px] bg-brand700 rounded-full',
              active ? 'opacity-1' : 'opacity-0'
            )}
          />
        </div>
      )}
      <span className="select-none">{label}</span>
    </div>
  );
};
