// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {Dialog} from '@modules/Core/components/base/Dialog';
import {Typography} from '@modules/Core/components/base/Typography';
import {_ProfileAlert} from '@modules/Core/types/profile.model';
import {trans} from '@modules/Translations/util/i18n';

interface _Props {
  alert: _ProfileAlert;
  open: boolean;
  onClose: (confirmed: boolean, navigateTo: string | null) => void;
}

export const ProfileAlertDialog: React.FC<_Props> = ({open, onClose, alert}) => {
  const {metadata} = alert;

  const handleClose = async (confirmed: boolean): Promise<void> => {
    onClose(confirmed, metadata?.navigateTo as string | null);
  };

  return (
    <Dialog
      title={trans(metadata?.title as string | undefined)}
      confirmLabel={trans(metadata?.confirmLabel as string | undefined)}
      open={open}
      onClose={() => {
        void handleClose(false);
      }}
      onConfirm={() => {
        void handleClose(true);
      }}
      titleGap={5}
    >
      <Typography variant="body1" dangerouslySetInnerHTML={{__html: trans(metadata?.content as string | undefined)}} />
    </Dialog>
  );
};
