// @ts-nocheck
/* eslint-disable */

import React, {useRef} from 'react';
import {LottieRef} from 'lottie-react';
import {addCN} from '@/_core/util/cssHelper';
import LottieWrapper from '../../wrappers/LottieWrapper';

//

export interface _LottieAnimation {
  label: string;
  animationData: {
    nm: string;
    ddd: number;
    h: number;
    w: number;
    meta: {
      g: string;
    };
    layers: Layer[];
    v: string;
    fr: number;
    op: number;
    ip: number;
    assets: number[];
    __complete: boolean;
  };
}

interface Layer {
  ty: number;
  nm: string;
  sr?: number;
  st?: number;
  op?: number;
  ip?: number;
  hd?: boolean;
  ddd?: number;
  bm?: number;
  hasMask?: boolean;
  ao?: number;
  ks?: any; // Keyframe Signature
  ef?: any[];
  shapes?: Shape[];
  ind?: number;
  completed?: boolean;
}

interface Shape {
  ty: string;
  bm?: number;
  hd?: boolean;
  mn?: string;
  nm?: string;
  ix?: number;
  cix?: number;
  np?: number;
  it?: ShapeItem[];
  _render?: boolean;
}

interface ShapeItem {
  ty: string;
  bm?: number;
  hd?: boolean;
  mn?: string;
  nm?: string;
  ix?: number;
  d?: number;
  ks?: any; // Could be further detailed based on type
  a?: any; // Anchor Point
  s?: any; // Scale
  sk?: any; // Skew
  p?: any; // Position
  r?: any; // Rotation
  sa?: any; // Skew Axis
  o?: any; // Opacity
  _render?: boolean;
}

interface _FeelingsIcon {
  lottieKey: string;
  label: string;
  active: boolean;
  onClick: () => void;
}

export const FeelingsIcon: React.FC<_FeelingsIcon> = ({lottieKey, label, active, onClick}) => {
  const ref = useRef<LottieRef>();

  return (
    <button
      type="button"
      onMouseEnter={() => ref.current?.play()}
      onMouseLeave={() => ref.current?.stop()}
      onClick={onClick}
      className={addCN(
        'flex h-[128px] p-1 flex-col items-center gap-1 shrink-0 transition w-full',
        'border-[2px] rounded-sm',
        'bg-white500 hover:bg-grey100',
        active ? 'border-brand700' : 'border-grey300'
      )}
    >
      <div className="h-9 w-9">
        <LottieWrapper lottieKey={lottieKey} autoplay={false} lottieRef={ref} />
      </div>
      <span className="">{label}</span>
    </button>
  );
};
