// @ts-nocheck
/* eslint-disable */

import React, {createRef, useEffect, useState} from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {generate} from '../helper';

interface _Input extends React.InputHTMLAttributes<HTMLInputElement> {
  // CANNOT be ref, conflcits with react and is always null
  elRef?: React.RefObject<HTMLInputElement>;
  value?: string | null;
  label?: string;
  disabled?: boolean;
  validationState?: 'danger' | 'warning' | 'success';
  helperText?: string;
  helperTextIcon?: React.ElementType;
  placeholder?: string;
  width?: string;
  icon?: React.ElementType;
  iconElement?: React.ReactNode;
  readOnly?: boolean;
  type?: string;
  iconPosition?: 'left' | 'right';
  autoFocus?: boolean;
  required?: boolean;
  style?: React.CSSProperties;
  dusk?: string;
  onChange?: (value: string, event?: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onPaste?: (event: React.ClipboardEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  hidden?: boolean;
  accept?: string;
  onIconClick?: () => void;
}

export const Input: React.FC<_Input> = ({
  elRef: refFromProps = null,
  autoFocus,
  disabled,
  dusk,
  helperText,
  icon: Icon,
  helperTextIcon: HelperTextIcon,
  iconElement = null,
  iconPosition = 'left',
  label,
  name,
  onBlur,
  onChange,
  onKeyDown,
  onPaste,
  placeholder = '',
  readOnly,
  required,
  style,
  type = 'text',
  validationState,
  value = '',
  width = 'w-full',
  hidden,
  accept,
  onIconClick,
}) => {
  const [id] = useState(generate());
  const ref = refFromProps ?? createRef<HTMLInputElement>();

  useEffect(() => {
    if (autoFocus) {
      ref.current?.focus();
    }
  }, [autoFocus, ref]);

  let borderColor = 'border-grey300';
  let iconColor = 'fill-grey700';
  if (validationState === 'danger') {
    borderColor = 'border-red700';
    iconColor = 'fill-red700';
  }
  if (validationState === 'warning') {
    borderColor = 'border-orange700';
    iconColor = 'fill-orange700';
  }
  if (validationState === 'success') {
    borderColor = 'border-green700';
    iconColor = 'fill-green700';
  }

  if (disabled) {
    borderColor = 'border-grey500';
  }

  const iconsComponent = (
    <>
      {Icon && (
        <div
          onClick={onIconClick}
          className={addCN(
            'absolute user-select-none inset-y-0 flex items-center',
            iconPosition === 'left' ? 'left-[16px] pl-3.5' : 'right-[16px] pr-3.5',
            onIconClick ? 'cursor-pointer' : 'pointer-events-none'
          )}
        >
          <Icon color={disabled ? 'fill-grey500' : undefined} />
        </div>
      )}

      {iconElement ? (
        <div
          onClick={onIconClick}
          className={addCN(
            'absolute user-select-none inset-y-0 flex items-center',
            iconPosition === 'left' ? 'left-[16px] pl-3.5' : 'right-[16px] pr-3.5'
          )}
        >
          {iconElement}
        </div>
      ) : null}
    </>
  );

  return (
    // width must be set on a wrapper div, not on the input itself to work properly with the global grid
    <div className={addCN(width, hidden ? 'hidden' : '')}>
      <div className={addCN('flex flex-col gap-1 w-full')}>
        {label && (
          <label htmlFor={id} className="text-body2-bold user-select-none pointer-events-none">
            {label}
            {required && <span className="required text-body2-bold">*</span>}
          </label>
        )}

        <div className="relative">
          {iconPosition === 'left' ? iconsComponent : null}
          <input
            id={id}
            ref={ref}
            type={type}
            disabled={disabled}
            value={value}
            style={style}
            onChange={e => onChange?.(e.target.value, e)}
            className={addCN(
              'w-full block transition h-6 border-1 rounded-sm text-xs',
              Icon ? 'px-2 py-1 pl-6' : 'px-2 py-1',
              disabled ? 'bg-grey50' : 'bg-white500 hover:bg-grey100 focus:bg-white500',
              disabled ? 'text-typography-disabled' : 'text-typography-paragraph placeholder-typography-caption',
              borderColor,
              'focus:border-blue700 focus:outline-none'
            )}
            placeholder={disabled ? null : placeholder}
            readOnly={readOnly}
            dusk={dusk}
            name={name}
            onBlur={onBlur}
            onKeyDown={onKeyDown}
            onPaste={onPaste}
            required={required}
            hidden={hidden}
            accept={accept}
          />
          {iconPosition === 'right' ? iconsComponent : null}
        </div>
        {helperText ? (
          <div className="inline-flex items-center gap-0">
            {HelperTextIcon && <HelperTextIcon color={iconColor} size="sm" />}
            <span
              className={addCN(
                'text-body2-bold',
                validationState === 'danger' ? '!text-red700' : '',
                validationState === 'warning' ? '!text-orange700' : '',
                validationState === 'success' ? '!text-green700' : ''
              )}
            >
              {helperText}
            </span>
          </div>
        ) : null}
      </div>
    </div>
  );
};
