// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useState} from 'react';
import {CustomizableSectionItem} from '@modules/ContentEngine/components/items/sections/CustomizableSectionItem';
import {Select} from '@modules/Core/components/base/inputs/Select';
import {PlaceholderSection} from '@modules/Core/components/layout/PlaceholderSection';
import {getDesignImage} from '@modules/Core/util/imagesUtil';
import {logger} from '@modules/Core/util/Logger';
import {_InstitutionOverviewTeam} from '@modules/Institution/types/institutionstatistics.model';
import {ProfileExperienceCard} from '@modules/Statistics/components/general/ProfileExperienceCard';
import {OrganisationIndividualPerformanceCard} from '@modules/Statistics/components/institution/OrganisationIndividualPerformanceCard';
import {OrganisationTeamsOverviewCard} from '@modules/Statistics/components/institution/OrganisationTeamsOverviewCard';
import {trans} from '@modules/Translations/util/i18n';

interface _Props {
  teams: Record<string, _InstitutionOverviewTeam>;
  totalExperienceGain: number;
}

const LIMIT = 4;
export const OrganisationTeamsOverviewItem: React.FC<_Props> = ({totalExperienceGain, teams}) => {
  const [chosenTeam, setChosenTeam] = useState<string | null>(null);

  logger.debug('OrganisationTeamsOverviewItem', {teams});
  useEffect(() => {
    if (!chosenTeam) {
      setChosenTeam(Object.keys(teams ?? {})?.[0] ?? null);
    }
  }, [teams]);

  const showPlaceholder = !teams || Object.keys(teams).length === 0;

  return (
    <div className="flex flex-row w-full justify-between gap-3">
      <CustomizableSectionItem
        fullHeight
        width={6}
        title={trans('gamification.institution.teams_overview')}
        titlePrefix={
          !!totalExperienceGain && (
            <ProfileExperienceCard
              titleVariant="h6"
              fullWidth
              label="gamification.institution_experience_short"
              experiencePoints={totalExperienceGain}
            />
          )
        }
      >
        {!showPlaceholder ? (
          <div className="flex flex-col gap-3">
            {Object.entries(teams ?? {})
              .slice(0, LIMIT)
              .map(([key, team]) => {
                return (
                  <div className="w-full">
                    <OrganisationTeamsOverviewCard key={key} team={team} />
                  </div>
                );
              })}
          </div>
        ) : (
          <PlaceholderSection
            fullWidth
            direction="vertical"
            title={trans('institution_gamification.overview_teams_missing.title')}
            content={trans('institution_gamification.overview_teams_missing.description')}
            image={getDesignImage('team_placeholder.png')}
          />
        )}
      </CustomizableSectionItem>

      <CustomizableSectionItem
        className="h-full"
        fullHeight
        width={6}
        title={trans('gamification.institution.teams_performance')}
        titlePrefix={
          <div className="relative-col-width-2">
            {!showPlaceholder && (
              <Select
                placeholder={trans('gamification.institution.select_team_label')}
                options={Object.entries(teams ?? {}).map(([key, team]) => ({
                  label: team.name,
                  value: key,
                }))}
                value={{
                  value: chosenTeam,
                }}
                onChange={value => setChosenTeam(value.value)}
              />
            )}
          </div>
        }
      >
        {!showPlaceholder ? (
          <OrganisationIndividualPerformanceCard team={teams?.[chosenTeam ?? ''] ?? null} />
        ) : (
          <PlaceholderSection
            fullWidth
            direction="vertical"
            title={trans('institution_gamification.overview_individual_team_missing.title')}
            content={trans('institution_gamification.overview_individual_team_missing.description')}
            image={getDesignImage('power.png')}
          />
        )}
      </CustomizableSectionItem>
    </div>
  );
};
