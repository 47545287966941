// @ts-nocheck
/* eslint-disable */

import {logger} from '@modules/Core/util/Logger';
import {performFlowAction} from '@modules/FlowEngine/services/api';
import {_Profile} from '@modules/Profile/types/profile.model';
import {appState} from '@modules/State/util/util';
import {ORG_ROLES, SUPER_ADMIN_ROLE} from '../config/rolesConstants';

export function can(
  name: string,
  additionalParams: Record<string, string | null | undefined> = {},
  withInstitution = true
) {
  // Fetch the current profile from the Redux store
  const currentProfile = appState().profile?.currentProfile;
  const currentInstitution = appState().institution?.currentInstitution;
  const resources = appState().rolesAndPermissions?.resources;
  const resource = resources[name] ?? null;
  logger.debug('[rolestest]Checking permission', {
    name,
    additionalParams,
    currentInstitution,
    currentProfile,
    resource,
    resources,
  });

  if (!currentProfile || !resource) {
    logger.debug('[rolestest]Checking permission failed1', {name, additionalParams});
    return false; // No current profile, access denied
  }

  if (withInstitution && !additionalParams.institutionId) {
    additionalParams.institution_id = currentInstitution?.id;
  }

  // Permission entries is an array for the same permission but different params (team/org)

  for (const permissionEntries of Object.values(currentProfile?.permissions || {})) {
    for (const permission of permissionEntries) {
      const hasSameResourceName = permission.name === resource.name;
      const allowsAllSections = permission.section === null;
      const hasMatchingSection = permission.section === resource.section;
      const allowsAllActions = permission.action === null;
      const hasMatchingAction = permission.action === resource.action;

      if (hasSameResourceName && (allowsAllSections || hasMatchingSection) && (allowsAllActions || hasMatchingAction)) {
        if (paramsMatch(additionalParams, permission.params)) {
          logger.debug('[rolestest]Permission granted', {name, additionalParams});
          return true;
        }
        logger.debug('[rolestest] following check failed: paramsMatch(additionalParams, permission.params)', {
          additionalParams,
          permissionParams: permission.params,
        });
      }
    }
  }

  return false;
}

function paramsMatch(expectedParams, additionalParams) {
  for (const paramName of Object.keys(expectedParams)) {
    const param = expectedParams[paramName];
    const isOptional = param?.optional;

    if (additionalParams[paramName] === undefined && !isOptional) {
      return false;
    }

    if (additionalParams[paramName] !== null && additionalParams[paramName] !== expectedParams[paramName]) {
      return false;
    }
  }
  return true;
}

export function isSuperAdmin() {
  const currentProfile = appState().profile?.currentProfile;
  return (currentProfile?.roles || [])?.some(role => role?.name === SUPER_ADMIN_ROLE);
}

export async function performChangeRoleAction(
  profileIds: string[],
  roleName: string,
  additionalParams: Record<string, string> = {}
): Promise<any> {
  const method = 'role.assign';
  const params = {
    profileIds,
    roleName,
    additionalParams,
  };
  const requestBody = {
    method,
    params,
  };
  return await performFlowAction(requestBody, {
    refetchProfile: true,
    loadingIndicator: true, // TODO @Sherif Really?
  });
}

export function hasRole(profile: _Profile | null, roleName: string, params: Record<string, string> = {}) {
  return (profile?.roles || [])?.some(role => {
    if (role?.name !== roleName) {
      return false;
    }

    for (const key of Object.keys(params)) {
      const expectedValue = params[key];
      const actualValue = role?.pivot?.[key] ?? null;

      if (expectedValue !== actualValue) {
        return false;
      }
    }
    return true;
  });
}

export function isOrgRole(roleName: string): boolean {
  return ORG_ROLES.includes(roleName);
}
