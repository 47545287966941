// @ts-nocheck
/* eslint-disable */

import {useCallback, useEffect, useMemo} from 'react';
// import {addReactEventListener, removeReactEventListener} from '@modules/Core';
import {usePathname, useSearchParams} from 'next/navigation';
import {addReactEventListener, removeReactEventListener} from '@modules/Core/util/eventsUtil';
import {_Location} from '../types/location.model';

/**
 * @description This hook is used to listen to path changes and calls the callback function on location change
 * @param callback
 */
export function useLocationEffect(callback: (location: _Location) => void): any {
  const pathName = usePathname();
  const query = useSearchParams();

  const location = useMemo(
    () => ({
      pathname: pathName,
      search: query.toString(),
    }),
    [pathName, query]
  );

  useEffect(() => {
    if (callback) {
      callback(location);
    }
  }, [location]);

  const reloadLocation = useCallback(() => {
    if (callback) {
      callback(location);
    }
  }, [callback]);

  useEffect(() => {
    addReactEventListener('reloadLocation', reloadLocation);
    return () => {
      removeReactEventListener('reloadLocation', reloadLocation);
    };
  }, [reloadLocation]);

  return location;
}
