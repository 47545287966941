// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table';
import {addCN} from '@/_core/util/cssHelper';
import {_TableColumns, _TableData} from '@modules/Core/components/base/table/Table';
import {Pagination} from './Pagination';

interface _ReactTable {
  data: _TableData;
  columns: _TableColumns;
}

const LIMIT = 50;
export const ReactTable: React.FC<_ReactTable> = ({data, columns}) => {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    manualPagination: data?.length <= LIMIT,
    initialState: {
      pagination: {
        pageSize: LIMIT,
        pageIndex: 0,
      },
    },
    autoResetPageIndex: false,
    // debugTable: true
  });

  // hide the pagination if we have only one page
  // Note: we can't use this logic to hide the pagination on 1 page. Because if we then set it to show all rows at once, the pagination will be hidden and not shown again. The user won't be able to deselect all rows
  // const isOnePager = table?.getPageCount() === 1;

  const showPagination = table?.getPageCount() > 1;

  return (
    <div className="flex flex-col gap-5">
      <div>
        <table cellPadding="0" className="w-full">
          {/* here, we didnt change anything at all. This is the basic example. Stuff like filtering, sorting goes here. Rest -> Table.js */}
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => {
                  const index = header?.index;
                  const currentColumn = columns[index];
                  const width = currentColumn?.width;

                  return (
                    <th className={addCN(typeof width === 'string' ? width : '')} key={header.id}>
                      {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map(row => (
              <tr key={row.id}>
                {row.getVisibleCells().map(cell => (
                  <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {Boolean(showPagination) && <Pagination table={table} />}
    </div>
  );
};
