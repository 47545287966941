// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {ProfileLevelProgressionItem} from '@modules/ContentEngine/components/items/gamification/ProfileLevelProgressionItem';
import {AvatarUser} from '@modules/Core/components/base/avatar/AvatarUser';
import {logger} from '@modules/Core/util/Logger';
import {getProfilePic} from '@modules/Profile/util/profileUtil';
import {OverviewCard} from '@modules/Statistics/components/general/OverviewCard';
import {_TeamOverviewProfile} from '@modules/Team/types/teamstatistics.model';

interface _Props {
  profile?: _TeamOverviewProfile;
  view?: 'card' | 'expanded';
  className?: string;
  supportedMetrics?: string[];
}

export const TeamProfilesOverviewCard: React.FC<_Props> = ({supportedMetrics, className, view = 'card', profile}) => {
  if (!profile) {
    return null;
  }
  logger.debug('[TeamTest]TeamIndividualPerformanceCard', {profile});

  const gamification = profile?.gamification ?? null;
  return (
    <OverviewCard
      supportedMetrics={supportedMetrics ?? []}
      className={className}
      view={view}
      data={{
        activity: profile.activity,
        change: profile.change,
        name: profile.fullName,
        subtitle: profile.position ?? '-',
        metrics: profile.metrics,
      }}
      renderAvatar={() => <AvatarUser image={getProfilePic(profile)} />}
      renderProgressBar={() => <ProfileLevelProgressionItem type="level" minified gamification={gamification} />}
    />
  );
};
