// @ts-nocheck
/* eslint-disable */

// 4 hours for high
// > 2 hours for medium
// < 2 hours for low

const HIGH_THRESHOLD_SECONDS = 60 * 60 * 4;
const MEDIUM_THRESHOLD_SECONDS = 60 * 60 * 2;

export function getActivity(totalSeconds: number, daysCount: number): 'high' | 'medium' | 'low' {
  const weeksCount = daysCount / 7;
  const averagePerWeek = totalSeconds / weeksCount;
  if (averagePerWeek >= HIGH_THRESHOLD_SECONDS) {
    return 'high';
  }
  if (averagePerWeek >= MEDIUM_THRESHOLD_SECONDS) {
    return 'medium';
  }
  return 'low';
}
