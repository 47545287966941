// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {twMerge} from 'tailwind-merge';
import {IconButton} from '@modules/Core/components/base/buttons/IconButton';
import {Divider} from '@modules/Core/components/base/Divider';
import {
  getIconByName,
  IconArrowLeft,
  IconArrowRight,
  IconCancel,
  IconInfo,
} from '@modules/Core/components/base/Icons/Icons';
import {ToolTip} from '@modules/Core/components/base/ToolTip';
import {Typography} from '@modules/Core/components/base/Typography';
import {_ProfilePrompt} from '@modules/Core/types/prompts.model';
import {formatDateTime} from '@modules/Core/util/dates';
import {getImage} from '@modules/Core/util/imagesUtil';
import {logger} from '@modules/Core/util/Logger';
import {useAppNavigate} from '@modules/Navigation/hooks/appNavigate';
import {getServiceIcon} from '@modules/Profile/util/profileUtil';
import {isLangPropKey, trans} from '@modules/Translations/util/i18n';

interface _Props {
  cta: _ProfilePrompt;
  hasNext?: boolean;
  hasPrevious?: boolean;
  onNextClick?: () => void;
  onPreviousClick?: () => void;
  onSeen?: () => Promise<void>;
  isDev?: boolean;
  id?: string;
}

export const CTACard: React.FC<_Props> = ({
  id,
  isDev,
  cta,
  hasNext,
  hasPrevious,
  onNextClick,
  onPreviousClick,
  onSeen,
}) => {
  const {navigate} = useAppNavigate();

  logger.debug('CTACard', {cta, hasNext, hasPrevious, onNextClick, onPreviousClick});
  if (!cta) {
    return null;
  }

  const onClick = async () => {
    // await onSeen?.();
    navigate(cta.link);
  };

  return (
    <div
      id={id}
      className={twMerge(
        'flex flex-col gap-1 w-full',
        'rounded-sm border-1 border-grey300',
        'py-1 px-2',
        isDev ? 'bg-typography-danger' : 'bg-white500'
      )}
    >
      {isDev && (
        <>
          <Typography variant="body2" bold>
            Hint: This is visible ONLY in dev mode
          </Typography>
          <div className="w-full flex flex-row justify-center items-center">
            <ToolTip txt={`Visible from : ${formatDateTime(new Date(cta.valid_from))}`}>
              <IconInfo />
            </ToolTip>
          </div>
          <Divider light />
        </>
      )}
      <HeaderComponent
        onSeen={onSeen}
        cta={cta}
        hasNext={hasNext}
        hasPrevious={hasPrevious}
        onNextClick={onNextClick}
        onPreviousClick={onPreviousClick}
      />

      <BodyComponent
        onClick={onClick}
        title={cta.title}
        description={cta.description}
        descriptionParams={cta.data?.descriptionParams ?? {}}
      />
    </div>
  );
};

const HeaderComponent: React.FC<{
  onSeen: () => void;
  cta: _ProfilePrompt;
  hasNext?: boolean;
  hasPrevious?: boolean;
  onNextClick?: () => void;
  onPreviousClick?: () => void;
}> = ({onSeen, cta, hasNext, hasPrevious, onNextClick, onPreviousClick}) => {
  let icon;
  if (cta.icon_type === 'image') {
    icon = (
      <div className="h-3 w-3">
        <img src={getImage(cta.icon, 'png')} />
      </div>
    );
  } else if (cta.icon_type === 'service-icon') {
    const IconComponent = getServiceIcon(cta.icon);

    if (IconComponent) {
      icon = <IconComponent />;
    }
  } else {
    const IconComponent = getIconByName(cta.icon);

    if (IconComponent) {
      icon = <IconComponent />;
    }
  }

  return (
    <div className={twMerge('flex flex-row w-full items-center', icon ? 'justify-between' : 'justify-end')}>
      {icon && icon}
      <NavigationComponent
        hasNext={hasNext}
        hasPrevious={hasPrevious}
        onNextClick={onNextClick}
        onPreviousClick={onPreviousClick}
        onSeen={onSeen}
      />
    </div>
  );
};

const NavigationComponent: React.FC<{
  hasNext?: boolean;
  hasPrevious?: boolean;
  onNextClick?: () => void;
  onPreviousClick?: () => void;
  onSeen: () => void;
}> = ({hasNext, hasPrevious, onNextClick, onPreviousClick, onSeen}) => {
  const isStandalone = !hasNext && !hasPrevious;

  return (
    <div className={twMerge('flex flex-row justify-end items-center gap-1')}>
      {!isStandalone && <IconButton onClick={onPreviousClick} icon={IconArrowLeft} disabled={!hasPrevious} />}
      {!isStandalone && <IconButton onClick={onNextClick} icon={IconArrowRight} disabled={!hasNext} />}
      <IconButton onClick={onSeen} icon={IconCancel} />
    </div>
  );
};

const BodyComponent: React.FC<{
  onClick: () => void;
  title: string;
  description: string;
  descriptionParams?: Record<string, string>;
}> = ({title, description, descriptionParams, onClick}) => {
  // For each key/value pair, call isLangPropKey, if yes, trans, if no, return it
  Object.entries(descriptionParams ?? {}).forEach(([key, value]) => {
    if (isLangPropKey(value)) {
      descriptionParams[key] = trans(value);
    }
  });
  return (
    <div onClick={onClick} className={twMerge('flex flex-col gap-0 w-full', 'cursor-pointer')}>
      <Typography variant="body" bold>
        {trans(title)}
      </Typography>

      <Typography variant="body2">{trans(description, descriptionParams)}</Typography>
    </div>
  );
};
