// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {twMerge} from 'tailwind-merge';
import {Dialog} from '@modules/Core/components/base/Dialog';
import {logger} from '@modules/Core/util/Logger';
import {GlobalTutorialCard} from '@modules/Tutorials/components/GlobalTutorialCard';
import {TutorialCard} from '@modules/Tutorials/components/TutorialCard';
import {useTutorialFlow} from '@modules/Tutorials/hooks/prepareTutorial';
import {_Tutorial} from '@modules/Tutorials/types/tutorial.model';

export interface _TutorialFlowContainerProps {
  tutorial?: _Tutorial | null;
  onDone?: () => void;
  withScroll?: boolean;
  withTimeout?: boolean;
  open?: boolean;
}

export const TutorialFlowContainer: React.FC<_TutorialFlowContainerProps> = ({
  open,
  withTimeout = false,
  withScroll,
  onDone,
  tutorial,
}) => {
  const {currentStep, totalSteps, finished, onFinish, type} = useTutorialFlow(tutorial, onDone, withTimeout);
  logger.debug('TutorialFlowContainer', {type, currentStep, totalSteps, finished, tutorial, open});

  if (!currentStep || !open) {
    return null;
  }

  if (finished) {
    return null;
  }

  if (type === 'dialog') {
    return (
      <Dialog showCloseButton open onClose={onFinish}>
        <div className={twMerge('w-full', 'h-full', 'flex', 'items-center', 'flex', 'justify-center')}>
          <TutorialCard simpleView data={currentStep} onClose={onFinish} />
        </div>
      </Dialog>
    );
  }
  return (
    <GlobalTutorialCard
      withScroll={withScroll}
      onClose={onFinish}
      elementId={currentStep.elementId}
      data={currentStep}
    />
  );
};
