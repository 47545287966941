// @ts-nocheck
/* eslint-disable */

import {AxiosResponse} from 'axios';
import {httpClient} from '@modules/Core/services/HttpClient';
import {reloadProfileStateWithPromise} from '@modules/Core/util/eventsUtil';
import {logger} from '@modules/Core/util/Logger';
import {_FlowActionResult} from '@modules/FlowEngine/types/flow-action.model';
import {FLOW_ACTION, FLOW_ACTION_UNAUTHORIZED, FLOWS_LOAD, FLOWS_LOAD_UNAUTHORIZED} from './paths';

// Do not use this for now, might be implemented for batch actions in the future
// async function performFlowActions(body: any, loadRequest = true): Promise<any> {
//   if (loadRequest) {
//     dispatchReactEvent('navigate.start', body);
//   }
//   const result = await httpClient.post(FLOW_ACTIONS, body, {headers: {'X-Show-Loader': loadRequest}});
//   if (loadRequest) {
//     reloadProfileState();
//   }
//   return result;
// }

async function performFlowAction(
  body: any,
  {
    refetchProfile = false,
    loadingIndicator = false,
    unauthorized = false,
  }: {refetchProfile?: boolean; loadingIndicator?: boolean; unauthorized?: boolean} = {}
): Promise<AxiosResponse<_FlowActionResult>> {
  if (loadingIndicator) {
    // dispatchReactEvent('navigate.start', body);
  }
  const result = await httpClient.post(
    `${unauthorized ? FLOW_ACTION_UNAUTHORIZED : FLOW_ACTION}?method=${body.method}`,
    body,

    loadingIndicator ? {headers: {'X-Show-Loader': 'true'}} : {}
  );
  if (refetchProfile && !unauthorized) {
    logger.debug('[performFlowAction] refetchProfile', body.method);
    await reloadProfileStateWithPromise();
  }
  return result;
}

let flowsCache: any = null;

async function loadFlows(authorized: boolean): Promise<any> {
  if (!flowsCache) {
    flowsCache = await httpClient.get(authorized ? FLOWS_LOAD : FLOWS_LOAD_UNAUTHORIZED);
  }
  return flowsCache;
}

export {performFlowAction, loadFlows};
