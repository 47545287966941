// @ts-nocheck
/* eslint-disable */

import {useEffect, useState} from 'react';
import _ from 'lodash';
import {reloadContentEngine} from '@modules/Core/util/eventsUtil';
import {setNotificationsRead} from '@modules/Notifications/services/notificationApi';
import {useSelectState} from '@modules/State/hooks/selectState';
import {_Notification} from '../util/notification.model';

export function useNotifications(): {
  notifications: _Notification[];
  onSetNotificationRead: (id?: string) => Promise<void>;
  unreadNotifications: _Notification[];
  toShowNotifications: _Notification[];
} {
  const notifications = useSelectState(state => state.notifications.currentNotifications) as _Notification[];

  const [unreadNotifications, setUnreadNotifications] = useState<_Notification[]>([]);

  const toShowNotifications = useToShowNotifications(notifications, unreadNotifications);

  useEffect(() => {
    if (!notifications?.length) {
      if (unreadNotifications?.length) {
        setUnreadNotifications([]);
      }
      return;
    }
    const newUnreadNotifications = (notifications || []).filter(notification => !notification.read_at);

    if (!_.isEqual(unreadNotifications, newUnreadNotifications)) {
      setUnreadNotifications((notifications || []).filter(notification => !notification.read_at));
    }
  }, [notifications]);

  async function onSetNotificationRead(id?: string): Promise<void> {
    const ids = id ? [id] : (notifications || []).map(notification => notification.id);

    try {
      await setNotificationsRead(ids);

      reloadContentEngine();
    } catch (e) {
      //
    }
  }

  return {
    notifications,
    onSetNotificationRead,
    unreadNotifications,
    toShowNotifications,
  };
}

function useToShowNotifications(notifications: _Notification[], unreadNotifications: _Notification[]): _Notification[] {
  const [toShowNotifications, setToShowNotifications] = useState<_Notification[]>([]);

  useEffect(() => {
    if (!notifications?.length && !unreadNotifications?.length) {
      if (toShowNotifications?.length) {
        setToShowNotifications([]);
      }
      return;
    }
    // If all read, return only first 7
    if (unreadNotifications?.length === 0) {
      // Return first 7 notifications
      setToShowNotifications((notifications || []).slice(0, 7));
      return;
    }

    // Get index of last unread notification
    const lastUnreadIndex = notifications.findIndex(
      notification => notification.id === unreadNotifications[unreadNotifications.length - 1].id
    );

    if (lastUnreadIndex < 7) {
      // Return first 7 notifications
      const newToShowNotifications = (notifications || []).slice(0, 7);

      if (!_.isEqual(toShowNotifications, newToShowNotifications)) {
        setToShowNotifications(newToShowNotifications);
      }
      return;
    }

    const newToShowNotifications = (notifications || []).slice(0, lastUnreadIndex + 1);

    if (!_.isEqual(toShowNotifications, newToShowNotifications)) {
      setToShowNotifications(newToShowNotifications);
    }
  }, [notifications, unreadNotifications]);

  return toShowNotifications;
}
