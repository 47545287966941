// @ts-nocheck
/* eslint-disable */

import {useEffect, useLayoutEffect, useRef, useState} from 'react';
import {logger} from '@modules/Core/util/Logger';
import {_Tutorial, _TutorialStep, _TutorialType} from '@modules/Tutorials/types/tutorial.model';
import {setTutorialDoneAction} from '@modules/Tutorials/util/tutorialActionsUtil';
import {prepareTutorialStep} from '@modules/Tutorials/util/tutorialStepUtil';

export const useTutorialFlow = (
  tutorial?: _Tutorial | null,
  onDone?: () => void,
  withTimeout = false
): {
  currentStep: _TutorialStep | null;
  totalSteps: number;
  onNext: () => void;
  onPrevious: () => void;
  onFinish: () => void;
  finished: boolean;
  type?: 'global' | 'dialog';
} => {
  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);
  const [steps, setSteps] = useState<_TutorialStep[] | null>(null);
  const [finished, setFinished] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState<_TutorialStep | null>(null);
  const lastLoadedTutorialName = useRef<string | null>(null);
  // Prepares actions for current step (like previous, next etc...).
  useEffect(() => {
    if (!steps?.[currentStepIndex]) {
      setCurrentStep(null);
      return;
    }

    const htmlEelement = document.getElementById(steps[currentStepIndex].elementId);
    logger.debug('Element Position:', htmlEelement?.getBoundingClientRect());
    setCurrentStep(
      prepareTutorialStep(steps[currentStepIndex], currentStepIndex, steps.length, onNext, onPrevious, onFinish)
    );
  }, [steps, currentStepIndex]);

  // Extracts steps
  useLayoutEffect(() => {
    setTimeout(
      () => {
        const filteredSteps = Object.values(tutorial.steps).filter(step => document.getElementById(step.elementId));
        logger.debug('TutorialFlowContainer prepareTutorial before', {
          filteredSteps,
          tutorial,
          currentStep,
          steps,
          currentStepIndex,
          finished,
          lastLoadedTutorialName: lastLoadedTutorialName.current,
        });

        if (tutorial && tutorial.name !== lastLoadedTutorialName.current && filteredSteps.length) {
          logger.debug('TutorialFlowContainer prepareTutorial in', {
            filteredSteps,
            tutorial,
            currentStep,
            steps,
            currentStepIndex,
            finished,
            lastLoadedTutorialName: lastLoadedTutorialName.current,
          });

          setSteps(filteredSteps);
          setCurrentStepIndex(0);
          setFinished(false);
          lastLoadedTutorialName.current = tutorial.name;
        }
      },
      withTimeout ? 2500 : 0
    );
  }, [tutorial]);

  const onNext = () => {
    logger.debug('TutorialFlowContainer: onNext', [currentStepIndex, steps?.length]);
    if (steps && currentStepIndex < steps.length - 1) {
      setCurrentStepIndex(currentStepIndex + 1);
    }
  };

  const onPrevious = () => {
    if (steps && currentStepIndex > 0) {
      setCurrentStepIndex(currentStepIndex - 1);
    }
  };

  const onFinish = (): void => {
    // We don't block if request fails
    if (tutorial?.name) {
      void setTutorialDoneAction(tutorial?.name);
      onDone?.();
    }
    setFinished(true);
  };

  logger.debug('TutorialFlowContainer prepareTutorial', {tutorial, currentStep, steps, currentStepIndex, finished});

  return {
    currentStep,
    totalSteps: steps ? steps.length : 0,
    onNext,
    onPrevious,
    onFinish,
    finished,
    type: tutorial?.type ?? ('global' as _TutorialType),
  };
};
