// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useRef} from 'react';
import {Chart, registerables} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {useUiStore} from '@modules/Core/util/logic/zustand';
import {trans} from '@modules/Translations/util/i18n';

Chart.register(ChartDataLabels);
Chart.register(...registerables);

interface _Props {
  width: number;
  height: number;
  data: number[];
}

export const ProfileMotivesChart: React.FC<_Props> = ({width, height, data}) => {
  const chartContainer = useRef<HTMLCanvasElement>(null);
  const chartInstance = useRef<Chart | null>(null);
  const scaleFactor = useUiStore(state => state.scaleFactor);

  useEffect(() => {
    const updateOffset = (chart: Chart): void => {
      const radius = height / 2;
      chart.options.plugins.datalabels.offset = radius - 40;
      chart.update();
    };

    const initChart = (ctx, params): void => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
      chartInstance.current = new Chart(ctx, params);
    };

    const ctx = chartContainer.current?.getContext('2d');
    if (ctx) {
      const data2 = {
        labels: [
          trans('motive_structure.career.title'),
          trans('motive_structure.development.title'),
          trans('motive_structure.selfrealization.title'),
          trans('motive_structure.selfoptimization.title'),
          trans('motive_structure.fun.title'),
          trans('motive_structure.power.title'),
          trans('motive_structure.achievement.title'),
          trans('motive_structure.independance.title'),
          trans('motive_structure.fairness.title'),
          trans('motive_structure.idealism.title'),
          trans('motive_structure.responsibility.title'),
          trans('motive_structure.goalinternalization.title'),
          trans('motive_structure.status.title'),
          trans('motive_structure.security.title'),
          trans('motive_structure.belonging.title'),
          trans('motive_structure.acceptance.title'),
        ],
        datasets: [
          {
            data,
            backgroundColor: [
              '#F3C677', // 'rgba(243, 198, 119, 1)',
              '#F3C677', // 'rgba(243, 198, 119, 1)',
              '#F3C677', // 'rgba(243, 198, 119, 1)',
              '#F3C677', // 'rgba(243, 198, 119, 1)',
              '#F89377', // 'rgba(248, 147, 119, 1)',
              '#F89377', // 'rgba(248, 147, 119, 1)',
              '#F89377', // 'rgba(248, 147, 119, 1)',
              '#F89377', // 'rgba(248, 147, 119, 1)',
              '#7B1E7A', // 'rgba(123, 30, 122, 1)',
              '#7B1E7A', // 'rgba(123, 30, 122, 1)',
              '#7B1E7A', // 'rgba(123, 30, 122, 1)',
              '#7B1E7A', // 'rgba(123, 30, 122, 1)',
              '#A8DADC', // 'rgba(168, 218, 220, 1)',
              '#A8DADC', // 'rgba(168, 218, 220, 1)',
              '#A8DADC', // 'rgba(168, 218, 220, 1)',
              '#A8DADC', // 'rgba(168, 218, 220, 1)',
            ],
          },
        ],
      };

      let updateFinished = false;

      const mmUpdatePlugin = {
        id: 'mmUpdate',
        afterDraw: chart => {
          if (!updateFinished) {
            updateFinished = true;
            updateOffset(chart);
          }
        },
      };

      initChart(ctx, {
        type: 'polarArea',
        data: data2,
        plugins: [ChartDataLabels, mmUpdatePlugin],
        options: {
          animation: false,
          responsive: false,
          maintainAspectRatio: false,
          plugins: {
            mmUpdate: {},
            legend: {
              position: 'right',
              display: false,
              onClick: e => e.stopPropagation(),
            },
            tooltip: {
              callbacks: {
                label: context => {
                  let result = `${context.label}: ${context.formattedValue}%`;
                  if (context.datasetIndex === 1) {
                    result += ' Candidates Analysis';
                  }
                  return result;
                },
              },
            },
            datalabels: {
              font: {
                family: 'var(--font-karla), sans-serif',
              },
              color: 'black',
              formatter: (value, context) => {
                if (context.datasetIndex === 1) {
                  return '';
                }
                return `${context.chart.legend.legendItems[context.dataIndex].text} ${context.dataset.data[context.dataIndex]}%`;
              },
              anchor: 'start',
              align: 'end',
              offset: 0,
            },
          },
          title: {
            display: false,
          },
          layout: {
            padding: {
              top: 50,
              bottom: 50,
              left: 100,
              right: 100,
            },
          },
          scales: {
            r: {
              grid: {
                color: ['gray'],
              },
              max: 100,
              min: 0,
              scaleLabel: {
                display: true,
              },
              ticks: {
                stepSize: 10,
              },
              angleLines: {
                display: true,
                color: ['gray'],
              },
              pointLabels: {
                display: false,
                font: {
                  size: 15,
                },
              },
            },
          },
        },
      });
    }
  }, [data]);

  return (
    <div className={`flex w-[${width}px h-[${height}px] justify-center`}>
      <canvas ref={chartContainer} width={width} height={height} />
    </div>
  );
};
