// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {IconEmail} from '@modules/Core/components/base/Icons/Icons';
import {Input} from '@modules/Core/components/base/inputs/Input';
import {trans} from '@modules/Translations/util/i18n';

interface _LoginProps {
  email: string | null;
  setEmail: (email: string | null) => void;
  disabled?: boolean;
}

export const LoginEmailInput: React.FC<_LoginProps> = ({email, setEmail, disabled = false}) => {
  function handleEmailChange(email: string) {
    setEmail(email);
  }

  return (
    <Input
      type="email"
      readOnly={disabled}
      value={email}
      onChange={handleEmailChange}
      icon={IconEmail}
      label={trans('login.email_label')}
      placeholder={trans('invite.email_placeholder')}
      required
    />
  );
};
