// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {addCN} from '@/_core/util/cssHelper';
import {AvatarUser} from '@modules/Core/components/base/avatar/AvatarUser';
import {IconButton} from '@modules/Core/components/base/buttons/IconButton';
import {_Dialog, Dialog} from '@modules/Core/components/base/Dialog';
import {IconInfo} from '@modules/Core/components/base/Icons/Icons';
import {CheckBox} from '@modules/Core/components/base/inputs/CheckBox';
import {_ActionsTableHeaderProps} from '@modules/Core/components/base/table/actions/ActionsTableHeader';
import {ActionsTable} from '@modules/Core/components/base/table/ActionsTable';
import {Typography} from '@modules/Core/components/base/Typography';
import {Section} from '@modules/Core/components/layout/Section';
import {rolesLanguageDict} from '@modules/Core/config/rolesConstants';
import {SOFTFACT_SERVICES_NO_SAT_PERF} from '@modules/Core/config/services';
import {useDialog} from '@modules/Core/hooks/ui/dialog';
import {
  INSTITUTION_ADMIN_ROLE,
  INSTITUTION_MEMBER_ROLE,
  INSTITUTION_OWNER_ROLE,
  TEAM_ADMIN_ROLE,
  TEAM_MEMBER_ROLE,
  TEAM_OWNER_ROLE,
} from '@modules/Core/types/rolesAndPermissions.model';
import {formatDate} from '@modules/Core/util/dates';
import {logger} from '@modules/Core/util/Logger';
import {isOrgRole} from '@modules/Core/util/rolesAndPermissionsUtil';
import {PROFILE_SERVICES_TITLES} from '@modules/Profile/config/profileServicesLangProps';
import {_Profile} from '@modules/Profile/types/profile.model';
import {getProfilePic} from '@modules/Profile/util/profileUtil';
import {trans} from '@modules/Translations/util/i18n';
import {_TableColumns, _TableDataItem} from '../../base/table/Table';

const statusLangPropertyMap: Record<string, string> = {
  not_upgraded: 'service.status.not_upgraded',
  not_started: 'service.status.open',
  in_progress: 'service.status.in_progress',
  done: 'service.status.done',
};

const membersDescriptionsLangPropertyMap: Record<string, string[]> = {
  organizationAdmin: [
    'roles.roles_explanation.organizationAdmin.description.0',
    'roles.roles_explanation.organizationAdmin.description.1',
    'roles.roles_explanation.organizationAdmin.description.2',
  ],
  organizationOwner: [
    'roles.roles_explanation.organizationOwner.description.0',
    'roles.roles_explanation.organizationOwner.description.1',
    'roles.roles_explanation.organizationOwner.description.2',
    'roles.roles_explanation.organizationOwner.description.3',
  ],
  organizationMember: [
    'roles.roles_explanation.organizationMember.description.0',
    'roles.roles_explanation.organizationMember.description.1',
    'roles.roles_explanation.organizationMember.description.2',
  ],
  teamMember: [
    'roles.roles_explanation.teamMember.description.0',
    'roles.roles_explanation.teamMember.description.1',
    'roles.roles_explanation.teamMember.description.2',
    'roles.roles_explanation.teamMember.description.3',
  ],
  teamOwner: [
    'roles.roles_explanation.teamOwner.description.0',
    'roles.roles_explanation.teamOwner.description.1',
    'roles.roles_explanation.teamOwner.description.2',
    'roles.roles_explanation.teamOwner.description.3',
    'roles.roles_explanation.teamOwner.description.4',
  ],
  teamAdmin: [
    'roles.roles_explanation.teamAdmin.description.0',
    'roles.roles_explanation.teamAdmin.description.1',
    'roles.roles_explanation.teamAdmin.description.2',
    'roles.roles_explanation.teamAdmin.description.3',
  ],
};

const rolesOrder = [
  INSTITUTION_OWNER_ROLE,
  INSTITUTION_ADMIN_ROLE,
  TEAM_OWNER_ROLE,
  TEAM_ADMIN_ROLE,
  INSTITUTION_MEMBER_ROLE,
  TEAM_MEMBER_ROLE,
];

interface _MembersRolesMgmtProps {
  profiles: Record<string, _Profile>;
  canSelectProfile?: (profile: _Profile) => boolean;
  onProfileSelected?: (profileId: string) => void;

  teamId?: string;
  institutionId?: string;

  showDialog?: boolean;
  setShowDialog?: (show: boolean) => void;

  dialogConfig?: _Dialog;
  setDialogConfig?: (dialogConfig: _Dialog) => void;
  allowSelection?: boolean;

  headerProps: _ActionsTableHeaderProps;
  extraColumns?: Record<number, _TableColumns>;
  renderExtraColumns?: (profile: _Profile) => _TableDataItem;
}

export const MembersRolesMgmt: React.FC<_MembersRolesMgmtProps> = ({
  profiles,
  teamId,
  institutionId,
  showDialog,
  setShowDialog,
  dialogConfig,
  setDialogConfig,
  allowSelection = true,
  headerProps,
  onProfileSelected,
  extraColumns,
  renderExtraColumns,
}) => {
  logger.debug('MembersRolesMgmt', {dialogConfig, extraColumns, renderExtraColumns, profiles});
  return (
    <>
      <DialogComponent show={showDialog} setShow={setShowDialog} dialogConfig={dialogConfig} />
      <TableComponent
        headerProps={headerProps}
        allowSelection={allowSelection}
        profiles={profiles}
        teamId={teamId}
        institutionId={institutionId}
        onProfileSelected={onProfileSelected}
        setDialogConfig={setDialogConfig}
        setShowDialog={setShowDialog}
        extraColumns={extraColumns}
        renderExtraColumns={renderExtraColumns}
      />
    </>
  );
};

interface _DialogProps {
  show: boolean;
  setShow: (show: boolean) => void;
  dialogConfig: _Dialog;
}

const DialogComponent: React.FC<_DialogProps> = ({show, setShow, dialogConfig}) => (
  <Dialog
    open={show}
    onClose={() => {
      setShow(false);
    }}
    title={dialogConfig?.title}
    onConfirm={dialogConfig?.onConfirm}
    cancelLabel={dialogConfig?.cancelLabel}
    confirmLabel={dialogConfig?.confirmLabel}
    showCloseButton
  >
    {dialogConfig?.children}
  </Dialog>
);

interface _TableComponentProps {
  allowSelection: boolean;
  profiles: Record<string, _Profile>;
  teamId: string;
  institutionId: string;
  headerProps: _ActionsTableHeaderProps;
  onProfileSelected: (profileId: string) => void;
  setDialogConfig?: (dialogConfig: _Dialog) => void;
  setShowDialog: (show: boolean) => void;
  // Index = column index, value = column
  extraColumns?: Record<number, _TableColumns>;
  renderExtraColumns?: (profile: _Profile) => _TableDataItem;
}

const TableComponent: React.FC<_TableComponentProps> = ({
  allowSelection,
  profiles,
  teamId,
  institutionId,
  headerProps,
  onProfileSelected,
  setDialogConfig,
  setShowDialog,
  extraColumns,
  renderExtraColumns,
}) => {
  const dialog = useDialog();
  const columns: _TableColumns = [
    {label: trans('base.name'), key: 'name', width: 'w-[30%]'},
    {
      label: (
        <div className="flex flex-row gap-0 items-center">
          {trans('base.roles')}
          <IconButton icon={IconInfo} onClick={showRolesPopup} />
        </div>
      ),
      key: 'roles',
      width: 'w-[15%]',
    },
    {label: trans('base.soft_facts_menu_title'), key: 'soft_facts', width: 'w-[15%]'},
    // Add other headers based on your configuration
    {label: trans('base.last_login'), key: 'last_login', width: 'w-[15%]'},
  ];

  function showRolesPopup() {
    dialog.show({
      title: trans('roles.roles_explanation.title'),
      children: (
        <div>
          {(institutionId
            ? ['organizationOwner', 'organizationAdmin', 'organizationMember']
            : ['teamOwner', 'teamAdmin', 'teamMember']
          ).map(roleKey => (
            <div key={roleKey}>
              <b>{trans(`roles.roles_explanation.${roleKey}.role`)}</b>
              <ul>
                {membersDescriptionsLangPropertyMap[roleKey].map((description, index) => (
                  <li>{trans(description)}</li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      ),
      hideButtons: true,
      showCloseButton: true,
      onClose: () => {
        setShowDialog(false);
      },
    });
  }

  function prepareSoftfactsDialog(profile: _Profile) {
    setShowDialog(true); // Open the dialog

    logger.debug('prepareSoftfactsDialog', {profile});
    // TODO @Sherif Please check: I reverted this. You have deleted the `content` content (but this is required for the dialog I guess)
    const content = (
      <div className="flex flex-col gap-3">
        {SOFTFACT_SERVICES_NO_SAT_PERF.map(key => (
          <div className="flex flex-row justify-start w-full gap-2">
            <Typography>{trans(PROFILE_SERVICES_TITLES[key])}:</Typography>
            <Typography
              className={addCN(
                profile?.progress?.[key] === 'done' && 'text-green700',
                profile?.progress?.[key] === 'in_progress' && 'text-orange700',
                profile?.progress?.[key] === 'not_started' && 'text-grey500'
              )}
              // sx={{
              //   color:
              //     profile?.progress?.[key] === 'done'
              //       ? 'accent.green'
              //       : profile?.progress?.[key] === 'in_progress'
              //         ? 'accent.orange'
              //         : 'grey.600',
              // }}
            >
              {trans(statusLangPropertyMap[profile?.progress?.[key]])}
            </Typography>
          </div>
        ))}
      </div>
    );

    // Set up dialog options, but don't show it yet
    setDialogConfig?.({
      title: trans('profile_result.member_soft_facts', {
        member: profile?.fullName,
      }),
      children: content,
      showCloseButton: true,
      onClose: () => {
        setShowDialog(false);
      },
      size: 'sm',
    });
  }

  const renderRow = (profile: _Profile): _TableDataItem => {
    const total = SOFTFACT_SERVICES_NO_SAT_PERF.length;

    const done = SOFTFACT_SERVICES_NO_SAT_PERF.filter(status => profile?.progress?.[status] !== 'not_started')?.length;

    const result = {
      name: (
        <div className="flex flex-row items-center gap-2">
          {allowSelection && (
            <CheckBox
              onChange={() => onProfileSelected(profile.id)}
              active={headerProps.selectedRowsIds?.includes(profile.id)}
            />
          )}
          <AvatarUser image={getProfilePic(profile)} label={profile.fullName} email={profile.email} />
        </div>
      ),
      roles: (
        <div className="flex flex-col justify-center items-start w-full">
          {(profile?.roles || [])
            .filter(role => {
              // If teamId is provided, show either Org roles + non-team roles
              // or team roles for the given teamId
              if (teamId) {
                return role?.pivot?.team_id === teamId;
              }

              if (institutionId) {
                return !isOrgRole(role?.name) || role?.pivot?.institution_id === institutionId;
              }
              return true;
            })
            // remove duplicates
            .sort((a, b) => {
              const sortingArray = [
                INSTITUTION_OWNER_ROLE,
                INSTITUTION_ADMIN_ROLE,
                INSTITUTION_MEMBER_ROLE,
                TEAM_OWNER_ROLE,
                TEAM_ADMIN_ROLE,
                TEAM_MEMBER_ROLE,
              ];

              return sortingArray.indexOf(a.name) > sortingArray.indexOf(b.name) ? 1 : -1;
            })
            .filter((v, i, a) => a.findIndex(t => t.name === v.name) === i)
            .map(role => role?.name && trans(rolesLanguageDict[role?.name]))
            .map((translatedRole, index) => translatedRole && <div key={translatedRole}>{translatedRole}</div>)}
        </div>
      ),
      soft_facts: (
        <div className="cursor-pointer" onClick={() => prepareSoftfactsDialog(profile)}>
          <Typography>{`${done} / ${total}`}</Typography>
        </div>
      ), // Example placeholder
      last_login: <Typography>{profile.lastActivity ? formatDate(new Date(profile.lastActivity)) : '-'}</Typography>,
      // Implement additional columns based on profile and config
    };

    if (renderExtraColumns) {
      return {...result, ...renderExtraColumns(profile)};
    }

    return result;
  };

  const sortedProfiles = Object.values(profiles ?? {}).sort((a, b) => {
    // sort by roles
    const rolesA = a.roles.map(role => role.name);
    const rolesB = b.roles.map(role => role.name);

    const roleIndexA = rolesOrder.findIndex(role => rolesA.includes(role));
    const roleIndexB = rolesOrder.findIndex(role => rolesB.includes(role));

    // If same roles, compare created_at
    return roleIndexA - roleIndexB || new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
  });

  const allColumns = columns;
  // add extra columns by index
  if (extraColumns) {
    Object.keys(extraColumns).forEach(index => {
      allColumns.splice(Number(index), 0, extraColumns[index]);
    });
  }
  return (
    <Section>
      <ActionsTable
        rows={sortedProfiles}
        columns={allColumns}
        renderRow={renderRow}
        showTopBorder
        filterOptions={{
          enabled: true,
          searchAttributes: ['fullName', 'email'],
        }}
        headerProps={headerProps}
      />
    </Section>
  );
};
