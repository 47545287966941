// @ts-nocheck
/* eslint-disable */

import {_FeatureFlags} from '@modules/FeatureFlags/types/featureFlags.model';
import {httpClient} from '@modules/Core/services/HttpClient';
import {FEATURE_FLAGS} from '../paths';

let featureFlagsCache: _FeatureFlags | null = null;

async function loadFeatureFlags(loadingIndicator = false): Promise<_FeatureFlags> {
  if (!featureFlagsCache) {
    featureFlagsCache = await httpClient.get(
      FEATURE_FLAGS,
      loadingIndicator ? {headers: {'X-Show-Loader': 'true'}} : {}
    );
  }
  return featureFlagsCache;
}

export {loadFeatureFlags};
