// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useState} from 'react';
import {GraphItem} from '@modules/ContentEngine/components/items/GraphItem';
import {_ProfileTrackingMetricValueEntry} from '@modules/ContentEngine/types/metrics.model';
import {getStatisticImage} from '@modules/Core/util/imagesUtil';
import {logger} from '@modules/Core/util/Logger';
import {_GraphData} from '@modules/Profile/components/services/result/common/ProfileResultGraph';
import {EXPERIENCE_GAIN_METRIC} from '@modules/Statistics/config/constants';
import {TeamProfilesOverviewCard} from '@modules/Team/components/dashboard/cards/TeamProfilesOverviewCard';
import {statisticsColors, statisticTitleLangProps} from '@modules/Team/config/statisticsConstants';
import {_TeamOverviewProfile} from '@modules/Team/types/teamstatistics.model';
import {trans} from '@modules/Translations/util/i18n';

interface _Props {
  profile?: _TeamOverviewProfile;
}

export const supportedMetricsOrdered = ['softfactsCompleted', 'exercisesCompleted', 'experienceGain'];
export const TeamIndividualPerformanceCard: React.FC<_Props> = ({profile}) => {
  const graphType = 'Donut';
  const [graphData, setGraphData] = useState<_GraphData[]>([]);
  const [experienceGainedMetric, setExperienceGainedMetric] = useState<_ProfileTrackingMetricValueEntry | null>(null);

  useEffect(() => {
    setExperienceGainedMetric(profile?.metrics?.[EXPERIENCE_GAIN_METRIC] ?? null);
  }, [profile]);
  useEffect(() => {
    setGraphData(
      // Object.entries(profile?.metrics ?? {})
      //   ?.filter(([metricName]) => supportedMetricsOrdered.includes(metricName))
      //   ?.sort(([metricNameA], [metricNameB]) => supportedMetricsOrdered.indexOf(metricNameA) - supportedMetricsOrdered.indexOf(metricNameB))
      //
      supportedMetricsOrdered.map(supportedMetricName => {
        const metricName = supportedMetricName;
        const metric = profile?.metrics[metricName] ?? {};
        logger.debug('[TeamTest]TeamIndividualPerformanceCard', {metricName, metric});
        return {
          category: trans(statisticTitleLangProps[metricName]),
          value: metric?.sum ?? 0,
        };
      }) ?? []
    );
  }, [profile]);
  if (!profile) {
    return null;
  }

  logger.debug('[TeamTest]TeamIndividualPerformanceCard', {profile, graphData, experienceGainedMetric});
  return (
    <div className="flex flex-row gap-3 items-center w-full h-full">
      <TeamProfilesOverviewCard
        supportedMetrics={supportedMetricsOrdered}
        className="relative-col-width-3"
        profile={profile}
        view="expanded"
      />
      <div className="flex flex-row items-center justify-center relative-col-width-4 relative-col-height-4">
        <GraphItem
          type={graphType}
          chartOptions={{
            colors: statisticsColors,
            text: experienceGainedMetric?.sum ?? '0',
            image: getStatisticImage('experiencelogo_2x'),
          }}
          data={graphData}
          withWrapper={false}
        />
      </div>
    </div>
  );
};
