// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {Card} from '@modules/Core/components/base/cards/Card';
import {Indicator} from '@modules/Core/components/base/Indicator';
import {ProgressBar} from '@modules/Core/components/base/ProgressBar';
import {Typography} from '@modules/Core/components/base/Typography';
import {SERVICE_STATUS_FINISHED, SERVICE_STATUS_STARTED} from '@modules/Core/config/services';
import {PROFILE_SERVICES_TITLES} from '@modules/Profile/config/profileServicesLangProps';
import {_ServiceStatusStatus} from '@modules/Profile/types/modules.model';
import {getServiceIcon} from '@modules/Profile/util/profileUtil';
import {trans} from '@modules/Translations/util/i18n';

interface _Props {
  status: _ServiceStatusStatus;
  progress: number;
  service: string;
}

export const ProfileDevelopmentStatusCard: React.FC<_Props> = ({service, status, progress}) => {
  if (![SERVICE_STATUS_STARTED, SERVICE_STATUS_FINISHED].includes(status)) {
    return null;
  }
  const Icon = getServiceIcon(service);
  const indicator =
    status === SERVICE_STATUS_STARTED ? 'warning' : status === SERVICE_STATUS_FINISHED ? 'success' : null;
  const statusText =
    status === SERVICE_STATUS_STARTED
      ? trans('base.pending')
      : status === SERVICE_STATUS_FINISHED
        ? trans('base.done')
        : null;
  return (
    <Card>
      <div className="flex flex-row items-center gap-0">
        <Indicator color={indicator} />
        <Typography>{statusText}</Typography>
      </div>
      <div className="flex flex-row items-center gap-0">
        {Icon && <Icon />}
        <Typography>{trans(PROFILE_SERVICES_TITLES[service])}</Typography>
      </div>
      <ProgressBar color="success" size="xs" value={progress} />
    </Card>
  );
};
