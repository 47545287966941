// @ts-nocheck
/* eslint-disable */

import {FLOWS} from '@modules/FlowEngine/config/flowNames';
import {fireDialogFlow} from '@modules/FlowEngine/util/dialogFlowsUtil';
import {trans} from '@modules/Translations/util/i18n';

export function newTeamFlow(onlyInvite: boolean = false): void {
  fireDialogFlow(
    FLOWS.NewTeam,
    null,
    {
      title: trans('institution.create_organisation'),
      hideButtons: true,
      showCloseButton: true,
    },
    {
      onlyInvite,
      excludeIntro: true,
      ignoreRedirect: true,
      excludeTitles: true,
    }
  );
}

export function inviteToTeamFlow(teamId?: string | null): void {
  fireDialogFlow(
    FLOWS.InviteTeam,
    null,
    {
      title: trans('institution.add_institution_member'),
      hideButtons: true,
      showCloseButton: true,
      excludeTitles: true,
    },
    {
      teamId,
    }
  );
}
