// @ts-nocheck
/* eslint-disable */

import React, {createContext, ReactNode, useContext, useEffect, useState} from 'react';
import {usePathname, useRouter, useSearchParams} from 'next/navigation';
import {_AlertSeverity} from '@modules/Core/components/base/Alert';
import redirects from '@modules/Core/config/redirects.mjs';
import useSnackbar from '@modules/Core/hooks/ui/snackbar';
import {dispatchReactEvent, reloadContentEngine, reloadProfileState} from '@modules/Core/util/eventsUtil';
import {logger} from '@modules/Core/util/Logger';
import {LOCATION_CHANGED_APP_EVENT} from '@modules/Navigation/hooks/navigationInit';

// Define the interface for the context value
interface AppNavigateContextProps {
  navigateWithAlert: (
    path: string,
    alertMessage?: string,
    alertSeverity?: _AlertSeverity,
    reloadProfile?: boolean,
    withLoader?: boolean
  ) => void;
  navigate: (path: string, param?: any, target?: string | null) => void;
  currentUrl?: string;
}

// Create the context with an undefined default value
const AppNavigateContext = createContext<AppNavigateContextProps | undefined>(undefined);

// Provider component that initializes the hook and provides its value
export const AppNavigateProvider: React.FC<{children: ReactNode}> = ({children}) => {
  const router = useRouter();
  const snackbar = useSnackbar();
  const pathname = usePathname();
  const searchParams = useSearchParams();

  const [navigateDone, setNavigateDone] = useState<boolean>(false);
  const [navigateTimeout, setNavigateTimeout] = useState<boolean>(false);
  const [currentUrl, setCurrentUrl] = useState<string>('');

  useEffect(() => {
    logger.debug('[useAppNavigate] Navigation done', `${pathname}${searchParams.toString()}`);
    setNavigateDone(true);
    setNavigateTimeout(false);
    setCurrentUrl(createUrl(pathname, searchParams));
  }, [pathname, searchParams]);

  useEffect(() => {
    setNavigateTimeout(false);
    if (navigateTimeout && !navigateDone) {
      logger.debug('[useAppNavigate] Navigation timeout', `${pathname}${searchParams.toString()}`);
      dispatchReactEvent('navigate.done', {url: `${pathname}${searchParams.toString()}`});
    }
  }, [navigateTimeout, navigateDone, pathname, searchParams]);

  const createUrl = (path: string, params: URLSearchParams | null): string => {
    const queryString = params?.toString();
    return `${path}${queryString ? `?${queryString}` : ''}`;
  };

  const isSameUrl = (fromUrl: string, toUrl: string): boolean => {
    logger.debug('[useAppNavigate] Checking if', fromUrl, 'is the same as', toUrl);
    if (fromUrl === toUrl) {
      return true;
    }
    const redirectMatches = redirects.filter(
      (redirect: any) => toUrl === redirect.source && fromUrl === redirect.destination
    );
    return redirectMatches.length > 0;
  };

  const navigateWithLoader = (
    path: string,
    params: URLSearchParams | null,
    reloadProfile: boolean = true,
    withLoader: boolean = true
  ): void => {
    const fromUrl = createUrl(pathname, searchParams);
    const toUrl = createUrl(path, params);
    logger.debug('[navigateWithLoader] Navigating to', toUrl, 'from', fromUrl, ' with loader', withLoader);
    if (isSameUrl(fromUrl, toUrl)) {
      logger.debug('[navigateWithLoader] Same URL, reloading content engine');
      // Fire rerouting event to same route, for tracking purposes
      dispatchReactEvent(LOCATION_CHANGED_APP_EVENT);
      reloadContentEngine();
    } else {
      if (withLoader) {
        dispatchReactEvent('navigate.start', {url: toUrl});
        setNavigateDone(false);
        setNavigateTimeout(false);
      }

      router.push(toUrl);
      if (withLoader) {
        setTimeout(() => setNavigateTimeout(true), 5000);
      }
    }
    if (reloadProfile) {
      reloadProfileState();
    }
  };

  const navigateWithAlert = (
    path: string,
    alertMessage?: string,
    alertSeverity?: _AlertSeverity,
    reloadProfile: boolean = true,
    withLoader: boolean = true
  ): void => {
    logger.info(
      '[AppNavigate] Navigating to ',
      path,
      ' with alert ',
      alertMessage,
      alertSeverity,
      ' and reload ',
      reloadProfile,
      ' with loader ',
      withLoader
    );
    if (alertMessage) {
      snackbar.show(alertMessage, alertSeverity);
    }
    // Use router.push for navigation
    navigateWithLoader(path, null, reloadProfile, withLoader);
  };

  const navigate = (path: string, param: Record<string, any>, target?: string): void => {
    logger.info('[AppNavigate] Navigating to ', path, ' with param ', param);
    // Next.js does not directly support passing state via navigate,
    // You would need to serialize your state to query parameters or manage it externally.
    if (target === '_blank') {
      window.open(path, target);
      return;
    }
    navigateWithLoader(path, new URLSearchParams(param));
  };

  return (
    <AppNavigateContext.Provider value={{navigateWithAlert, navigate, currentUrl}}>
      {children}
    </AppNavigateContext.Provider>
  );
};

// Hook to use the navigation context
export const useAppNavigate = (): AppNavigateContextProps => {
  const context = useContext(AppNavigateContext);
  if (!context) {
    throw new Error('useAppNavigate must be used within an AppNavigateProvider');
  }
  return context;
};
