// @ts-nocheck
/* eslint-disable */

import * as am5 from '@amcharts/amcharts5';
import * as am5radar from '@amcharts/amcharts5/radar';
import * as am5xy from '@amcharts/amcharts5/xy';
import {trans} from '@modules/Translations/util/i18n';

const fontFamily = 'softfact, sans-serif';

const colorSets = [
  ['#8ACED0', '#F0B856', '#132E3E'],
  ['#97DDDF', '#F4C674', '#1E3B4B'],
  ['#B6E0E2', '#F5D08E', '#2B5C7B'],
  ['#C5E7E8', '#F8DFB4', '#457DA0'],
];

// combines: https://www.amcharts.com/demos/multi-part-gauge/
// https://www.amcharts.com/demos/radar-with-date-axis/
export const initSolidGaugeMulti = (root, chartOptions, data) => {
  // Set up number formatter - removes decimals 6.50 -> 7
  root.numberFormatter.set('numberFormat', '#.');

  // adjust the size according to the number of soft facts
  let size = 65;
  if (data?.[0]?.length === 1) size = 75;
  if (data?.[0]?.length === 2) size = 65;
  if (data?.[0]?.length > 2) size = 55;

  const chart = root.container.children.push(
    am5radar.RadarChart.new(root, {
      panX: false,
      panY: false,
      wheelX: 'none', // 'panX', // also prevents amCharts from taking mouse wheel events
      wheelY: 'none', // 'zoomX',
      zoomX: 'none',
      zoomY: 'none',
      innerRadius: am5.percent(size),
    })
  );

  chart.get('colors').set('step', 2);

  const createRenderer = (start, end) => {
    const yRenderer = am5radar.AxisRendererRadial.new(root, {minGridDistance: 0});

    const yAxis = chart.yAxes.push(
      am5xy.CategoryAxis.new(root, {
        maxDeviation: 0,
        categoryField: 'category',
        renderer: yRenderer,
      })
    );

    const xRenderer = am5radar.AxisRendererCircular.new(root, {startAngle: start, endAngle: end});
    const xAxis = chart.xAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: xRenderer,
        min: 0,
        max: 100,
        strictMinMax: true,
        numberFormat: "#'%'",
      })
    );

    return {
      xAxis,
      xRenderer,
      yAxis,
      yRenderer,
    };
  };

  // Add cursor
  // https://www.amcharts.com/docs/v5/charts/radar-chart/#Cursor
  const cursor = chart.set('cursor', am5radar.RadarCursor.new(root, {behavior: 'zoomX'}));
  cursor.lineY.set('visible', false); // hide the horizontal line on hover
  cursor.lineX.set('visible', false); // hide the vertical line on hover

  const createGreyBar = (subChartData, start, end) => {
    const {xAxis, xRenderer, yAxis, yRenderer} = createRenderer(start, end);

    yRenderer.grid.template.set('visible', false); // removes grid lines
    // we cant remove the grid lines for x, so we make them transparent
    xRenderer.grid.template.set('stroke', 'transparent'); // removes grid lines

    xRenderer.labels.template.set('visible', false); // removes label

    yRenderer.labels.template.setAll({
      centerX: am5.p100, // moves the categories to the left
      fontWeight: 'bold',
      fontSize: '0.625rem',
      fontFamily,
    });

    // Create series
    for (let j = 0; j < subChartData.length; j += 1) {
      // grey bar indicating 100%
      const greyBar = chart.series.push(
        am5radar.RadarColumnSeries.new(root, {
          xAxis,
          yAxis,
          clustered: false,
          valueXField: 'value',
          categoryYField: 'category',
          fill: '#DFE5ED',
        })
      );

      greyBar.columns.template.setAll({
        width: am5.p100,
        fillOpacity: 0.08,
        strokeOpacity: 0,
        cornerRadius: 20,
      });

      greyBar.data.setAll(subChartData.map(d => ({...d, value: 100}))); // sets all values to 100 for each category
    }

    yAxis.data.setAll(subChartData.map(d => ({category: d.category}))); // adds the categories to the y axis

    xAxis.data.setAll(subChartData);
  };

  const createSubChart = (subChartData, start, end, gridLines, i, colors, totalRounds) => {
    // Create axes and their renderers
    // https://www.amcharts.com/docs/v5/charts/radar-chart/#Adding_axes
    const {xAxis, xRenderer, yAxis, yRenderer} = createRenderer(start, end);

    xRenderer.labels.template.set('visible', false); // remove x label
    if (totalRounds > 1) {
      const roundLabelDict =
        i === 0 ? trans('profile_exercises.initial_round') : trans('profile_exercises.round', {roundIndex: i});
      const customLabel = xAxis.makeDataItem({});
      customLabel.set('value', 50);
      xAxis.createAxisRange(customLabel);
      // since all x labels are removed, we need to activate this manually
      customLabel.get('label').setAll({
        text: roundLabelDict,
        forceHidden: false,
        visible: true,
        fontFamily,
        fontSize: '0.875rem',
      });
    }

    // adds a line at specific points, e.g. [0, 80]
    if (gridLines.length > 0) {
      gridLines.forEach(gridLine => {
        // here we can add individual grid lines
        const rangeDataItem = xAxis.makeDataItem({value: gridLine});
        xAxis.createAxisRange(rangeDataItem);
        rangeDataItem.get('grid').set('stroke', '#ff0000');
      });
    }

    yRenderer.grid.template.set('visible', false); // removes grid lines
    // we cant remove the grid lines, so we make them transparent
    xRenderer.grid.template.set('stroke', 'transparent'); // removes grid lines

    // add the label only once
    yRenderer.labels.template.set('visible', false); // removes label

    // Create series
    // https://www.amcharts.com/docs/v5/charts/radar-chart/#Adding_series
    for (let j = 0; j < subChartData.length; j += 1) {
      // data bar (with color)
      const series = chart.series.push(
        am5radar.RadarColumnSeries.new(root, {
          clustered: false,
          name: 'Series',
          xAxis,
          yAxis,
          categoryYField: 'category',
          valueXField: 'value',
        })
      );
      series.columns.template.setAll({
        width: am5.p100,
        strokeOpacity: 0,
        tooltipText: '{category}: {valueX}%',
        cornerRadius: 20,
        templateField: 'columnSettings',
      });

      // apply custom styles to each bar
      series.columns.template.adapters.add('fill', (fill, target) => am5.color(colors[series.columns.indexOf(target)]));

      series.data.setAll(subChartData);
      // series.appear(2000, 100);
      series.data.setAll(subChartData);
    }

    yAxis.data.setAll(subChartData.map(d => ({category: d.category}))); // adds the categories to the y axis

    xAxis.data.setAll(subChartData);
  };

  const area = 270; // makes the circle go only until 75% of the circle
  const start = -90;
  const chartSize = area / data.length;
  const gap = 1; // gap between the sub charts
  createGreyBar(data[0], start, area - 90);

  // we create a sub chart for each data set. Divide the circle into equal parts
  data.forEach((subChartData, i) => {
    let currentStart = start + i * chartSize;
    if (i > 0) currentStart += gap; // adds a small gap between the sub charts

    let currentEnd = currentStart + chartSize;
    if (i > 0 && i !== data.length - 1) currentEnd -= gap; // adds a small gap between the sub charts

    // this logic can add custom grid lines. But it is deactivated currently
    // const isFirst = i === 0;
    // const isLast = i === data.length - 1;
    const gridLines = [];
    // if (isFirst) gridLines.push(0);
    // if (!isLast) gridLines.push(100);

    // we need to reverse the color sets, because the first should be the darkest
    const currentColorSet = colorSets[data.length - 1 - i];

    createSubChart(subChartData, currentStart, currentEnd, gridLines, i, currentColorSet, data.length);
  });

  // chart.appear(2000, 100); // Animate chart and series in
};
