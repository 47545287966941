// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {usePathname} from 'next/navigation';
import {flowRoute} from '@/_core/util/util';
import {Dialog} from '@modules/Core/components/base/Dialog';
import {Typography} from '@modules/Core/components/base/Typography';
import {PROFILE_SERVICES_TITLES} from '@modules/Profile/config/profileServicesLangProps';
import {downloadCertificate} from '@modules/Profile/util/exercisesUtil';
import {getSurveyFlow} from '@modules/Profile/util/profileUtil';
import {trans} from '@modules/Translations/util/i18n';

const trophiesMap: Record<number | 'final', any> = {
  1: {lottieKey: 'reward_0', height: '486px', width: '324px'},
  2: {lottieKey: 'reward_1', height: '421px', width: '281px'},
  3: {lottieKey: 'reward_2', height: '562px', width: '500px'},
  final: {lottieKey: 'exercise_completed_medal', height: '333px', width: '241px'},
};

interface _ExerciseRoundFinishedDialogProps {
  open: boolean;
  onClose: (confirmed: boolean, navigateTo?: string) => void;
  service: string;
  round?: number | null | 'final';
}

const ExerciseRoundFinishedDialog: React.FC<_ExerciseRoundFinishedDialogProps> = ({
  open,
  onClose,
  service,
  round = null,
}) => {
  const location = usePathname();

  const handleDownload = async (): Promise<void> => {
    if (round === null || round === 'final') {
      return;
    }
    await downloadCertificate(service, round);
    onClose?.(false);
  };

  const goToResults = (): void => {
    const flowPath = flowRoute(getSurveyFlow(service)?.name);
    // check if the location is the same as the flow route

    if (location !== flowPath && flowPath) {
      onClose?.(true, flowPath);
    } else {
      onClose?.(false);
    }
  };

  const successTrophy = round !== null ? trophiesMap[round] : null;

  return (
    <Dialog
      open={open}
      onClose={() => onClose(false)}
      showCloseButton
      confirmLabel={
        round !== 'final' ? trans('profile_exercises.download_certificate') : trans('profile_exercises.go_to_results')
      }
      onConfirm={round !== 'final' ? handleDownload : goToResults}
      lottieData={successTrophy}
      title={
        round !== 'final'
          ? trans('profile_exercises.congratulations')
          : trans('profile_exercises.congratulations_final')
      }
      titleVariant="h4"
      justifyContent="center"
      justifyButtons="center"
      justifyTitle="center"
    >
      <Typography
        className="text-center"
        variant="body1"
        dangerouslySetInnerHTML={{
          __html:
            round !== 'final'
              ? trans('profile_exercises.congratulations_message', {
                  round,
                })
              : trans('profile_exercises.congratulations_final_message', {
                  service: trans(PROFILE_SERVICES_TITLES[service]),
                }),
        }}
      />
    </Dialog>
  );
};

export default ExerciseRoundFinishedDialog;
