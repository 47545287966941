// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useState} from 'react';
import {useDidUpdateEffect} from '@modules/Core/hooks/didUpdateEffect';
import {copyObject} from '@modules/Core/util/util';
import {TutorialFlowContainer} from '@modules/Tutorials/components/TutorialFlowContainer';
import {_Tutorial} from '@modules/Tutorials/types/tutorial.model';

interface _Props {
  tutorials: Record<string, _Tutorial>;
  onDone?: () => void;
}

export const ProfileTutorials: React.FC<_Props> = ({tutorials, onDone = null}) => {
  const [orderedTutorials, setOrderedTutorials] = useState<_Tutorial[]>(tutorials);
  const [currentTutorial, setCurrentTutorial] = useState<_Tutorial | null>(null);

  useDidUpdateEffect(() => {
    if (!orderedTutorials?.length) {
      setCurrentTutorial(null);
      return;
    }

    setCurrentTutorial(orderedTutorials[0]);
  }, [orderedTutorials]);
  useEffect(() => {
    setOrderedTutorials(copyObject(Object.values(tutorials ?? {}))?.filter(tutorial => !tutorial.completed) ?? []);
  }, [tutorials]);

  const handleDone = (): void => {
    const nextTutorial = orderedTutorials.slice(1);

    if (!nextTutorial.length) {
      onDone?.();
      setCurrentTutorial(null);
      return;
    }

    setCurrentTutorial(nextTutorial[0]);
    setOrderedTutorials(nextTutorial);
  };

  if (!currentTutorial) {
    return null;
  }

  return <TutorialFlowContainer onDone={handleDone} tutorial={currentTutorial} withTimeout />;
};
