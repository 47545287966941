// @ts-nocheck
/* eslint-disable */

import {httpClientUnauthenticated} from '@modules/Core/services/HttpClient';
import {REGISTER, RESEND_TOKEN, RESET_PASSWORD, SEND_RESET_PASSWORD_LINK, VERIFY_TOKEN} from '../paths';

async function register(
  email: string,
  password: string,
  firstName: string,
  lastName: string,
  tosPrivacy: boolean,
  consent: boolean,
  newsletter: boolean,
  registerOptions: any,
  institutionName: string,
  promotionCode: string
): Promise<any> {
  return await httpClientUnauthenticated.post(REGISTER, {
    email,
    password,
    consent: `${consent}`,
    first_name: firstName,
    last_name: lastName,
    tos_privacy: `${tosPrivacy}`,
    newsletter,
    options: registerOptions,
    institution_name: institutionName,
    promotion_code: promotionCode,
    initial_referrer: sessionStorage.initialReferrer,
  });
}

async function verifyToken(email: string, activationToken: string): Promise<any> {
  return await httpClientUnauthenticated.post(VERIFY_TOKEN, {
    email,
    activation_token: activationToken,
  });
}

async function resendToken(email: string): Promise<any> {
  return await httpClientUnauthenticated.post(RESEND_TOKEN, {
    email,
  });
}

async function resetPasswordLink(email: string): Promise<any> {
  return await httpClientUnauthenticated.post(SEND_RESET_PASSWORD_LINK, {
    email,
  });
}

async function resetPassword(email: string, password: string, token: string): Promise<any> {
  return await httpClientUnauthenticated.post(RESET_PASSWORD, {
    email,
    password,
    token,
  });
}

export {register, verifyToken, resendToken, resetPasswordLink, resetPassword};
