// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useState} from 'react';
import {CustomizableSectionItem} from '@modules/ContentEngine/components/items/sections/CustomizableSectionItem';
import {_ProfileTrackingMetricValueEntry} from '@modules/ContentEngine/types/metrics.model';
import {Select} from '@modules/Core/components/base/inputs/Select';
import {PlaceholderSection} from '@modules/Core/components/layout/PlaceholderSection';
import {getDesignImage} from '@modules/Core/util/imagesUtil';
import {logger} from '@modules/Core/util/Logger';
import {ProfileExperienceCard} from '@modules/Statistics/components/general/ProfileExperienceCard';
import {TeamIndividualPerformanceCard} from '@modules/Team/components/dashboard/cards/TeamIndividualPerformanceCard';
import {TeamProfilesOverviewCard} from '@modules/Team/components/dashboard/cards/TeamProfilesOverviewCard';
import {_TeamOverviewProfile} from '@modules/Team/types/teamstatistics.model';
import {trans} from '@modules/Translations/util/i18n';

interface _Props {
  profiles: Record<string, _TeamOverviewProfile>;
  experienceMetric: _ProfileTrackingMetricValueEntry;
}

const LIMIT = 6;
export const TeamProfilesOverviewItem: React.FC<_Props> = ({experienceMetric, profiles}) => {
  const [chosenProfile, setChosenProfile] = useState<string | null>(null);

  useEffect(() => {
    if (!chosenProfile) {
      setChosenProfile(Object.keys(profiles ?? {})?.[0] ?? null);
    }
  }, [profiles]);

  const showPlaceholder = !profiles || Object.keys(profiles).length === 0;
  logger.debug('[TeamProfilesOverviewItem]TeamProfilesOverviewItem', {profiles, chosenProfile});
  return (
    <div className="flex flex-row w-full justify-between gap-3">
      <CustomizableSectionItem
        fullHeight
        width={6}
        title={trans('gamification.team.profiles_overview')}
        titlePrefix={
          !!experienceMetric && (
            <ProfileExperienceCard
              titleVariant="h6"
              fullWidth
              label="gamification.team_experience_short"
              experiencePoints={experienceMetric.sum}
            />
          )
        }
      >
        {!showPlaceholder ? (
          <div className="flex flex-row gap-3 flex-wrap justify-between gap-y-3">
            {Object.entries(profiles ?? {})
              .slice(0, LIMIT)
              .map(([key, profile]) => {
                return (
                  <div className="relative-col-width-2-75">
                    <TeamProfilesOverviewCard key={key} profile={profile} />
                  </div>
                );
              })}
          </div>
        ) : (
          <PlaceholderSection
            fullWidth
            direction="vertical"
            title={trans('team_gamification.overview_profiles_missing.title')}
            content={trans('team_gamification.overview_profiles_missing.description')}
            image={getDesignImage('team_placeholder.png')}
          />
        )}
      </CustomizableSectionItem>

      <CustomizableSectionItem
        className="h-full"
        fullHeight
        width={6}
        title={trans('gamification.team.individual_performance')}
        titlePrefix={
          !showPlaceholder && (
            <div className="relative-col-width-2">
              <Select
                placeholder={trans('team_dashboard.select_profile_label')}
                options={Object.entries(profiles ?? {}).map(([key, profile]) => ({
                  label: profile.fullName,
                  value: key,
                }))}
                value={{
                  value: chosenProfile,
                }}
                onChange={value => setChosenProfile(value.value)}
              />
            </div>
          )
        }
      >
        {!showPlaceholder ? (
          <TeamIndividualPerformanceCard profile={profiles?.[chosenProfile ?? ''] ?? null} />
        ) : (
          <PlaceholderSection
            fullWidth
            direction="vertical"
            title={trans('team_gamification.overview_individual_profile_missing.title')}
            content={trans('team_gamification.overview_individual_profile_missing.description')}
            image={getDesignImage('power.png')}
          />
        )}
      </CustomizableSectionItem>
    </div>
  );
};
