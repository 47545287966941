// @ts-nocheck
/* eslint-disable */

import {logger} from '@modules/Core/util/Logger';
import {performFlowAction} from '@modules/FlowEngine/services/api';
import {getAllJournals} from '@modules/Profile/services/journalsApi';
import {_JournalFilterParams} from '@modules/Profile/types/journal.model';
import {_Profile} from '@modules/Profile/types/profile.model';
import {currentProfile} from '@modules/Profile/util/profileUtil';

export const setProfileAlertDoneAction = async (alertId: string): Promise<any> => {
  const method = 'profile.alert.done'; // Backend method name
  const params = {
    alertId,
  };

  try {
    // Call the performFlowAction function with the method and parameters
    const response = await performFlowAction({
      method,
      params,
    });

    // Extract the response data
    const responseData = response?.data;

    // Check if the response indicates a successful operation
    if (responseData?.status === 'success' && responseData?.data) {
      return responseData.data;
    }

    // Log an error if the operation was not successful
    logger.error('Start Service Next Round Action failed:', responseData?.error);
    return null;
  } catch (e) {
    // Log any errors encountered during the operation
    logger.error('Start Service Next Round Action encountered an error:', e);
    return null;
  }
};

export async function addNewJournal(
  categoryId: string,
  teamId: string,
  journal: string,
  metadata: Record<string, any>,
  group: string
): Promise<boolean> {
  if (!categoryId) {
    return false;
  }

  const method = 'journal.add';
  const params = {
    categoryId,
    teamId,
    journal,
    metadata,
    group,
  };
  try {
    const response = await performFlowAction(
      {
        method,
        params,
      },
      {refetchProfile: true}
    );

    return response?.data?.status === 'success';
  } catch (e) {
    logger.error(e);
    return false;
  }
}

export async function deleteJournal(journalId: string): Promise<boolean> {
  if (!journalId) {
    return false;
  }

  const method = 'journal.delete';
  const params = {
    journalId,
  };
  try {
    const response = await performFlowAction(
      {
        method,
        params,
      },
      {refetchProfile: true}
    );

    return response?.data?.status === 'success';
  } catch (e) {
    logger.error(e);
    return false;
  }
}

export async function updateJournal(journalId: string, journal: string): Promise<boolean> {
  if (!journalId) {
    return false;
  }

  const method = 'journal.update';
  const params = {
    journalId,
    journal,
  };
  try {
    const response = await performFlowAction(
      {
        method,
        params,
      },
      {refetchProfile: true}
    );

    return response?.data?.status === 'success';
  } catch (e) {
    logger.error(e);
    return false;
  }
}

export function getServiceCategory(service: string): string | null {
  if (!service) {
    return null;
  }
  return `soft_facts.${service}`;
}

export async function getJournalsList(filterParams: _JournalFilterParams): Promise<any> {
  try {
    const response = await getAllJournals(filterParams);
    return response?.data?.journals;
  } catch (err) {
    logger.error(err);
    return [];
  }
}

export function hasJournals(profile: _Profile | null): boolean {
  profile = profile ?? currentProfile();
  return !!profile?.hasJournals;
}
