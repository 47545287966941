// @ts-nocheck
/* eslint-disable */

import {FC, useEffect, useMemo, useRef} from 'react';
import {ContentEngineContainer} from '@modules/ContentEngine/components/base/ContentEngineContainer';
import {Dialog} from '@modules/Core/components/base/Dialog';
import {addReactEventListener, ReactEventActionClicked, removeReactEventListener} from '@modules/Core/util/eventsUtil';

interface _Props {
  latestReleaseKey: string | null;
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const LatestSoftwareUpdate: FC<_Props> = ({open, setOpen, latestReleaseKey}) => {
  const lastLoadedKey = useRef<string | null>(null);
  const content = useMemo(() => {
    if (!latestReleaseKey || (lastLoadedKey.current && lastLoadedKey.current === latestReleaseKey)) {
      return null;
    }

    lastLoadedKey.current = latestReleaseKey;
    return (
      <ContentEngineContainer
        type={`${latestReleaseKey}`}
        params={{
          softwareUpdateId: 'latest',
        }}
        withLoadingIndicator
      />
    );
  }, [latestReleaseKey]);

  useEffect(() => {
    addReactEventListener(ReactEventActionClicked, onReactEventActionClicked);

    return () => {
      removeReactEventListener(ReactEventActionClicked, onReactEventActionClicked);
    };
  }, []);

  const onReactEventActionClicked = (event: CustomEvent): void => {
    if (event?.detail?.type === 'software_update_confirm') {
      setOpen(false);
    }
  };
  return (
    <Dialog open={open} onClose={() => setOpen(false)} showCloseButton>
      {content}
    </Dialog>
  );
};
