// @ts-nocheck
/* eslint-disable */

import {FC, useCallback, useEffect, useLayoutEffect, useRef, useState} from 'react';
import {logger} from '@modules/Core/util/Logger';
import {useUiStore} from '@modules/Core/util/logic/zustand';
import {TutorialCard} from '@modules/Tutorials/components/TutorialCard';
import {_TutorialStep} from '@modules/Tutorials/types/tutorial.model';

interface _GlobalTutorialCard {
  elementId: string | null;
  data: _TutorialStep;
  onClose?: () => void;
  withScroll?: boolean;
}

export const GlobalTutorialCard: FC<_GlobalTutorialCard> = ({withScroll = true, elementId, onClose, data}) => {
  const {direction = 'to-right'} = data;

  const [position, setPosition] = useState<undefined | {top: number; left: number}>();
  const ref = useRef<HTMLDivElement | null>(null);
  const lastFocusedElementId = useRef<string | null>(null);
  const scaleFactor = useUiStore(state => state.scaleFactor);

  const updatePosition = useCallback(() => {
    logger.debug('updatePosition', {elementId});

    if (!elementId) return;
    const el = document.getElementById(elementId);
    const rect = el?.getBoundingClientRect();
    logger.debug('updatePosition', {elementId, rect, el});

    // if all coordinates are 0, chances are the element is dynamically created and not yet visible
    if (el && rect && rect.top + rect.left + rect.right + rect.bottom !== 0) {
      let newPos;
      const tutorialCardHeight = ref.current?.clientHeight ?? 0;
      const tutorialCardWidth = ref.current?.clientWidth ?? 0;

      // TODO: Add all, find a cleaner way maybe
      if (direction === 'to-right') {
        newPos = {top: rect.top + window.scrollY + rect.height / 2 - 13, left: rect.right + window.scrollX + 25};
      } else if (direction === 'to-left') {
        newPos = {top: rect.top + window.scrollY, left: rect.left - tutorialCardWidth + window.scrollX};
      } else if (direction === 'to-top') {
        newPos = {top: rect.top - tutorialCardHeight + window.scrollY, left: rect.left + window.scrollX};
      } else if (direction === 'to-bottom') {
        newPos = {top: rect.bottom + window.scrollY + 25, left: rect.left + window.scrollX};
      } else if (direction === 'to-bottom-left') {
        newPos = {top: rect.bottom + window.scrollY, left: rect.left - tutorialCardWidth + window.scrollX};
      } else if (direction === 'to-bottom-right') {
        newPos = {top: rect.bottom + window.scrollY + 25, left: rect.right + window.scrollX - 25};
      } else if (direction === 'to-bottom-middle') {
        newPos = {
          top: rect.bottom + window.scrollY + 25,
          left: rect.right + window.scrollX - tutorialCardWidth - rect.width / 2,
        };
      }

      if (newPos && (newPos.top !== position?.top || newPos.left !== position?.left)) {
        setPosition(newPos);
      }
    } else {
      setTimeout(updatePosition, 100); // Consider adding max retry limit or other exit condition
    }
  }, [elementId, direction, position]);

  useEffect(() => {
    if (elementId && lastFocusedElementId.current !== elementId) {
      const el = document.getElementById(elementId);
      logger.debug('tutorialtest: ', {
        elementId,
        withScroll,
      });
      if (el && withScroll) {
        // scroll to the element
        el.scrollIntoView({behavior: 'smooth', block: 'center'});
        lastFocusedElementId.current = elementId;
      }
    }
  }, [elementId, withScroll]);
  useEffect(() => {
    const scrollParent = getScrollParent(document.getElementById(elementId));
    const handleScroll = () => {
      updatePosition();
    };

    scrollParent.addEventListener('scroll', handleScroll);
    return () => {
      scrollParent.removeEventListener('scroll', handleScroll);
    };
  }, [elementId, updatePosition]);

  useLayoutEffect(() => {
    updatePosition();
  }, [data, updatePosition]);

  // Utility to find the closest scrollable parent
  function getScrollParent(node) {
    if (!node) return window;
    const {overflowY} = getComputedStyle(node);
    const isScrollable = overflowY !== 'visible' && overflowY !== 'hidden';
    return isScrollable && node.scrollHeight >= node.clientHeight ? node : getScrollParent(node.parentNode);
  }

  return position ? (
    <div className="fixed z-[999]" style={{top: position.top / scaleFactor, left: position.left / scaleFactor}}>
      <TutorialCard onClose={onClose} divRef={ref} data={data} />
    </div>
  ) : null;
};
