// @ts-nocheck
/* eslint-disable */

import {flowRoute} from '@/_core/util/util';
import {logger} from '@modules/Core/util/Logger';
import {can} from '@modules/Core/util/rolesAndPermissionsUtil';
import {goToCoachingSession} from '@modules/Core/util/routingUtil';
import {dictAsPathVariables} from '@modules/Core/util/strings';
import {debug, isDevMode, selectedKeys} from '@modules/Core/util/util';
import {_EvaluatorFunction} from '@modules/FlowEngine/types/evaluatorFunction.model';
import {fireDialogFlow} from '@modules/FlowEngine/util/dialogFlowsUtil';
import {inviteToTeamFlow, newTeamFlow} from '@modules/Institution/util/institutionDialogFlowsUtil';
import {currentInstitution, switchInstitution} from '@modules/Institution/util/institutionUtil';
import {isLoggedIn, onLogout} from '@modules/Profile/util/authUtil';
import {getProfileExercises, getProfileServiceRoundExercises} from '@modules/Profile/util/exercisesUtil';
import {hasThirdPersonFeedbacks} from '@modules/Profile/util/feedback360Util'; // Redux Functions
import {hasJournals} from '@modules/Profile/util/journalUtil';
import {getProfileSalutation} from '@modules/Profile/util/profileUIUtil';
import {
  currentProfile,
  getProfileLatestSoftfact,
  isSoftfactOrderCalculated,
  shareResult,
  surveyFlowRoute,
} from '@modules/Profile/util/profileUtil';
import {
  getCurrentServiceRound,
  hasStoredResults,
  isServiceAvailable,
  isServiceConsented,
  isServiceDone,
  isServiceDoneV2,
  isServiceNotReadyV2,
  isServicePreconditionsFulfilled,
  isServiceReady,
  isServiceReadyAndAvailable,
  isServiceReadyV2,
  isServiceStarted,
  isServiceStartedOrPreparedAndNotAvailable,
  isServiceStartedV2,
  isSurveyDone,
} from '@modules/Profile/util/serviceUtil';
import {refreshReduxState as refreshState} from '@modules/State/util/util';
import {leaveTeamFlow, teamAnalysisTransparencyFlow} from '@modules/Team/util/teamDialogFlowsUtil';
import {
  getPotentialSurveyDoneCount,
  getTeamDevNextSubModule,
  getTeamDevNextSubModuleName,
  isTransparencyAccepted,
} from '@modules/Team/util/teamsUtil';
import {currentTeam, setCurrentTeam, team} from '@modules/Team/util/teamUtil';
import {trans} from '@modules/Translations/util/i18n';
import {getFlowCurrentStep, getFlowData} from '../helpers/dataGetters';

const institutionFunctions: _EvaluatorFunction[] = [{name: 'switchInstitution', fn: switchInstitution}];
// You would use these entries when calling the modified registerFunctions method in your EvaluatorFunctions class.

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class EvaluatorFunctions {
  static functions: Record<string, _EvaluatorFunction> = {};

  static registerFunctions(functionsArray: _EvaluatorFunction[], categoryName: string): void {
    EvaluatorFunctions.functions[categoryName] = {};

    functionsArray.forEach(fnObj => {
      const {name: functionName, fn} = fnObj; // Destructure to get the name and function
      EvaluatorFunctions.functions[categoryName][functionName] = fn;
    });
  }

  static registerAllFunctions(): void {
    registerAllFunctions();
  }
}

/**
 * Outside the class so that class is independent from the functions
 * This is where you add new categories of functions
 * Category is ONLY used for debugging purposes.
 */
const registerAllFunctions = (): void => {
  // Redux Functions
  const reduxFunctions: _EvaluatorFunction[] = [
    {name: 'refreshState', fn: refreshState},
    // {name: 'changeCurrentTeam', fn: changeCurrentTeam},
    {name: 'currentTeam', fn: currentTeam},
    {name: 'team', fn: team},
    {name: 'currentInstitution', fn: currentInstitution},
    {name: 'currentProfile', fn: currentProfile},
    {name: 'setCurrentTeam', fn: setCurrentTeam},
  ];

  // Internationalization Functions
  const langFunctions: _EvaluatorFunction[] = [{name: 'trans', fn: trans}];

  // Authentication Functions
  const authFunctions: _EvaluatorFunction[] = [
    {
      name: 'isLoggedIn',
      fn: isLoggedIn,
    },
    {name: 'logout', fn: onLogout},
  ];

  // Flow and State Functions
  const flowFunctions: _EvaluatorFunction[] = [
    {name: 'getFlowCurrentStep', fn: getFlowCurrentStep},
    {name: 'getFlowData', fn: getFlowData},
  ];

  // Development and Debugging Functions
  const devFunctions: _EvaluatorFunction[] = [
    {name: 'isDevMode', fn: isDevMode},
    {name: 'logger', fn: logger},
  ];

  // Str Util
  const strFunctions: _EvaluatorFunction[] = [{name: 'asPathVariables', fn: dictAsPathVariables}];

  // Survey and Service Functions
  const serviceFunctions: _EvaluatorFunction[] = [
    {name: 'isServiceAvailable', fn: isServiceAvailable},
    {name: 'isServiceConsented', fn: isServiceConsented},
    {name: 'isServiceDone', fn: isServiceDone},
    {name: 'isServicePreconditionsFulfilled', fn: isServicePreconditionsFulfilled},
    {name: 'isServiceReady', fn: isServiceReady},
    {name: 'isServiceReadyV2', fn: isServiceReadyV2},
    {name: 'isServiceStartedV2', fn: isServiceStartedV2},
    {name: 'isServiceDoneV2', fn: isServiceDoneV2},
    {name: 'isServiceNotReadyV2', fn: isServiceNotReadyV2},
    {name: 'isServiceStarted', fn: isServiceStarted},
    {name: 'isSurveyDone', fn: isSurveyDone},
    {name: 'hasStoredResults', fn: hasStoredResults},
    {name: 'isServiceStartedOrPreparedAndNotAvailable', fn: isServiceStartedOrPreparedAndNotAvailable},
    {name: 'isServiceReadyAndAvailable', fn: isServiceReadyAndAvailable},
  ];

  // Routing Functions
  const routingFunctions: _EvaluatorFunction[] = [
    {name: 'flowRoute', fn: flowRoute},
    {name: 'surveyFlowRoute', fn: surveyFlowRoute},
    {name: 'goToCoachingSession', fn: goToCoachingSession},
  ];

  // Profile Functions
  const profileFunctions: _EvaluatorFunction[] = [
    {name: 'isSoftfactOrderCalculated', fn: isSoftfactOrderCalculated},
    {name: 'getProfileSalutation', fn: getProfileSalutation},
    {name: 'hasJournals', fn: hasJournals},
    {name: 'shareResult', fn: shareResult},
    {name: 'hasThirdPersonFeedbacks', fn: hasThirdPersonFeedbacks},
    {name: 'latestSoftfact', fn: getProfileLatestSoftfact},
    {name: 'getCurrentServiceRound', fn: getCurrentServiceRound},
  ];

  // Permission Functions
  const rolesPermissionFunctions: _EvaluatorFunction[] = [
    {name: 'can', fn: can},
    // {name: 'isInstitutionAdmin', fn: isInstitutionAdmin},
    // {name: 'isTeamAdmin', fn: isTeamAdmin},
  ];

  // Exercise Functions
  const exerciseFunctions: _EvaluatorFunction[] = [
    {name: 'getProfileExercises', fn: getProfileExercises},
    {name: 'getProfileServiceRoundExercises', fn: getProfileServiceRoundExercises},
  ];

  // Team Functions
  const teamFunctions: _EvaluatorFunction[] = [
    {name: 'getPotentialSurveyDoneCount', fn: getPotentialSurveyDoneCount},
    {name: 'getTeamDevNextSubModule', fn: getTeamDevNextSubModule},
    {name: 'getTeamDevNextSubModuleName', fn: getTeamDevNextSubModuleName},
    // {name: 'shareResult', fn: shareResult},
    {name: 'isTransparencyAccepted', fn: isTransparencyAccepted},
  ];

  // Util
  const utilFunctions: _EvaluatorFunction[] = [
    {name: 'debug', fn: debug},
    {name: 'selectedKeys', fn: selectedKeys},
  ];

  // Dialog flows
  const dialogFlows: _EvaluatorFunction[] = [
    {name: 'newTeamFlow', fn: newTeamFlow},
    {name: 'inviteToTeamFlow', fn: inviteToTeamFlow},
    {name: 'leaveTeamFlow', fn: leaveTeamFlow},
    {name: 'teamAnalysisTransparencyFlow', fn: teamAnalysisTransparencyFlow},
    {name: 'fireDialogFlow', fn: fireDialogFlow},
  ];

  EvaluatorFunctions.registerFunctions(serviceFunctions, 'service');
  EvaluatorFunctions.registerFunctions(reduxFunctions, 'redux');
  EvaluatorFunctions.registerFunctions(langFunctions, 'lang');
  EvaluatorFunctions.registerFunctions(authFunctions, 'auth');
  EvaluatorFunctions.registerFunctions(flowFunctions, 'flow');
  EvaluatorFunctions.registerFunctions(devFunctions, 'dev');
  EvaluatorFunctions.registerFunctions(routingFunctions, 'routing');
  EvaluatorFunctions.registerFunctions(profileFunctions, 'profile');
  EvaluatorFunctions.registerFunctions(rolesPermissionFunctions, 'rolesPermission');
  EvaluatorFunctions.registerFunctions(exerciseFunctions, 'exercise');
  EvaluatorFunctions.registerFunctions(strFunctions, 'str');
  EvaluatorFunctions.registerFunctions(teamFunctions, 'team');
  EvaluatorFunctions.registerFunctions(utilFunctions, 'util');
  EvaluatorFunctions.registerFunctions(institutionFunctions, 'institution');
  EvaluatorFunctions.registerFunctions(dialogFlows, 'dialogFlows');
};
