// @ts-nocheck
/* eslint-disable */

import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import * as am5 from '@amcharts/amcharts5';
import {Root} from '@amcharts/amcharts5';
import {logger} from '@modules/Core/util/Logger';
import {uuid} from '@modules/Core/util/util';
import {initAmCharts} from './initAmCharts';

interface _AmChartsProps {
  type: 'SolidGaugeMulti' | 'Lines' | 'PolarArea' | 'Donut';
  data: any;
  chartOptions?: {colors?: string[]};
}

export const AmCharts: React.FC<_AmChartsProps> = ({type, data, chartOptions = {}}) => {
  const [id] = useState(uuid());
  const [root, setRoot] = useState<Root | null>(null);
  const rootSet = useRef(false);
  useLayoutEffect(() => {
    if (id && !rootSet.current) {
      const r = am5.Root.new(id);
      setRoot(r);
      rootSet.current = true;
    }
    return () => {
      if (root) {
        root.dispose();
        setRoot(null);
      }
    };
  }, [id]);

  useEffect(() => {
    if (root) {
      // remove all children
      root.container.children.each(child => {
        // check if disposed
        if (child.isDisposed()) {
          return;
        }
        child.dispose();
      });
      logger.debug('init chart', {id}, {type}, {data});

      // based on https://www.amcharts.com/demos/radar-with-date-axis/
      if (type === 'SolidGaugeMulti') initAmCharts.initSolidGaugeMulti(root, chartOptions, data);
      if (type === 'Lines') initAmCharts.initializeLinesChart(root, chartOptions, data);
      if (type === 'PolarArea') initAmCharts.initializePolarAreaChart(root, chartOptions, data);
      if (type === 'Donut') initAmCharts.initDonutGauge(root, chartOptions, data);
    }
  }, [data, chartOptions, id, type, root]);

  return <div className="c-amcharts" style={{height: '100%', width: '100%'}} id={id} />;
};
