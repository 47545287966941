// @ts-nocheck
/* eslint-disable */

export const DEFAULT_THEME = 'softfact-light';

export const MULTIPLE_THEMES_ENABLED = false;
export const FAV_ICON_PATH = '/img/favicons/android-chrome-512x512.png';
export const APP_TITLE = 'soft.fact - Work Different';

/**
 * key name for Loading indicator
 */
export const GLOBAL_LOADING = 'global';
export const INSTITUTION_LOADING = 'institution';

export const SOFT_FACT_SUPPORT = 'support@softfact.works';

export const AMBASSADOR_PLAN_ID = 'ambassador';

export const SHOW_SOFTWARE_UPDATE_POPUP_EVENT = 'showSoftwareUpdatePopup';

/**
 * Notifications profileValues keys
 */

export const PROFILE_NOTIFICATION_DASHBOARD_SEEN = 'dashboard_popup_seen';

/**
 * Profile Alerts keys
 */

export const PROFILE_ALERT_TYPE_GAMIFICATION = 'gamification_alert';

export const PROFILE_ALERT_TYPE_SERVICE_ROUND_FINISHED = 'service_round_finished';
export const PROFILE_ALERT_NEW_RELEASE = 'new_release';

/**
 * Action status
 */

export const ACTION_STATUS_SUCCESS = 'success';
export const ACTION_STATUS_ERROR = 'error';

export const DEFAULT_ERROR_MESSAGE = 'base.error';
export const FEEDBACK_TYPE_MEMBERSHIP_CANCELLED = 'membership_cancelled';
