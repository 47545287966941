// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {PlaceholderSection} from '@modules/Core/components/layout/PlaceholderSection';
import {getDesignImage} from '@modules/Core/util/imagesUtil';
import {logger} from '@modules/Core/util/Logger';
import {OrganisationExercisesRoundRow} from '@modules/Institution/components/dashboard/OrganisationExercisesRoundRow';
import {_InstitutionOverviewTeam} from '@modules/Institution/types/institutionstatistics.model';
import {_TotalProgressData} from '@modules/Team/types/teamstatistics.model';
import {trans} from '@modules/Translations/util/i18n';

interface _Props {
  exercises: Record<string, _InstitutionOverviewTeam[]>;
  relevance: string[];
  progress: Record<string, _TotalProgressData>;
}

const LIMIT = 4;
export const OrganisationExercisesItem: React.FC<_Props> = ({progress, exercises, relevance}) => {
  logger.debug('OrganisationExercisesItem', {exercises, relevance, progress});

  if (!exercises || Object.keys(exercises).length === 0) {
    return (
      <PlaceholderSection
        title={trans('institution_gamification.exercises_missing.title')}
        content={trans('institution_gamification.exercises_missing.description')}
        image={getDesignImage('your exercises.png')}
      />
    );
  }
  return (
    <div className="flex flex-col gap-3">
      {Object.entries(exercises ?? {})
        .sort(([a], [b]) => {
          if (!relevance.includes(a)) {
            return 1;
          }

          if (!relevance.includes(b)) {
            return -1;
          }
          return relevance.indexOf(a) - relevance.indexOf(b);
        })
        .map(([service, teams]) => {
          return (
            <div key={service}>
              <OrganisationExercisesRoundRow progress={progress[service] ?? null} service={service} teams={teams} />
            </div>
          );
        })
        .slice(0, LIMIT)}
    </div>
  );
};
