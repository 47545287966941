// @ts-nocheck
/* eslint-disable */

import React, {useState} from 'react';
import {AvatarUser} from '@modules/Core/components/base/avatar/AvatarUser';
import {CheckBox} from '@modules/Core/components/base/inputs/CheckBox';
import {_SelectValue} from '@modules/Core/components/base/inputs/Select';
import {ActionsTable} from '@modules/Core/components/base/table/ActionsTable';
import {_TableColumns, _TableDataItem} from '@modules/Core/components/base/table/Table';
import {ToolTip} from '@modules/Core/components/base/ToolTip';
import {Section} from '@modules/Core/components/layout/Section';
import {rolesLangDict} from '@modules/Core/config/rolesConstants';
import {formatDate} from '@modules/Core/util/dates';
import {hasLegacyMembership} from '@modules/Core/util/subscriptionUtil';
import {_Institution} from '@modules/Institution/types/institution.model';
import {DEV_ONLY_PLAN_ID, FREE_PLAN_ID} from '@modules/Profile/config/membershipsConstants';
import {_InstitutionProfile} from '@modules/Profile/types/profile.model';
import {
  getToBeActivatedSubscription,
  hasDiscount,
  hasRunningOneTimePayment,
  isFreeSubscription,
  isSubscriptionToBeCancelled,
} from '@modules/Profile/util/membershipsUtil';
import {getProfilePic} from '@modules/Profile/util/profileUtil';
import {ProfileTeamsTableView} from '@modules/Team/components/common/ProfileTeamsTableView/ProfileTeamsTableView';
import {trans} from '@modules/Translations/util/i18n';

interface _InstitutionPaymentTableProps {
  profiles: Record<string, _InstitutionProfile>;
  institution: _Institution | null;
  chosenProfiles: string[];
  canSelectProfile: (profile: _InstitutionProfile) => boolean;
  onProfileSelected: (profileId: string) => void;
  onClearSelectedProfiles: () => void;
  actions: _InstitutionTableSelectValue[];
}

export interface _InstitutionTableSelectValue extends _SelectValue {
  action: () => void;
}

export const InstitutionPaymentTable: React.FC<_InstitutionPaymentTableProps> = ({
  profiles,
  chosenProfiles,
  institution,
  canSelectProfile,
  onProfileSelected,
  onClearSelectedProfiles,
  actions,
}) => {
  const [expandedProfileTeams, setExpandedProfileTeams] = useState<string | null>(null);

  const columns: _TableColumns = [
    {label: trans('base.name'), key: 'name', width: 'relative-col-width-3-plus-gap'},
    {label: trans('base.license'), key: 'license', width: 'relative-col-width-3-plus-gap'},
    {label: trans('subscription.due_date'), key: 'dueDate', width: 'relative-col-width-2-plus-gap'},
    {label: trans('organisation.teams'), key: 'teams', width: 'relative-col-width-2'},
  ];

  function profileLicenseCancellation(profile: _InstitutionProfile) {
    if (!isSubscriptionToBeCancelled(profile?.subscriptionData?.subscription)) {
      return null;
    }

    const profToBeActivated = getToBeActivatedSubscription(profile);

    const message =
      !!profToBeActivated?.plan_id && !!profToBeActivated?.plan_id !== FREE_PLAN_ID
        ? 'subscription.to_be_changed_plan'
        : profile?.subscriptionData?.subscription?.pivot?.is_trial
          ? 'subscription.to_be_cancelled_trial'
          : 'subscription.to_be_cancelled_plan';

    const newBillingInterval = profToBeActivated?.type;
    const oldBillingInterval = profile?.subscriptionData?.subscription?.type;

    const date = profile?.subscriptionData?.subscription?.pivot?.is_trial
      ? profile?.subscriptionData?.subscription?.pivot?.expires_at
      : profile?.subscriptionData?.subscription?.pivot?.date;

    return (
      <div className="flex flex-col">
        {trans(message, {
          date: formatDate(new Date(date)),
          newBillingInterval: trans(`subscription.types.${newBillingInterval}_short`),
          oldBillingInterval: trans(`subscription.types.${oldBillingInterval}_short`),
        })}
      </div>
    );
  }

  function profileExpiryDateComponent(profile: _InstitutionProfile) {
    const toBeCancelled = isSubscriptionToBeCancelled(profile?.subscriptionData?.subscription);

    if (toBeCancelled) {
      return profileLicenseCancellation(profile);
    }
    if (profile?.subscriptionData?.subscription?.pivot?.is_trial) {
      return (
        <div>
          <div className="overflow_text">
            {`${trans('base.trial')} ${formatDate(
              new Date(profile?.subscriptionData?.subscription?.pivot?.expires_at)
            )}`}
          </div>
          {!toBeCancelled && (
            <div className="overflow_text">
              {`${trans('base.subscription')} ${formatDate(
                new Date(profile?.subscriptionData?.subscription?.pivot?.membership_ends_at)
              )}`}
            </div>
          )}
        </div>
      );
    }

    if (profile?.subscriptionData?.subscription?.pivot?.expires_at) {
      return <div>{formatDate(new Date(profile?.subscriptionData?.subscription?.pivot?.expires_at))}</div>;
    }
    return '-';
  }

  // Render row function
  const renderRow = (profile: _InstitutionProfile): _TableDataItem => {
    const isFree = isFreeSubscription(profile?.subscriptionData?.subscription);
    return {
      name: (
        <ToolTip txt={!canSelectProfile(profile) ? trans('subscription.no_possible_actions_description') : null}>
          <div className="flex flex-row items-center gap-2">
            {!hasLegacyMembership(institution) && (
              <CheckBox
                disabled={!canSelectProfile(profile)}
                onChange={() => onProfileSelected(profile.id)}
                active={chosenProfiles?.includes(profile.id)}
              />
            )}
            <AvatarUser image={getProfilePic(profile)} label={profile.fullName} email={profile.email} />
          </div>
        </ToolTip>
      ),
      license: (
        <div className="flex flex-col items-start justify-start">
          <div>
            {!isFree || !hasRunningOneTimePayment(profile) ? (
              <>{trans(`subscription.plans.${profile?.subscriptionData?.planId}.title`)}</>
            ) : null}
            {![FREE_PLAN_ID, DEV_ONLY_PLAN_ID].includes(profile?.subscriptionData?.planId) && (
              <span>/{trans(`subscription.types.${profile?.subscriptionData?.subscription?.type}_abbreviated`)}</span>
            )}
          </div>
          {isFree && hasRunningOneTimePayment(profile) && trans('subscription.softfact_check_included')}
          {isFree && !hasRunningOneTimePayment(profile) && (profile?.trialAvailable ?? institution?.trialAvailable) && (
            <div className="text-primary text-wrap">{trans('promotions.trial_available')}</div>
          )}

          {isFree && !hasRunningOneTimePayment(profile) && hasDiscount(profile) && (
            <div className="text-primary text-wrap">
              {trans('promotions.discount_available', {
                value: profile?.discount?.action_params?.value,
              })}
            </div>
          )}
        </div>
      ),
      dueDate: profileExpiryDateComponent(profile),
      teams: (
        <ProfileTeamsTableView
          open={expandedProfileTeams === profile.id}
          onOpen={() => setExpandedProfileTeams(profile.id)}
          onClose={() => setExpandedProfileTeams(null)}
          teams={profile?.teams}
          renderTeam={team => (
            <div className="flex flex-col items-start w-full">
              <b>{team.name}</b>
              {trans(rolesLangDict[team?.role])}
            </div>
          )}
        />
      ),
    };
  };

  return (
    <Section>
      <ActionsTable
        rows={Object.values(profiles ?? {})}
        columns={columns}
        renderRow={renderRow}
        showTopBorder
        filterOptions={{
          enabled: true,
          searchAttributes: ['fullName', 'license', 'dueDate', 'teams.name', 'email'],
        }}
        headerProps={{
          selectedRowsIds: chosenProfiles,
          onClearSelectedRows: onClearSelectedProfiles,
          selectedRowsLabel: (count: number) =>
            count === 1
              ? trans('subscription.user_selected')
              : trans('subscription.users_selected', {
                  count,
                }),
          actions,
          actionsLabel: trans('base.actions'),
          resetSelectionLabel: trans('subscription.reset_selection'),
        }}
      />
    </Section>
  );
};
