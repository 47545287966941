// @ts-nocheck
/* eslint-disable */

import {logger} from '@modules/Core/util/Logger';
import {FLOWS} from '@modules/FlowEngine/config/flowNames';
import {fireDialogFlow} from '@modules/FlowEngine/util/dialogFlowsUtil';
import {_Team} from '@modules/Team/types/team.model';
import {archiveTeam} from '@modules/Team/util/teamsUtil';
import {team} from '@modules/Team/util/teamUtil';
import {trans} from '@modules/Translations/util/i18n';

export function leaveTeamFlow(profileId: string, teamId: string): void {
  const currentTeam = team(teamId);
  if (!currentTeam) {
    return;
  }
  fireDialogFlow(
    FLOWS.LeaveTeam,
    null,
    {
      hideButtons: true,
      showCloseButton: true,
    },
    {
      teamId,
      profileId,
    }
  );
}

export function teamAnalysisTransparencyFlow(teamId: string, transparent: boolean = false): void {
  logger.debug(`[istransparencyaccepted] teamId: ${teamId}, transparent: ${transparent}`);
  fireDialogFlow(
    FLOWS.TeamAnalysisTransparency,
    null,
    {
      showCloseButton: true,
      hideButtons: true,
    },
    {
      teamId,
      transparent: transparent || false,
    }
  );
}

export function archiveTeamFlow(team: _Team): void {
  fireDialogFlow(FLOWS.ArchiveTeam, null, {
    title: trans('project.archive_team', {
      name: team.name,
    }),
    onConfirm: () => {
      void archiveTeam(team.id);
    },
  });
}
