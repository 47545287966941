// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {Card} from '@modules/Core/components/base/cards/Card';
import {Typography} from '@modules/Core/components/base/Typography';
import {_ProfileMotivesGroupResult} from '@modules/Profile/types/service.model';
import {getMotiveGroupImage} from '@modules/Profile/util/profileUtil';
import {trans} from '@modules/Translations/util/i18n';

interface _ProfileMotivesGroupCardProps {
  motivesGroup: _ProfileMotivesGroupResult;
  isMain?: boolean;
  mainView?: boolean;
}

export const ProfileMotivesGroupCard: React.FC<_ProfileMotivesGroupCardProps> = ({
  mainView = false,
  isMain = false,
  motivesGroup,
}) => {
  const {value, title, group} = motivesGroup ?? {};

  return (
    <Card>
      <img src={getMotiveGroupImage(title)} className="img-fluid p-5 mx-auto" />
      <Typography variant="h6">
        {isMain && `${trans('motive_structure.main')}: `}
        {trans(`motive_structure.${title}.title`)} ({Math.round(value)}%)
      </Typography>
      {!mainView && (
        <Typography
          dangerouslySetInnerHTML={{
            __html: trans(`motive_structure.${title}.interpretation`),
          }}
        />
      )}
      {mainView && (
        <div className="flex flex-col gap-3">
          <Typography bold>{trans(`motive_structure.do_things`)}</Typography>
          <Typography
            dangerouslySetInnerHTML={{__html: trans(`motive_structure.${title}.handlungsergebniserwartung`)}}
          />
          <Typography bold>{trans(`motive_structure.chance`)}</Typography>
          <Typography dangerouslySetInnerHTML={{__html: trans(`motive_structure.${title}.chance`)}} />
          <Typography bold>{trans(`motive_structure.block`)}</Typography>
          <Typography dangerouslySetInnerHTML={{__html: trans(`motive_structure.${title}.block`)}} />
        </div>
      )}
    </Card>
  );
};
