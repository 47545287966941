// @ts-nocheck
/* eslint-disable */

import React, {useState} from 'react';
import {ContentEngineContainer} from '@modules/ContentEngine/components/base/ContentEngineContainer';
import {CE_TEMPLATE_ALERTS} from '@modules/ContentEngine/config/constants';
import {generate} from '@modules/Core/components/base/helper';
import {logger} from '@modules/Core/util/Logger';
import {FlowHeaderNavigation} from '@modules/FlowEngine/components/Base/FlowHeaderNavigation';
import {useFlow} from '@modules/FlowEngine/hooks/flow';
import {_FlowInput, _FlowSchema} from '@modules/FlowEngine/types/core.model';
import {_FlowStep} from '@modules/FlowEngine/types/step.model';
import FlowActions from './FlowActions';

interface _Props {
  flowSchema: _FlowSchema;
  flowName: string;
  stepName?: string;
  input?: _FlowInput;
  onCurrentStepChanged?: (step: _FlowStep | undefined) => void;
  onFinish?: () => void;
  instanceKey?: string;
}

const FlowComponent: React.FC<_Props> = ({
  flowSchema,
  flowName,
  input,
  stepName,
  onCurrentStepChanged = null,
  onFinish = null,
  instanceKey = 'default',
}) => {
  const [instanceKeyId] = useState<string>(`${instanceKey}_${generate()}`);

  const {options, currentStep, onNextClicked, onPreviousClicked, onSaveClicked, onFinishClicked, stepComponent} =
    useFlow(flowName, flowSchema, input, stepName, onCurrentStepChanged, onFinish, instanceKeyId);

  const navigation = currentStep?.navigation ? currentStep.navigation : options?.navigation;

  function flowActions(allowSticky = true): React.ReactNode {
    if (options?.showFooter && currentStep) {
      return (
        <FlowActions
          currentStep={currentStep}
          stickyFooter={allowSticky && !!currentStep?.options?.stickyFooter}
          onNextClicked={onNextClicked}
          onSaveClicked={onSaveClicked}
          onFinishClicked={onFinishClicked}
          onPreviousClicked={onPreviousClicked}
        />
      );
    }
  }

  if (!stepComponent) {
    return null;
  }
  const alertsCE = options?.withAlerts ? <ContentEngineContainer type={CE_TEMPLATE_ALERTS} /> : null;
  logger.debug('FlowComponent', {currentStep, options});

  if (currentStep?.options?.layout === 'plain') {
    return (
      <>
        <FlowHeaderNavigation navigation={navigation} currentStep={currentStep} />
        {stepComponent}
        {alertsCE}
      </>
    );
  }

  logger.debug('FlowComponent', {currentStep, options});
  return (
    <>
      <FlowHeaderNavigation navigation={navigation} currentStep={currentStep} />
      {stepComponent}
      {flowActions()}
      {alertsCE}
    </>
  );
};

export default FlowComponent;
