// @ts-nocheck
/* eslint-disable */

import {useEffect, useState} from 'react';
import {
  SERVICE_TEAM_DEVELOPMENT,
  SERVICE_TEAM_REFLECTION,
  SOFTFACT_SERVICES_NO_SAT_PERF,
} from '@modules/Core/config/services';
import {logger} from '@modules/Core/util/Logger';
import {copyObject} from '@modules/Core/util/util';
import {_Profile} from '@modules/Profile/types/profile.model';

export const IN_PROGRESS_STATUS = 'in_progress';
export const DONE_STATUS = 'done';
export const NOT_STARTED_STATUS = 'not_started';
export const NOT_AVAILABLE_STATUS = 'not_available';

export function useTeamMembersProgress(profiles: Record<string, _Profile>, teamId?: string | null) {
  const [members, setMembers] = useState<Record<string, _Profile>>({});

  useEffect(() => {
    if (!profiles) {
      setMembers({});
      return;
    }

    const newMembers: Record<string, _Profile> = {};

    for (const [key, value] of Object.entries(profiles)) {
      newMembers[key] = createSingleMemberProgress(value);
    }

    setMembers(newMembers);
  }, [profiles, teamId]);

  function createSingleMemberProgress(member: _Profile): _Profile {
    const result: Record<string, string> = {};

    const services = member.surveys;

    if (!services) {
      return member;
    }
    //
    // result[SERVICE_PROFILE_BASE_AND_ANALYSIS] = createSoftfactsStatus(services?.[SERVICE_PROFILE_BASE_AND_ANALYSIS]);
    //
    // result[SERVICE_PROFILE_ROLES] = createSoftfactsStatus(services?.[SERVICE_PROFILE_ROLES]);
    //
    // result[SERVICE_PROFILE_WAY_OF_WORKING] = createSoftfactsStatus(services?.[SERVICE_PROFILE_WAY_OF_WORKING]);
    //
    // result[SERVICE_PROFILE_MOTIVE_STRUCTURE] = createSoftfactsStatus(services?.[SERVICE_PROFILE_MOTIVE_STRUCTURE]);
    //
    // result[SERVICE_PROFILE_VALUES] = createSoftfactsStatus(services?.[SERVICE_PROFILE_VALUES]);

    SOFTFACT_SERVICES_NO_SAT_PERF.forEach(service => {
      result[service] = createSoftfactsStatus(services?.[service]);
    });

    if (teamId) {
      result[SERVICE_TEAM_REFLECTION] = getTeamSurveyStatus(member, SERVICE_TEAM_REFLECTION, 'team_way_of_working');

      result[SERVICE_TEAM_DEVELOPMENT] = getTeamSurveyStatus(member, SERVICE_TEAM_DEVELOPMENT, 'team_potential');
    }

    // for (const [key, value] of Object.entries(services)) {
    //   result[key] = createSoftfactsStatus(value);
    // }

    const newMember = copyObject(member);

    newMember.progress = result;

    return newMember;
  }

  function getTeamSurveyStatus(member: _Profile, teamService: string, profileTeamService: string): string {
    const services = member.subscriptionData?.services;

    const teamSurvey = services?.[teamService];

    if (!teamSurvey?.included) {
      return NOT_AVAILABLE_STATUS;
    }

    // To check if a profile started with team reflection, check for team WOW survey
    const profileTeamSurvey = member.surveys?.[`${profileTeamService}_${teamId}`];

    if (!profileTeamSurvey?.started) {
      return NOT_STARTED_STATUS;
    }

    if (!!profileTeamSurvey?.started && !profileTeamSurvey?.finished) {
      return IN_PROGRESS_STATUS;
    }

    return IN_PROGRESS_STATUS;
  }

  function createSoftfactsStatus(service: Record<string, any>): string {
    const started = !!service?.started;
    const finished = !!service?.finished;

    if (finished) {
      return DONE_STATUS;
    }

    if (started) {
      return IN_PROGRESS_STATUS;
    }

    return NOT_STARTED_STATUS;
  }

  return {
    members,
  };
}
