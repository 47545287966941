// @ts-nocheck
/* eslint-disable */

import {logger} from '@modules/Core/util/Logger';
import {_CustomSurvey, _CustomSurveyQuestion} from '@modules/CustomSurveys/types/customSurvey.model';
import {SurveyJSJson} from '@modules/CustomSurveys/types/surveyJs.model';

const modelFields = ['title', 'type', 'options', 'meta'];

export function mapSurveyCreatorToBackend(surveyCreatorObject?: SurveyJSJson): _CustomSurvey {
  if (!surveyCreatorObject) return {} as _CustomSurvey;

  const survey: _CustomSurvey = {
    id: surveyCreatorObject.id ?? '',
    title: surveyCreatorObject.title || '',
    description: surveyCreatorObject.description || '',
    questions: surveyCreatorObject.elements?.map((element, index) => {
      const question: _CustomSurveyQuestion = {
        title: element.title || '',
        type: element.type,
        options: {},
        meta: {},
        page: 0,
        order: index,
      };

      // Extract everything else to options
      Object.keys(element).forEach(key => {
        if (!modelFields.includes(key)) {
          question.options[key] = element[key];
        }
      });

      // If 'meta' exists in the element, use it directly
      if ('meta' in element && typeof element.meta === 'object') {
        question.meta = element.meta;
      }

      return question;
    }),
  };

  return survey;
}

export function mapBackendToSurveyCreator(customSurvey: _CustomSurvey | null, currentLanguage: string): SurveyJSJson {
  if (!customSurvey) return {} as SurveyJSJson;
  const surveyJS: SurveyJSJson = {
    id: customSurvey.id,
    title: customSurvey.title,
    description: customSurvey.description,
    pages: customSurvey.pages?.map(page => ({name: page.name})) ?? [],
  };

  customSurvey.questions?.forEach(question => {
    const pageIndex = question.page;
    if (!surveyJS.pages[pageIndex]) {
      surveyJS.pages[pageIndex] = {elements: []};
    }

    const element: any = {
      ...question.options,
      title: question.title,
      type: question.type,
      meta: question.meta,
    };

    const {options} = question;

    // For each options.choices.text (looks like :{$langKey:'textValue'}) we need to extract the textValue
    if (options?.choices) {
      logger.debug('options.choices', options.choices);
      options.choices = options.choices.map((choice: any) => {
        if (typeof choice.text === 'object') {
          // Set all languages to text of current language.
          const newText = {
            default: choice.text[currentLanguage] || choice.text.default,
            de: choice.text[currentLanguage] || choice.text.default,
            en: choice.text[currentLanguage] || choice.text.default,
          };
          return {
            ...choice,
            text: newText,
          };
        }

        return choice;
      });
      logger.debug('options.choices after', options.choices);
    }

    element.choices = options.choices;

    if (!surveyJS.pages[pageIndex].elements) {
      surveyJS.pages[pageIndex].elements = [];
    }

    surveyJS.pages[pageIndex].elements.push(element);
  });

  return surveyJS;
}
